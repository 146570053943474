import { Grid, Button } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import ReactTable from 'react-table';

function PlayerSearchTable(props) {
  const { playerList, classes, historyHref } = props;

  console.log(playerList);

  let columns = [
    {
      Header: 'First Name',
      accessor: 'firstName',
    },
    {
      Header: 'Last Name',
      accessor: 'lastName',
    },
    {
      Header: 'Date of Birth',
      accessor: 'dateOfBirth',
    },
    {
      Header: 'Canada Soccer Id',
      accessor: 'personCsId',
    },
    {
      Header: 'History',
      accessor: 'personId',
      Cell: cellInfo => <Button component={Link} to={`${historyHref}/${cellInfo.value}`}>View History</Button>,
    },
  ]

  return (
    <Grid container>
      <Grid item xs={12}>
        <ReactTable
          data={playerList}
          filterable
          defaultFilterMethod={(filter, row) => String(row[filter.id]).toLowerCase().includes(String(filter.value).toLowerCase())}
          columns={columns}
          className={classNames('-striped -highlight', classes.table)}
        />
      </Grid>
    </Grid>
  )
}

PlayerSearchTable.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

const styles = theme => ({
  table: {
    fontFamily: theme.typography.fontFamily,
  }
});
export default withStyles(styles, { withTheme: true })(PlayerSearchTable);