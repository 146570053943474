import React from 'react';
import { Route, withRouter } from 'react-router';

import UserTable from './user/UserTable';
import ViewUser from './user/ViewUser';

function UserRouter(props) {
  const { match } = props;
  return (
    <React.Fragment>
      <Route path={`${match.path}`} exact component={UserTable} />
      <Route path={`${match.path}/edit/:id`} render={props => <ViewUser {...props} redirectRoute={match.path} />} />
    </React.Fragment>
  )
}

export default withRouter(UserRouter);