import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Typography, ListItem, ListItemText, ListItemSecondaryAction, IconButton, List } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import LoadingSpinner from '../../../components/LoadingSpinner';

function ApprovedVendorsList(props) {
  const { vendors, onRevokeClick, updating, classes } = props;


  if (updating) {
    return <LoadingSpinner message={'Updating list'} />
  }

  if (vendors.length === 0) {
    return null;
  }

  return (
    <React.Fragment>
      <Typography variant='body1' color='inherit'>Vendors with Access</Typography>
      <List className={classes.vendor}>
        {
          vendors.map((vendor, i) => (
            <ListItem key={i} button>
              <ListItemText primary={vendor.organizationName} />
              <ListItemSecondaryAction onClick={onRevokeClick(vendor.organizationId)}>
                <IconButton>
                  <DeleteIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          ))
        }
      </List>
    </React.Fragment>
  )
}

ApprovedVendorsList.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

const styles = theme => ({
  vendor: {
    width: 400,
  }
});

export default withStyles(styles, { withTheme: true })(ApprovedVendorsList);