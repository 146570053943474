import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import LoginButton from './LoginButton';
import ContextButton from './ContextButton';
import LocalizationButton from './LocalizationButton';

function NavMenuToolbar(props) {
  const { classes } = props;
  const { t } = useTranslation();
  const isProduction = process.env.REACT_APP_ENV_NAME === 'Production';
  return (
    <React.Fragment>
      <Typography variant="h6" color="inherit" className={classes.grow} noWrap>
        {isProduction
          ? `${t('nav.topbar')}`
          : `${t('nav.topbar')}: ${process.env.REACT_APP_ENV_NAME}`}
      </Typography>
      <LocalizationButton />
      <ContextButton />
      <LoginButton />
    </React.Fragment>
  )
}

NavMenuToolbar.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

const styles = theme => ({
  grow: {
    flexGrow: 1,
  },
});


export default
  withStyles(styles, { withTheme: true })(NavMenuToolbar);
