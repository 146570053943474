import { getToken } from '../../utils/AzureADB2C';

export const GetAllActiveNewsItemsFromPortal = () => {
  const req = {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + getToken(),
    },
  };

  let url = [process.env.REACT_APP_PORTAL_URL, 'newsitem', 'getallactive'].join('/');
  return fetch(url, req);
}

export const GetAllNewsItemsFromPortal = () => {
  const req = {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + getToken(),
    },
  };

  let url = [process.env.REACT_APP_PORTAL_URL, 'newsitem', 'getall'].join('/');
  return fetch(url, req);
}

export const GetNewsItemById = (id) => {
  const req = {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + getToken(),
    },
  };

  let url = [process.env.REACT_APP_PORTAL_URL, 'newsitem', 'get', id].join('/');
  return fetch(url, req);
}