import { Button, Grid, Paper, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withRouter } from "react-router";
import { PostRegistrationToPortal } from '../../api/registration/PostRegistrationToPortal';
import { OrganizationInput, PhoneInput, TextInput } from '../../components/inputs/Inputs';
import { isInvalid } from '../../components/inputs/Validation';
import { Link } from 'react-router-dom'
import { withSnackbar } from 'notistack';
import { withTranslation } from 'react-i18next';
import i18next from 'i18next'

class RegistrationForm extends Component {
  displayName = RegistrationForm.name;
  constructor(props) {
    super(props);
    this.state = emptyFormState;
  }


  handleInputChange = field => event => {
    let newFieldState = Object.assign({}, this.state[field]);
    newFieldState.value = event.target.value;
    newFieldState = isInvalid(newFieldState);
    this.setState({ [field]: newFieldState });
  }

  anyInvalid = () => {
    let newState = {};
    let allValid = true;
    for (let field in this.state) {
      let newFieldState = Object.assign({}, this.state[field]);
      newFieldState = isInvalid(newFieldState);
      allValid = allValid && !newFieldState.invalid;
      newState[field] = newFieldState;
    }
    this.setState(newState);
    return !allValid;
  }

  handleRegister = () => {
    const { t } = this.props;
    if (this.anyInvalid()) {
      this.setState({ failedAttempt: true, failedMessage: t('registration.Please enter valid information') });
      return;
    }

    this.setState({ failedAttempt: false });


    const { firstName, lastName, email, phone, organization, role } = this.state;
    let registrationJson = {
      firstName: firstName.value,
      lastName: lastName.value,
      email: email.value,
      phone: phone.value,
      organization: organization.value,
      role: role.value,
    };

    PostRegistrationToPortal(registrationJson)
      .then((res) => {
        if (res.status === 200) {
          this.props.enqueueSnackbar(t("registration.Submitted registration to portal"), { variant: 'success', autoHideDuration: 5500 });
          this.props.history.push('/');
        }
        else {
          return res.json();
        }
      })
      .then(json => {
        if (json) {
          this.setState({ failedAttempt: true, failedMessage: json.message });
          this.props.enqueueSnackbar(t(`registration.Failed registration with portal`), { variant: 'error', autoHideDuration: 5500 });
        }
      })
      .catch(e => {
        console.log(e);
        this.props.enqueueSnackbar(t(`registration.Error submitting registration to portal`), { variant: 'error', autoHideDuration: 5500 });
      })
  }

  render() {
    const { firstName, lastName, email, phone, organization, role, failedAttempt, failedMessage } = this.state;
    const { classes, match, t } = this.props;
    let registrationCheckHref = `${match.path}/check`;

    return (
      <Grid container spacing={24}>
        <Grid item md={1} xl={2} />
        <Grid item xs={12} md={10} xl={8}>
          <Paper className={classes.paper}>
            <Grid container spacing={16}>
              <Grid item xs={12}>
                <Typography variant="h6" color="inherit">{t('registration.Registration')}</Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextInput fullWidth required field={firstName} onChange={this.handleInputChange} />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextInput fullWidth required field={lastName} onChange={this.handleInputChange} />
              </Grid>
              <Grid item xs={12} sm={8}>
                <TextInput fullWidth required field={email} onChange={this.handleInputChange} />
              </Grid>
              <Grid item xs={12} sm={4}>
                <PhoneInput field={phone} onChange={this.handleInputChange} />
              </Grid>
              <Grid item xs={12}>
                <OrganizationInput allowNewOrg field={organization} onChange={this.handleInputChange} />
              </Grid>
              <Grid item xs={12}>
                <TextInput fullWidth field={role} onChange={this.handleInputChange} />
              </Grid>
              <Grid item xs={12} className={classes.center}>
                <Button
                  variant='contained'
                  color='secondary'
                  className={classes.button}
                  onClick={this.handleRegister}
                >
                  {t('registration.Register')}
                </Button>
              </Grid>
              <Grid item xs={12} className={classes.center}>
                {
                  failedAttempt &&
                  <Typography color='error'>{failedMessage}</Typography>
                }
              </Grid>
              <Grid item xs={12} className={classes.center}>
                <Typography component={Link} to={registrationCheckHref}>{t('registration.Already registered? Click here to check your registration status')}</Typography>
              </Grid>
            </Grid>
          </Paper >

        </Grid>
        <Grid item xs={2} />

      </Grid >
    )
  }
}


RegistrationForm.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

const styles = theme => ({
  paper: {
    padding: theme.spacing.unit * 2,
  },
  center: {
    textAlign: 'center',
    marginTop: theme.spacing.unit * 2,
  },
  grow: {
    flexGrow: 1,
  },
});

const emptyFormState = {
  firstName: {
    label: "First Name",
    id: 'firstName',
    value: '',
    invalid: false,
    required: true,
  },
  lastName: {
    label: "Last Name",
    id: 'lastName',
    value: '',
    invalid: false,
    required: true,
  },
  email: {
    label: "Email",
    id: 'email',
    value: '',
    invalid: false,
    required: true,
  },
  phone: {
    label: "Phone",
    id: 'phone',
    value: '',
    invalid: false,
  },
  organization: {
    label: "Organization",
    id: 'organization',
    value: '',
    invalid: false,
  },
  role: {
    label: "Role in Organization",
    id: 'role',
    value: '',
    invalid: false,
  },
  failedAttempt: false,
  failedMessage: '',
};

export default
  withStyles(styles, { withTheme: true })(
    withSnackbar(
      withRouter(
        withTranslation()(RegistrationForm))));
