import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import { withUserContext } from '../contexts/UserContext.js';

import { signOutUrl, clearToken } from '../../utils/AzureADB2C';

class LoginButton extends Component {
  handleClick = (e) => {
    const { user } = this.props;
    if (user.isAuthenticated) {
      e.preventDefault();
      clearToken();
      window.location = signOutUrl;
    }
  }

  render() {
    const { classes, user } = this.props;
    let message = user.isAuthenticated ? 'Log out' : 'Log in';
    return (
      <Button component={Link} to='/login' variant='contained' color='secondary' className={classes.button} onClick={this.handleClick}>
        {message}
      </Button>
    )
  }
}


const styles = theme => ({
  button: {
    margin: theme.spacing.unit,
  },
  link: {
    textDecoration: 'none',
  },
  leftIcon: {
    marginRight: theme.spacing.unit,
  }
});

export default
  withStyles(styles, { withTheme: true })(
    withUserContext(LoginButton));
