export const GetRegistrationFromPortal = (email) => {
  const req = {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    }
  };

  let url = [process.env.REACT_APP_PORTAL_URL, 'registration', 'checkstatus', '?email=' + email].join('/');
  return fetch(url, req);
}
