import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import registerServiceWorker from './registerServiceWorker';


import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { CookiesProvider } from 'react-cookie';
import { SnackbarProvider } from 'notistack';
import UserProvider from './components/contexts/UserContext';
import OrganizationTypesProvider from './components/contexts/OrganizationTypesContext';
import LanguageProvider from './components/contexts/LanguageContext';

import { MuiPickersUtilsProvider } from 'material-ui-pickers';
import DateFnsUtils from '@date-io/date-fns';
import './translation/i18n';
import 'react-table/react-table.css';


const theme = createMuiTheme(
  {
    typography: {
      useNextVariants: true,
    },
    color: {
      light: '#fff',
    },
    palette: {
      primary: {
        light: '#f05545',
        main: '#b71c1c',
        dark: '#7f0000',
        contrastText: '#fff',
      },
      secondary: {
        light: '#484848',
        main: '#212121',
        dark: '#000000',
        contrastText: '#fff',
      },
    }
  }
);

const snackbarAnchor = {
  vertical: 'bottom',
  horizontal: 'right',
}

// b2cauth.initialize({
//   //instance: 'https://canadasoccerexternal.b2clogin.com/tfp/',
//   tenant: 'canadasoccerexternal.onmicrosoft.com',
//   signInPolicy: 'b2c_1_signin',
//   applicationId: '8e9074af-9a88-4739-8cf1-97e7b1a6d321',
//   cacheLocation: 'sessionStorage',
//   scopes: ['openid', 'https://canadasoccerexternal.onmicrosoft.com/nsrlocal/read', 'https://canadasoccerexternal.onmicrosoft.com/nsrlocal/user_impersonation'],
//   redirectUri: process.env.REACT_APP_SIGN_IN_REPLY_URL,
//   postLogoutRedirectUri: process.env.REACT_APP_LOGOUT_REDIRECT_URL,
// })




const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');

ReactDOM.render(
  <BrowserRouter basename={baseUrl}>
    <MuiThemeProvider theme={theme}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <CookiesProvider>
          <SnackbarProvider maxSnack={1} anchorOrigin={snackbarAnchor} autoHideDuration={1000}>
            <LanguageProvider>
              <UserProvider>
                <OrganizationTypesProvider>
                  <App />
                </OrganizationTypesProvider>
              </UserProvider>
            </LanguageProvider>
          </SnackbarProvider>
        </CookiesProvider>
      </MuiPickersUtilsProvider>
    </MuiThemeProvider>
  </BrowserRouter>,
  rootElement);

registerServiceWorker();
