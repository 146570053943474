import React from 'react';
import styled from 'styled-components';
import { Typography, Button } from '@material-ui/core';

import PlayerRegistration from './PlayerRegistration';
import { Item, Label, Value, ItemContainer } from './MiscComponents';

const Container = styled.div`
  color: rgba(0, 0, 0, 0.87);
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
`
const Title = styled.div`
  border-bottom: 1px solid #EDF2F7;
`

const ProximityScore = styled.div`
  float: right;
`

const Registrations = styled.div`
`

const ButtonControls = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: baseline;
`

function PersonPlayerCard({
  personDetails: { fsbPerson, fsbPlayerRegistrations, proximityScore } = {},
  title, personDetailsBoolMap, isNewRecord, onRemove, onDuplicateFound }) {

  if (fsbPerson === null || fsbPerson === undefined) {
    return null;
  }

  let fsbPersonBoolMap = (personDetailsBoolMap || {}).fsbPerson || {};
  let fsbPlayerRegistrationsBoolMap = (personDetailsBoolMap || {}).fsbPlayerRegistrations || [];

  return (
    <Container>
      {
        title &&
        <Typography variant='h4' gutterBottom>{title}</Typography>
      }
      <Title>
        <Typography variant='h6'>
          Person Details
          {
            !isNewRecord &&
            <ProximityScore>{`${(proximityScore * 100).toFixed(2)}%`}</ProximityScore>
          }
        </Typography>
      </Title>
      <ItemContainer>
        <Item flagged={fsbPersonBoolMap.fifaId}>
          <Label>FIFA Id</Label>
          <Value>{fsbPerson.fifaId || 'N/A'}</Value>
        </Item>
        <Item flagged={fsbPersonBoolMap.firstName}>
          <Label>First Name</Label>
          <Value>{fsbPerson.firstName || 'N/A'}</Value>
        </Item>
        <Item flagged={fsbPersonBoolMap.lastName}>
          <Label>Last Name</Label>
          <Value>{fsbPerson.lastName || 'N/A'}</Value>
        </Item>
        <Item flagged={fsbPersonBoolMap.localFirstName}>
          <Label>Local First Name</Label>
          <Value>{fsbPerson.localFirstName || 'N/A'}</Value>
        </Item>
        <Item flagged={fsbPersonBoolMap.localLastName}>
          <Label>Local Last Name</Label>
          <Value>{fsbPerson.localLastName || 'N/A'}</Value>
        </Item>
        <Item flagged={fsbPersonBoolMap.dateOfBirth}>
          <Label>Date of birth</Label>
          <Value>{fsbPerson.dateOfBirth ? new Date(fsbPerson.dateOfBirth).toLocaleDateString() : 'N/A'}</Value>
        </Item>
        <Item flagged={fsbPersonBoolMap.gender}>
          <Label>Gender</Label>
          <Value>{fsbPerson.gender || 'N/A'}</Value>
        </Item>
        <Item flagged={fsbPersonBoolMap.placeOfBirth}>
          <Label>Place of Birth</Label>
          <Value>{fsbPerson.placeOfBirth || 'N/A'}</Value>
        </Item>
        <Item flagged={fsbPersonBoolMap.countryOfBirth}>
          <Label>Country of Birth</Label>
          <Value>{fsbPerson.countryOfBirth || 'N/A'}</Value>
        </Item>
        <Item flagged={fsbPersonBoolMap.nationality}>
          <Label>Nationality</Label>
          <Value>{fsbPerson.nationality || 'N/A'}</Value>
        </Item>
      </ItemContainer>
      <Title>
        <Typography variant='h6'>Registration Details</Typography>
      </Title>
      <Registrations>
        {
          fsbPlayerRegistrations &&
          fsbPlayerRegistrations.map((pr, i) => {
            return <PlayerRegistration playerRegistration={pr} key={i} registrationBoolMap={fsbPlayerRegistrationsBoolMap[i] || {}} />
          })
        }
      </Registrations>
      {
        !isNewRecord &&
        <ButtonControls>
          <Button variant='contained' color='primary' onClick={onDuplicateFound}>Duplicate</Button>
          <Button variant='contained' color='secondary' onClick={onRemove}>Not a duplicate</Button>
        </ButtonControls>
      }
    </Container>
  )
}


export default PersonPlayerCard;