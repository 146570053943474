const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const postalRegex = /^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ -]?\d[ABCEGHJ-NPRSTV-Z]\d$/i;
const websiteRegex = /https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,}/;
const phoneRegex = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;

export function isInvalid(field) {
  let invalid;
  console.log(field.id);
  console.log(field.value);

  switch (field.id) {
    case 'active':
      invalid = field.value == null ? true : false;
      field.error = invalid ? "Active indicator must be turned on or off" : "";
    case 'organizationName':
      invalid = field.value == null ? true : field.value.toString().trim().length === 0 && field.required;
      field.error = invalid ? "Please enter an organization name" : "";
      break;
    case 'municipality':
      invalid = field.value == null ? true : field.value.trim().length === 0;
      field.error = invalid ? "Please enter the municipality" : "";
      break;
    case 'organizationTypeCode':
      invalid = field.value == null ? true : field.value.length === 0;
      field.error = invalid ? "Please enter an organization type" : "";
      break;
    case 'lat':
      invalid = field.value == null ? false : isNaN(field.value);
      field.error = invalid ? "Please enter the numeric value of the latitude of the organization headquarters" : "";
          break;
    case 'long':
      invalid = field.value == null ? false : isNaN(field.value);
      field.error = invalid ? "Please enter the numeric value of the longitude of the organization headquarters" : "";
      break;
    case 'addressLine1':
      invalid = field.value == null ? field.value = "" : field.value.length == 0 && field.required;
      field.error = invalid ? "Please enter address" : "";
      break;
    case 'provinceCode':
      invalid = field.value == null ? field.value = "" : field.value.length === 0 && !ValidProvinceCode(field.value.toUpperCase());
      field.error = invalid ? "Please enter a correct province code" : "";
      break;
    case 'postalCode':
      invalid = field.value == null ? field.value = "" : field.value.length === 0 || (field.value.length != 0 && !postalRegex.test(String(field.value).toLowerCase()));
      field.error = invalid ? "Please enter a correct postal code" : "";
      break;
    case 'email':
      invalid = field.value == null ? field.value = "" : field.value.trim().length != 0 && !emailRegex.test(String(field.value).toLowerCase());
      field.error = invalid ? "Please enter a correct email" : "";
      break;
    case 'phoneNumber':
      invalid = field.value == null ? field.value = "" : field.value.trim().length != 0 && !phoneRegex.test(String(field.value).toLowerCase());
      field.error = invalid ? "Please enter correct phone number" : "";
      break;
    case 'website':
      invalid = field.value == null ? true : field.value.trim().length != 0 && !websiteRegex.test(String(field.value).toLowerCase());
      field.error = invalid ? "Please enter correct website url" : "";
      break;
    default:
      invalid = field.error ? true : false;
      break;
  }

  field.invalid = invalid;
  return field;
}

function ValidProvinceCode(val) {
    return ["AB", "BC", "MB", "NB", "NL", "NS", "NT", "NU", "ON", "PE", "QC", "SK", "YK"].includes(val);
}


export function AnyInvalid(formFields) {
  let newFormFields = {};
  let allValid = true;
  for (let field in formFields) {
    let newFieldState = Object.assign({}, formFields[field]);
    newFieldState = isInvalid(newFieldState);
    allValid = allValid && !newFieldState.invalid;
    newFormFields[field] = newFieldState;
  }

  return {
    formFields: newFormFields,
    anyInvalid: !allValid
  }
}