import { EditorState, convertToRaw, convertFromRaw } from 'draft-js';

export function ConvertFromRawToContent(raw) {
  return convertFromRaw(JSON.parse(Buffer.from(raw, 'base64').toString('utf8')));
}
export function ConvertFromRawToEditorState(raw) {
  return EditorState.createWithContent(convertFromRaw(JSON.parse(Buffer.from(raw, 'base64').toString('utf8'))));
}

export function ConvertFromContentToRaw(content) {
  return Buffer.from(JSON.stringify(convertToRaw(content))).toString('base64');
}