import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Paper, Typography } from '@material-ui/core';
import { PageTitle } from '../components/text/Titles';


function ContactPage() {
  const { t } = useTranslation();
  //const { classes } = this.props;
  return (
      <Grid container direction="column" spacing={22}>
          <Grid style={{ marginBottom: 5 }} item xs={12}>
              <PageTitle text={t('contact.title')}/>
          </Grid>
          <Grid item style={{ marginBottom: 10 }} xs={8} container direction="column" spacing={24}>
          <Grid item container direction="column" spacing={8}>
          <Grid item>
              <Typography style={{ fontWeight: 'bold', fontStyle: 'italic' }} variant='h6'>{t('contact.registerTitle')}</Typography>
          </Grid>
          <Grid item>
              <Typography variant='body1'>{t('contact.registerBody')}</Typography>
          </Grid>
          </Grid>
          <Grid item container direction="column" spacing={8}>
          <Grid item>
              <Typography style={{ fontWeight: 'bold', fontStyle: 'italic' }} variant='h6'>{t('contact.updateOrgTitle')}</Typography>
          </Grid>
          <Grid item>
              <Typography variant='body1'>{t('contact.updateOrgBody')}</Typography>
          </Grid>
          </Grid>
          <Grid item container direction="column" spacing={8}>
          <Grid item>
              <Typography style={{ fontWeight: 'bold', fontStyle: 'italic' }} variant='h6'>{t('contact.vendorTitle')}</Typography>
          </Grid>
          <Grid item>
              <Typography variant='body1'>{t('contact.vendorBody')}</Typography>
          </Grid>
          </Grid>
          </Grid>
          <Grid item>
              <Typography style={{ fontWeight: 'bold', fontStyle: 'italic' }} variant='body1'>{t('contact.contactUs')}</Typography>
          </Grid>
      </Grid>
  )
}


const classes = theme => ({
    paper: {
        padding: theme.spacing.unit * 10,
        textAlign: 'center',
    },
    pageTitle: {
        marginTop: theme.spacing.unit * 3,
        marginBottom: theme.spacing.unit * 3,
    },
    section: {
        marginBottom: theme.spacing.unit * 2,
    },
    center: {
        textAlign: 'center',
    },
});

export default ContactPage;


