import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { withSnackbar } from 'notistack';
import { Link } from 'react-router-dom';
import { Grid, Typography, Button } from '@material-ui/core';
import classNames from 'classnames';
import ReactTable from 'react-table';

import { GetAllOrganizationsAsAdminFromPortal, GetParentOrganizationFromPortal } from '../../../../api/organization/GetOrganizationsFromPortal';
import { PostTriggerFIFASync } from '../../../../api/organization/PostOrganizationToPortal';
import StatusCell, { StatusFilterMethod, StatusFilterComponent } from '../../../../components/table/StatusCell';
import LoadingSpinner from '../../../../components/LoadingSpinner';
import { withOrganizationTypes } from '../../../../components/contexts/OrganizationTypesContext';
import OrganizationTypeCell, { OrganizationTypeFilterMethod, OrganizationTypeFilterComponent } from '../../../../components/table/OrganizationTypeCell';
import { withTranslation } from 'react-i18next';
import { PageTitle } from '../../../../components/text/Titles';

class OrganizationTable extends Component {
  displayName = OrganizationTable.name;
  constructor(props) {
    super(props);
    this.state = {
      organizations: [],
      initialLoad: true,
      loading: true,
      totalPages: null,
      sorting: "name",
      pageSize: 10,
      currentPage: 0,
    }
  }

  componentDidMount() {
    const { organizations } = this.state;
    if (organizations.length > 0) {
      return;
    }

    this.fetchData();
  }

  getColumns = () => {
    const { match, organizationTypes } = this.props;
    let baseEditRoute = `${match.path}/edit`;
    return [
      {
        Header: 'Name',
        accessor: 'organizationName',
      },
      {
        Header: 'Type',
        accessor: 'organizationTypeCode',
        Cell: cellInfo => <OrganizationTypeCell typeCode={cellInfo.value} />,
        filterMethod: OrganizationTypeFilterMethod,
        Filter: ({ filter, onChange }) => OrganizationTypeFilterComponent({ filter, onChange, organizationTypes })

      },
      {
        Header: 'Municipality',
        accessor: 'municipality'
      },
      {
        Header: 'Province',
        accessor: 'provinceCode'
      },
      {
        Header: 'Parent Organization',
        accessor: 'parentOrganizationCs.organizationName'
      },
      {
        Header: 'Email',
        accessor: 'emailAddress'
      },
      {
        Header: 'Last Modified',
        accessor: 'lastUpdateDateTime',
      },
      {
        Header: 'Status',
        accessor: 'activeIndicator',
        Cell: cellInfo => {
          return <StatusCell value={cellInfo.value} editTo={`${baseEditRoute}/${cellInfo.original.organizationId}`} />
        },
        filterMethod: StatusFilterMethod,
        Filter: StatusFilterComponent,
      },
    ]
  }

    fetchData = (state, instance) => {
        this.setState({ loading: true });

        const { filtered, page, pageSize, sorted } = state || {};

        GetAllOrganizationsAsAdminFromPortal(filtered, sorted, pageSize, page)
            .then(res => res.json())
            .then(json =>
            {
                this.setState({
                    organizations: json.organizations.map(o => JSON.parse(o)),
                    totalPages: json.totalPages,
                    initialLoad: false,
                    loading: false,
                });

          }
      )
      .catch(e => {
        this.props.enqueueSnackbar(`Error fetching organizations`, { variant: 'error', autoHideDuration: 5500 });
        console.log(e);
      });
  }


    handleSubmit = () => {
        const { redirectRoute } = this.props;

        let promise;
        promise = PostTriggerFIFASync();

        promise
            .then(res => {
                if (res.ok) {
                    this.props.enqueueSnackbar(`Successfully triggered FIFA Sync`, { variant: 'success', autoHideDuration: 5500 });
                    this.props.history.push(redirectRoute);
                }
                else {
                    this.props.enqueueSnackbar(`Error triggering FIFA Sync`, { variant: 'error', autoHideDuration: 5500 });
                    console.log(res);
                }
            })
            .catch(e => {
                this.props.enqueueSnackbar('Error triggering FIFA Sync', { variant: 'error', autoHideDuration: 5500 });
                console.log(e)
            });
    }

  render() {
    const { initialLoad, organizations, totalPages, loading, pageSize, filtered } = this.state;
    const { match, classes, t } = this.props;

    if (initialLoad) {
      return <LoadingSpinner />
    }

    let columns = this.getColumns();

    return (
      <Grid container direction="column" spacing={12}>
        <Grid item>
          <PageTitle text={t('organization.organizationAdministration')} />
        </Grid>
        <Grid style={{ paddingBottom: 10}} item direction="row-reverse" container spacing={8}>
          <Grid item><Button variant='contained' component={Link} to={`${match.path}/add`} color='primary'>Create Organization</Button></Grid>
          <Grid item><Button variant='contained' onClick={this.handleSubmit} color='primary'>Initiate FIFA Sync</Button></Grid>
        </Grid>
        <Grid item xs={12}>
          <ReactTable
            data={organizations}
            defaultFilterMethod={(filter, row) => {
              return String(row[filter.id]).toLowerCase().includes(String(filtered[filter.id].value).toLowerCase())
            }}
            columns={columns}
            className={classNames('-striped -highlight', classes.table)}

            manual
            pages={totalPages}
            loading={loading}
            defaultPageSize={pageSize}
            onFetchData={this.fetchData}
            filterable
          />
        </Grid>
      </Grid>
    )
  }
}


OrganizationTable.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

const styles = theme => ({
  table: {
    fontFamily: theme.typography.fontFamily,
  },
  title: {
    marginBottom: theme.spacing.unit * 2,
  }
});

export default
  withStyles(styles, { withTheme: true })(
    withSnackbar(
      withOrganizationTypes(
        withTranslation()(OrganizationTable))));