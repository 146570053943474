import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';
import { withLocalization } from '../../../../components/contexts/LanguageContext';
import { BodyParagraph, Section } from '../../../../components/text/Paragraphs';
import { SectionTitle } from '../../../../components/text/Titles';
import { OrderedList, ListItem } from '../../../../components/text/Lists';

function Prerequisites(props) {
  const { strings } = props;
  return (
    <Section>
      <SectionTitle text={strings.title} />
      <BodyParagraph text={strings.description} />
      <OrderedList>
        <ListItem text={strings.step1} />
        <ListItem text={strings.step2} />
        <ListItem text={strings.step3} />
      </OrderedList>
    </Section>
  )
}

Prerequisites.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

const strings = {
  en: {
    title: 'Prerequisites',
    description: 'In order to enable the Vendor to submit registrations to the NSR API on behalf of a Soccer Organization the following steps must be completed:',
    step1: 'The Vendor must register with the NSR Portal. An API key is generated for the vendor to use.',
    step2: 'The Soccer Organization must register with the NSR Portal.',
    step3: 'The Soccer Organization must grant the Vendor access through the NSR Portal.',
  },
  fr: {
    title: 'Prerequisites',
      descriptions: "Il existe un environnement de staging sur lequel vous pouvez effectuer des tests avant de mettre votre logiciel en production.\n\nL'url pour staging est `https://api.nsr.canadasoccer.com/nsr/stg/player-registration`\n\n**Assurez-vous que votre environnement est mis sur staging jusqu'� ce que vous soyez s�r qu'il fonctionne correctement.**\n\nLorsque vous �tes pr�t � passer � la production, l�URL de l�API de production est `https://cs-api-mgmt.azure-api.net/nsr/player-registration`.",
    step1: 'Le fournisseur et l�organisation de football doivent s�inscrire sur le portail NSR.',
    step2: 'L�organisation de football accorde au fournisseur l�acc�s � sa cl� API.',
    step3: 'Le fournisseur peut maintenant soumettre des inscriptions au nom de l�organisation de football.',
  },
}

const styles = theme => ({

});

export default
  withStyles(styles, { withTheme: true })(
    withLocalization(strings)(Prerequisites));

