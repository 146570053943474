import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

function PageTitleComponent(props) {
  return (
    <Typography variant='h4' className={props.classes.pageTitle}>
      {props.text}
    </Typography>
  )
}

PageTitleComponent.propTypes = {
  text: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};



function SectionTitleComponent(props) {
  return (
    <Typography variant='h5' className={props.classes.sectionTitle}>
      {props.text}
    </Typography>
  )
}

function SubTitleComponent(props) {
    return (
        <Typography variant='subheading' className={props.classes.subTitle}>
            {props.text}
        </Typography>
    )
}

SectionTitleComponent.propTypes = {
  text: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

const styles = theme => ({
  pageTitle: {
    marginTop: theme.spacing.unit * 1,
    marginBottom: theme.spacing.unit * 1,
  },
  subTitle: {
    marginTop: theme.spacing.unit * 1,
    marginBottom: theme.spacing.unit * 1,
    fontWeight: 500,
    paddingLeft: 10
  },
  sectionTitle: {
    margin: theme.spacing.unit,
  }
});

export const PageTitle = withStyles(styles, { withTheme: true })(PageTitleComponent);
export const SectionTitle = withStyles(styles, { withTheme: true })(SectionTitleComponent);
export const SubTitle = withStyles(styles, { withTheme: false })(SubTitleComponent);