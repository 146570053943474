import React from 'react';
import { withOrganizationTypes } from '../contexts/OrganizationTypesContext';

export function OrganizationTypeFilterComponent(props) {
  const { filter, onChange, organizationTypes } = props;
  return (
    <select
      onChange={e => onChange(e.target.value)}
      style={{ width: '100%' }}
      value={filter ? filter.value : 'all'}
    >
      <option value='all'>Show All</option>
      {
        organizationTypes.map(t => <option key={t.organizationTypeCode} value={t.organizationTypeCode}>{t.organizationTypeNameEn}</option>)
      }
    </select>
  )
}



export function OrganizationTypeFilterMethod(filter, row) {
  console.log(filter, row);
  if (filter.value === 'all') {
    return true;
  }

  return parseInt(filter.value) === parseInt(row.organizationTypeCode);
}



function OrganizationTypeCell(props) {
  return (
    <React.Fragment>
      {(props.organizationTypes.find(o => o.organizationTypeCode === props.typeCode) || {}).organizationTypeNameEn}
    </React.Fragment>
  )
}

export default withOrganizationTypes(OrganizationTypeCell);



