import React, { Component } from 'react';
import { Route } from 'react-router';

import RequestRouter from './admin/RequestRouter';
import UserRouter from './admin/UserRouter';
import DashboardPage from './admin/DashboardPage';
import NewsRouter from './admin/NewsRouter';
import OrganizationRouter from './admin/OrganizationRouter';
import NotificationSubscriptionPage from './admin/NotificationSubscriptionPage';

export class AdminRouter extends Component {
  render() {
    let { match } = this.props;
    return (
      <React.Fragment>
        <Route path={`${match.path}/dashboard`} component={DashboardPage} />
        <Route path={`${match.path}/news`} component={NewsRouter} />
        <Route path={`${match.path}/requests`} component={RequestRouter} />
        <Route path={`${match.path}/users`} component={UserRouter} />
        <Route path={`${match.path}/organizations`} component={OrganizationRouter} />
        <Route path={`${match.path}/notifications`} component={NotificationSubscriptionPage} />
      </React.Fragment>
    )
  }
}
