import { Button, Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel, Grid, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { DatePicker } from 'material-ui-pickers';
import { withSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { GetRegistrationReportFromPortal } from '../../../api/reports/GetReportsFromPortal';
import { withUserContext } from '../../../components/contexts/UserContext';
import saveBlob from '../../../utils/SaveFile';

class RegistrationControls extends Component {
  displayName = RegistrationControls.name;
  constructor(props) {
    super(props);
    this.state = {
      firstName: true,
      lastName: true,
      age: true,
      gender: true,
      registrationLevel: false,
      registrationNature: false,
      registrationFromDate: new Date(new Date().setFullYear(new Date().getFullYear() - 1)),
      registrationToDate: new Date(),
      generatingReport: false,
    }
  }
  handleClick = () => {
    const { firstName, lastName, age, gender, registrationNature, registrationLevel, registrationFromDate, registrationToDate } = this.state;
    let orgId = this.props.orgContext.orgId;
    let query = {
      firstName: firstName,
      lastName: lastName,
      age: age,
      gender: gender,
      registrationNature: registrationNature,
      registrationLevel: registrationLevel,
      registrationFromDate: registrationFromDate,
      registrationToDate: registrationToDate,
    }
    this.setState({ generatingReport: true }, () => {
      GetRegistrationReportFromPortal(orgId, query)
        .then(res => {
          if (res.status === 204) {
            throw new Error('No registrations during that period');
          }
          if (res.ok) {
            return res.blob();
          }
          throw res.text();
        })
        .then(blob => {
          this.props.enqueueSnackbar('Generated Report', { variant: 'success', autoHideDuration: 5500 });
          this.setState({ generatingReport: false, })
          saveBlob(blob, "report.csv")
        })
        .catch(e => {
          console.log(e);
          setTimeout(() => {
            this.setState({ generatingReport: false, })
          }, 1000);
          this.props.enqueueSnackbar(e.message, { variant: 'error', autoHideDuration: 5500 });
        })
    })

  }

  handleChange = field => event => {
    this.setState({ [field]: event.target.checked });
  }
  handleDatechange = field => date => {
    this.setState({ [field]: date });
  }

  render() {
    const { firstName, lastName, age, gender, registrationNature, registrationLevel, registrationFromDate, registrationToDate, generatingReport } = this.state;
    const { classes } = this.props;
    return (
      <Grid container spacing={24}>
        <Grid item xs={12}>
          <Typography variant='h6'>Generate Registration Report</Typography>
        </Grid>
        <Grid item xs={12}>
          <FormControl component='fieldset'>
            <FormLabel component='legend' className={classes.legend}>Include the following fields in the report:</FormLabel>
            <FormGroup>
              <FormControlLabel control={<Checkbox checked={firstName} onChange={this.handleChange('firstName')} value='firstName' />} label='First Name' />
              <FormControlLabel control={<Checkbox checked={lastName} onChange={this.handleChange('lastName')} value='lastName' />} label='Last Name' />
              <FormControlLabel control={<Checkbox checked={age} onChange={this.handleChange('age')} value='age' />} label='Age' />
              <FormControlLabel control={<Checkbox checked={gender} onChange={this.handleChange('gender')} value='gender' />} label='Gender' />
              <FormControlLabel control={<Checkbox checked={registrationLevel} onChange={this.handleChange('registrationLevel')} value='registrationLevel' />} label='Registration Level' />
              <FormControlLabel control={<Checkbox checked={registrationNature} onChange={this.handleChange('registrationNature')} value='registrationNature' />} label='Registration Nature' />
            </FormGroup>
            <FormGroup row>
              <DatePicker
                className={classes.datePicker}
                label='From'
                maxDate={registrationToDate}
                maxDateMessage='Must be before To date'
                showTodayButton
                format='MMMM d, yyyy'
                value={registrationFromDate}
                onChange={this.handleDatechange('registrationFromDate')}
              />
              <DatePicker
                className={classes.datePicker}
                label='To'
                minDate={registrationFromDate}
                minDateMessage='Must be after From date'
                showTodayButton
                format='MMMM d, yyyy'
                value={registrationToDate}
                onChange={this.handleDatechange('registrationToDate')}
              />
            </FormGroup>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <Button disabled={generatingReport} variant='contained' color='primary' onClick={this.handleClick}>{generatingReport ? 'Generating Report' : 'Download Report'}</Button>
        </Grid>
      </Grid>
    )
  }
}

RegistrationControls.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

const styles = theme => ({
  datePicker: {
    margin: theme.spacing.unit,
  },
  legend: {
    color: 'rgba(0, 0, 0, 0.54) !important',
  }
});

export default
  withStyles(styles, { withTheme: true })(
    withSnackbar(
      withUserContext(RegistrationControls)));