import React from 'react';
import styled from 'styled-components';
import { Item, Label, Value, ItemContainer } from './MiscComponents';


const Title = styled.div`
  margin: 0 0 0.5rem 0.25rem;
`

export default function PlayerRegistration({ registrationBoolMap, playerRegistration: { organizationFIFAId, status, level, discipline, registrationNature } }) {



  return (
    <ItemContainer>
      <Title>
        FIFA Player Registration
      </Title>
      <Item flagged={registrationBoolMap.registrationNature}>
        <Label>Registration Nature</Label>
        <Value>{registrationNature || 'N/A'}</Value>
      </Item>
      <Item flagged={registrationBoolMap.organizationFIFAId}>
        <Label>Organization FIFA Id</Label>
        <Value>{organizationFIFAId || 'N/A'}</Value>
      </Item>
      <Item flagged={registrationBoolMap.level}>
        <Label>Level</Label>
        <Value>{level || 'N/A'}</Value>
      </Item>
      <Item flagged={registrationBoolMap.status}>
        <Label>Status</Label>
        <Value>{status || 'N/A'}</Value>
      </Item>
      <Item flagged={registrationBoolMap.discipline}>
        <Label>Discipline</Label>
        <Value>{discipline || 'N/A'}</Value>
      </Item>
    </ItemContainer>
  )
}