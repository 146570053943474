import { getToken } from '../../utils/AzureADB2C';

export const CreateNewsItemToPortal = (json) => {
  const req = {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + getToken(),
    },
    body: JSON.stringify(json)
  };

  let url = [process.env.REACT_APP_PORTAL_URL, 'newsitem', 'add'].join('/');
  return fetch(url, req);
}

export const UpdateNewsItemToPortal = (json) => {
  const req = {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + getToken(),
    },
    body: JSON.stringify(json)
  };

  let url = [process.env.REACT_APP_PORTAL_URL, 'newsitem', 'update'].join('/');
  return fetch(url, req);
}