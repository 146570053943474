import React, { Component } from 'react';
import { withCookies } from 'react-cookie';

const LanguageContext = React.createContext();

class LanguageProvider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedLanguage: props.cookies.get('selectedLanguage') || 'en',
      setLanguage: (newLanguage) => {
        if (newLanguage === 'en' || newLanguage === 'fr') {
          props.cookies.set('selectedLanguage', newLanguage, { path: '/' });
          this.setState({ selectedLanguage: newLanguage });
        }
        else {
          console.log(`Unrecognized language: ${newLanguage}`);
        }
      }
    }
  }

  render() {
    return (
      <LanguageContext.Provider value={this.state}>
        {this.props.children}
      </LanguageContext.Provider>
    )
  }
}

export default withCookies(LanguageProvider);


export const withLocalization = strings => WrappedComponent => {
  return class extends Component {
    render() {
      return (
        <LanguageContext.Consumer>
          {(context) => <WrappedComponent strings={strings[context.selectedLanguage]} setLanguage={context.setLanguage} {...this.props} />}
        </LanguageContext.Consumer>
      )
    }
  }
}