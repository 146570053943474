import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Card, Typography, Button } from '@material-ui/core';

function DashCard(props) {
  return (
    <Card className={props.classes.card}>
      <Typography variant='h5' >{props.title}</Typography>
      <Typography color='textSecondary' className={props.classes.subtitle}>{props.subtitle}</Typography>
      <Button variant='outlined' className={props.classes[props.color]}>
        {
          props.up &&
          <span>&#9650; </span>
        }
        {
          props.down &&
          <span>&#9660; </span>
        }
        {props.buttonText}
      </Button>
    </Card>
  )
}

DashCard.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

const styles = theme => ({
  card: {
    textAlign: 'center',
    padding: theme.spacing.unit * 2,
  },
  subtitle: {
    paddingBottom: theme.spacing.unit,
  },
  green: {
    color: '#4caf50'
  },
  red: {
    color: '#f44336',
  },
});

export default withStyles(styles, { withTheme: true })(DashCard);