import { Grid, Paper, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import React from 'react';
import { SectionTitle, SubTitle } from '../../../components/text/Titles';
import { PhoneFormatter } from '../../../utils/Formatters';

function OrganizationCard(props) {
  const { organization, classes, isVendor, parentOrganizationName, pendingOrg } = props;
  let orgName = `${organization.organizationName} (${parentOrganizationName})`
  if(organization.organizationCs) organization.lat = organization.organizationCs.lat;
  if(organization.organizationCs) organization.long = organization.organizationCs.long;
  return (
    <Paper className={classes.paper}>
      <Grid container spacing={12}>
        <Grid item xs={12}>
          <SectionTitle text='Organization Details' />
        </Grid>
        <Grid item xs={12}>
          <Field label={isVendor ? 'Vendor CS ID' : 'CS ID'} text={organization.organizationCsId} />
          <Field label={'Name'} text={organization.organizationName} />
          <Field label={'Short Name'} text={organization.organizationShortName} />
          <Field label={'Parent'} text={parentOrganizationName} />
          <Field label='Address' text={organization.addressLine1} />
          <Field text={organization.addressLine2} />
          <Field text={`${organization.municipality} ${organization.provinceCode}, ${organization.postalCode}`} />

          <div style={{ height: 12 }} />
          <Field label='Phone' text={PhoneFormatter(organization.phoneNumber)} />
          <Field label='Email' text={organization.emailAddress} />
          <Field label='Website' text={organization.website} />
          <Field label='Latitude' text={organization.lat} />
          <Field label='Longitude' text={organization.long} />
          <Field label='Notes' text={organization.notes} />
        </Grid>
      </Grid>
    </Paper>
  )
}

const styles = theme => ({
  paper: {
    padding: theme.spacing.unit * 4,
  },
  center: {
    textAlign: 'center',
  },
  container: {
    display: 'flex',
    paddingLeft: theme.spacing.unit,
  },
  label: {
    width: 150,
    paddingRight: 5,
    paddingBottom: 5,
    paddingLeft: 20,
    textAlign: 'left',
    color: 'grey',
    fontWeight: 400

  },
  parentText: {
    fontSize: 16,
  }
});

const Field = withStyles(styles, { withTheme: true })(({ classes, label, text }) => {
  return (
    <div className={classes.container}>
      <Typography variant='body1' className={classes.label}>{label}</Typography>
      <Typography variant='body1' className={classes.text}>{text}</Typography>
    </div>
  )
});


export default withStyles(styles, { withTheme: true })(OrganizationCard);