import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { withSnackbar } from 'notistack';
import { Button, Typography, Grid } from '@material-ui/core';
import LoadingSpinner from '../../../components/LoadingSpinner';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { GetPlayerHistoryFromPortal } from '../../../api/reports/GetReportsFromPortal';

class PlayerHistory extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      saving: false,
      player: {},
      organizations: [],
      registrations: [],
    }
  }

  handleBackClick = () => {
    this.props.history.goBack();
  }


  handleSaveClick = () => {
    const { id } = this.props.match.params;
    this.setState({ saving: true }, () => {
      const report = document.getElementById('report');
      html2canvas(report)
        .then(canvas => {
          const imgData = canvas.toDataURL('image/png');
          const pdf = new jsPDF();
          pdf.addImage(imgData, 'JPEG', 0, 0);
          pdf.output('save', `${id}.pdf`);
          this.setState({ saving: false, });
        })
    })

  }

  componentDidMount() {
    const { id } = this.props.match.params;
    GetPlayerHistoryFromPortal(id)
      .then(res => {
        if (res.ok) {
          return res.json();
        }
        throw res.text();
      })
      .then(json => {

        // Remap the lookups to a HashMap on the ID
        let registrationLevels = {};
        for (let i = 0; i < json.registrationLevelTypes.length; i++) {
          registrationLevels[json.registrationLevelTypes[i].registrationLevelTypeCode] = json.registrationLevelTypes[i];
        }

        let registrationNature = {};
        for (let i = 0; i < json.registrationNatureTypes.length; i++) {
          registrationNature[json.registrationNatureTypes[i].playerRegistrationNatureTypeCode] = json.registrationNatureTypes[i];
        }

        let organizations = {};
        for (let i = 0; i < json.organizations.length; i++) {
          organizations[json.organizations[i].organizationId] = json.organizations[i];
        }

        this.setState({
          player: json.player,
          organizations: organizations,
          registrations: json.registrations,
          registrationLevels: registrationLevels,
          registrationNature: registrationNature,
          loading: false,
        });
      })
      .catch(e => {
        console.log(e);
        this.props.enqueueSnackbar(`Error fetching player history with id ${id}`, { variant: 'error', autoHideDuration: 5500 });
      });
  }


  render() {
    const { loading, saving, player, registrations, organizations, registrationLevels, registrationNature } = this.state;
    const { classes } = this.props;

    if (loading) {
      return <LoadingSpinner message={'Loading PDF'} />
    }

    return (
      <div className={classes.container}>
        <Grid container spacing={24}>
          <Grid item>
            <Button variant='contained' onClick={this.handleSaveClick}>Save</Button>
          </Grid>
          <Grid item>
            <Button variant='contained' onClick={this.handleBackClick}>Back</Button>
          </Grid>
        </Grid>
        <div id='report' className={classes.report}>
          <Grid container className={classes.logoContainer}>
            <Grid item>
              <img src='./2014_bg_logo.png' alt='logo' />
            </Grid>
            <Grid item className={classes.grow}>
              <Typography variant='h4' className={classes.logoTitle}>Registration History</Typography>
            </Grid>
          </Grid>
          <Typography variant='h5' className={classes.sectionTitle}>Player Information</Typography>
          <Typography variant='button'>{`Name: ${player.firstName} ${player.lastName}`}</Typography>
          <Typography variant='overline'>{`Date of birth: ${new Date(player.dateOfBirth).toLocaleDateString()}`}</Typography>
          <Typography variant='body2'>{`Canada Soccer ID: ${player.personCsId}`}</Typography>

          <Typography variant='h5' className={classes.sectionTitle}>Registration History</Typography>
          {
            registrations.map((r, i) => {
              var org = organizations[r.organizationId];
              if (!org) {
                return null;
              }
              let level = registrationLevels[r.registrationLevelTypeCode].registrationLevelTypeNameEn;
              let nature = registrationNature[r.playerRegistrationNatureTypeCode].playerRegistrationNatureTypeNameEn;
              return (
                <Grid container spacing={24} className={classes.registrationContainer} key={i}>
                  <Grid item xs={4}>
                    <Typography variant='button'>{org.organizationName}</Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant='overline'>{`${nature} - ${level}`}</Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant='body2'>{`From ${new Date(r.registrationFromDate).toLocaleDateString()} to ${new Date(r.registrationToDate).toLocaleDateString()}`}</Typography>
                  </Grid>
                </Grid>
              )
            })
          }
        </div>
        {saving &&
          <div className={classes.saving}>
            <LoadingSpinner message='Generating PDF' />
          </div>
        }
      </div >
    )
  }
}

PlayerHistory.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

const styles = theme => ({
  logoContainer: {
    alignItems: 'center',
  },
  logoTitle: {
    paddingLeft: 50,
  },
  grow: {
    flexGrow: 1,
  },
  sectionTitle: {
    borderBottom: '1px solid #aaa',
    marginTop: 25,
    marginBottom: 15,
  },
  registrationContainer: {
    // marginBottom: 15,
    display: 'flex',
    alignItems: 'baseline',
  },
  saving: {
    display: 'flex',
    position: 'fixed',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 2,
    backgroundColor: 'rgba(255, 255, 255, 0.9);',
  },
  report: {
    backgroundColor: '#fff',
    boxShadow: '0px 0px 25px 0px rgba(0,0,0,0.3)',
    width: '210mm',
    minHeight: '297mm',
    marginLeft: 'auto',
    marginRight: 'auto',
    padding: '10mm',
  }
});

export default
  withStyles(styles, { withTheme: true })(
    withSnackbar(PlayerHistory));