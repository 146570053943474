import React, { Component } from 'react';
import { Route } from 'react-router';
import ViewOrganization from './organization/ViewOrganization';

import InfoPage from './organization/InfoPage';

export class OrganizationRouter extends Component {
  render() {
    let { match } = this.props;
    return (
      <React.Fragment>
        <Route path={match.path} exact render={(props) => <InfoPage {...props} />} />
        <Route path={`${match.path}/edit/:id`} render={(props) => <ViewOrganization {...props} redirectRoute={match.path} />} />
      </React.Fragment>
    )
  }
}
