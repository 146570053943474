import { getToken } from '../../utils/AzureADB2C';

export function PostOrganizationToPortal(organizationJson) {
  const req = {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + getToken()
    },
    body: JSON.stringify(organizationJson)
  }

  let url = [process.env.REACT_APP_PORTAL_URL, 'organization', 'createorganization'].join('/');
  return fetch(url, req);
}

export function PostUpdateOrganizationToPortal(organizationJson) {
  const req = {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + getToken()
    },
    body: JSON.stringify(organizationJson)
  }

  let url = [process.env.REACT_APP_PORTAL_URL, 'organization', 'updateorganization'].join('/');
  return fetch(url, req);
}

export function PostUpdateOrganizationRequestToPortal(organizationJson) {
    const req = {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getToken()
        },
        body: JSON.stringify(organizationJson)
    }

    let url = [process.env.REACT_APP_PORTAL_URL, 'organization', 'updateorganizationnonadmin'].join('/');
    return fetch(url, req);
}

export function PostOrganizationAPIKeyRegenToPortal(organizationId) {
  let body = {
    organizationId: organizationId,
  };
  const req = {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + getToken()
    },
    body: JSON.stringify(body)
  }

  let url = [process.env.REACT_APP_PORTAL_URL, 'organization', 'resetorganizationapikey'].join('/');
  return fetch(url, req);
}

export function PostFindVendorsWithToPortal(query, orgId) {
  let body = {
    query: query,
    orgId: orgId
  };
  const req = {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body)
  }

  let url = [process.env.REACT_APP_PORTAL_URL, 'organization', 'findvendorswith'].join('/');
  return fetch(url, req);
}

export function PostFindOrganizationsWithToPortal(query) {
  let body = {
    query: query,
  };
  const req = {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body)
  }

  let url = [process.env.REACT_APP_PORTAL_URL, 'organization', 'FindOrganizationsWith'].join('/');
  return fetch(url, req);
}

export function PostGrantVendorAccessToPortal(vendorId, orgId) {
  let body = {
    organizationId: orgId,
    vendorId: vendorId,
  };
  const req = {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + getToken()
    },
    body: JSON.stringify(body)
  }

  let url = [process.env.REACT_APP_PORTAL_URL, 'organization', 'grantvendoraccess'].join('/');
  return fetch(url, req);
}

export function PostRevokeVendorAccessToPortal(vendorId, orgId) {
  let body = {
    organizationId: orgId,
    vendorId: vendorId,
  };
  const req = {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + getToken()
    },
    body: JSON.stringify(body)
  }

  let url = [process.env.REACT_APP_PORTAL_URL, 'organization', 'revokevendoraccess'].join('/');
  return fetch(url, req);
}

export function PostTriggerFIFASync() {
    const req = {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getToken()
        },
        body: ""
    }

    let url = [process.env.REACT_APP_PORTAL_URL, 'organization', 'triggersyncwithfifa'].join('/');
    return fetch(url, req);
}