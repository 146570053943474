import React from 'react';
import './LoadingSpinner.css';
import { Typography } from '@material-ui/core';

function LoadingSpinner(props) {
  const { message } = props;
  return (
    <div style={{ width: '100%', margin: 'auto', textAlign: 'center' }}>
      <Typography variant='overline'>{message ? message : 'Loading'}</Typography>
      <div className="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
    </div>
  )
}


export default LoadingSpinner;