import { Grid, Paper, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ReactTable from 'react-table';
import { Bar, BarChart, CartesianGrid, Cell, Legend, Line, LineChart, Pie, PieChart, Sector, Tooltip, XAxis, YAxis } from 'recharts';
import { withUserContext } from '../../../components/contexts/UserContext';
import './dashboard.css';
import DashCard from './dashboard/DashCard';


class DashboardPage extends Component {
  displayName = DashboardPage.name;
  constructor(props) {
    super(props);
    this.state = {
      activeIndex: 0,
    }
  }

  componentDidMount() {
    const { orgContext, history } = this.props;
    console.log(orgContext);
    if (orgContext.roleName !== process.env.REACT_APP_ADMIN_ROLE) {
      history.push('/portal/profile');
    }

  }

  onPieEnter = (data, index) => {
    this.setState({ activeIndex: index });
  }

  render() {
    const { classes } = this.props;
    return (
      <Grid container spacing={24}>
        <Grid item xs={6}>
          <Paper className={classes.paper}>
            <Grid container spacing={24}>
              <Grid item xs={12}>
                <Typography variant='h5' className={classes.title}>NSR Adoption<span className={classes.right}>641 Registered  <span className={classes.green}>&#9650; 24.46%</span></span></Typography>
              </Grid>
              <Grid item xs={12}>
                <LineChart width={600} height={400} data={lineData} className={classes.graph}
                  margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
                  <XAxis dataKey="name" />
                  <YAxis />
                  <CartesianGrid strokeDasharray="3 3" />
                  <Tooltip />
                  <Legend />
                  <Line type="monotone" dataKey="Vendors" stroke={orange} />
                  <Line type="monotone" dataKey="Organizations" stroke={blue} />
                </LineChart>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={6}>
          <Paper className={classes.paper}>
            <Grid container spacing={24}>
              <Grid item xs={12}>
                <Typography variant='h5' className={classes.title}>Adoption Breakdown</Typography>
              </Grid>
              <Grid item xs={8}>
                <PieChart width={600} height={400}>
                  <Pie
                    dataKey='value'
                    data={pieData1}
                    cx={275}
                    cy={200}
                    innerRadius={80}
                    outerRadius={100}
                    fill="#8884d8"
                    paddingAngle={1}
                  >
                    {
                      pieData1.map((entry, index) => <Cell key={index} fill={COLORS[entry.c % COLORS.length]} />)
                    }
                  </Pie>
                  <Pie
                    activeIndex={this.state.activeIndex}
                    activeShape={renderActiveShape}
                    dataKey='value'
                    data={pieData2}
                    cx={275}
                    cy={200}
                    innerRadius={110}
                    outerRadius={120}
                    fill="#8884d8"
                    paddingAngle={1}
                    onMouseEnter={this.onPieEnter}
                  >
                    {
                      pieData2.map((entry, index) => <Cell key={index} fill={COLORS[entry.c % COLORS.length]} />)
                    }
                  </Pie>
                </PieChart>
              </Grid>
              <Grid item xs={4}>
                <ul className={classes.legend}>
                  <li className={classes.legendItem}><span style={{ paddingRight: 4, fontSize: '2rem', color: red }}>&#8226;</span> No Registrations</li>
                  <li className={classes.legendItem}><span style={{ paddingRight: 4, fontSize: '2rem', color: blue }}>&#8226;</span> No Vendor</li>
                  <li className={classes.legendItem}><span style={{ paddingRight: 4, fontSize: '2rem', color: orange }}>&#8226;</span> Staging Registrations</li>
                  <li className={classes.legendItem}><span style={{ paddingRight: 4, fontSize: '2rem', color: green }}>&#8226;</span> Production Registrations</li>
                </ul>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={2}>
          <DashCard title={641} subtitle={'Organizations'} buttonText={'24.46%'} up color='green' />
        </Grid>
        <Grid item xs={2}>
          <DashCard title={'53.41'} subtitle={'Registrations / Organization'} buttonText={'10.12%'} up color='green' />
        </Grid>
        <Grid item xs={2}>
          <DashCard title={6.33} subtitle={'Avg. Days to Integrate'} buttonText={'32.66%'} down color='green' />
        </Grid>
        <Grid item xs={2}>
          <DashCard title={2} subtitle={'Org. w/o Registrations'} buttonText={'5.31%'} up color='red' />
        </Grid>
        <Grid item xs={2}>
          <DashCard title={2} subtitle={'Org. w/o Vendors'} buttonText={'53.46%'} down color='green' />
        </Grid>
        <Grid item xs={2}>
          <DashCard title={'641 / 500'} subtitle={'Monthly Target'} buttonText={'128.20%'} color='green' />
        </Grid>
        {false && <Grid item xs={6}>
          <Paper className={classes.paper}>
            <Grid container spacing={24}>
              <Grid item xs={12}>
                <Typography variant='h5' className={classes.title}>Daily Registrations<span className={classes.right}>7,279 Average Daily  <span className={classes.green}>&#9650;24.46%</span></span></Typography>
              </Grid>
              <Grid item xs={12}>
                <BarChart width={600} height={400} data={barData} className={classes.graph}
                  margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
                  <XAxis dataKey="name" />
                  <YAxis />
                  <CartesianGrid strokeDasharray="3 3" />
                  <Tooltip />
                  <Legend />
                  <Bar dataKey="Vendors" fill={orange} stackId="a" />
                  <Bar dataKey="Organizations" fill={blue} stackId="a" />
                </BarChart>
              </Grid>
            </Grid>
          </Paper>
        </Grid>}
        <Grid item xs={12}>
          <ReactTable
            data={GetRegistrationData()}
            filterable
            defaultFilterMethod={(filter, row) => String(row[filter.id]).toLowerCase().includes(String(filter.value).toLowerCase())}
            columns={registrationColumns}
            className={classNames('-striped -highlight', classes.table)}
          />
        </Grid>
      </Grid >
    )
  }
}

DashboardPage.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

const styles = theme => ({
  paper: {
    padding: theme.spacing.unit * 2,
  },
  graph: {
    fontSize: '0.8rem',
    fontFamily: 'Roboto, sans-serif',
  },
  legend: {
    fontSize: '1rem',
    fontFamily: 'Roboto, sans-serif',
    listStyle: 'none',
  },
  legendItem: {
    display: 'flex',
    alignItems: 'center',
  },
  title: {
    marginBottom: theme.spacing.unit * 2,
  },
  right: {
    float: 'right',
    fontSize: 14,
  },
  green: {
    color: '#4caf50',
  },
  table: {
    fontFamily: theme.typography.fontFamily,
  }
});

const lineData = [
  { name: 'Feb 4', Organizations: 56, Vendors: 111, Total: 6400 },
  { name: 'Feb 5', Organizations: 79, Vendors: 123, Total: 4398 },
  { name: 'Feb 6', Organizations: 111, Vendors: 129, Total: 11800 },
  { name: 'Feb 7', Organizations: 153, Vendors: 145, Total: 7688 },
  { name: 'Feb 8', Organizations: 256, Vendors: 154, Total: 6690 },
  { name: 'Feb 9', Organizations: 321, Vendors: 194, Total: 6190 },
  { name: 'Feb 10', Organizations: 420, Vendors: 221, Total: 7790 },
];

const barData = [
  { name: 'Feb 4', Organizations: 135, Vendors: 111, Total: 6400 },
  { name: 'Feb 5', Organizations: 142, Vendors: 123, Total: 4398 },
  { name: 'Feb 6', Organizations: 143, Vendors: 129, Total: 11800 },
  { name: 'Feb 7', Organizations: 149, Vendors: 145, Total: 7688 },
  { name: 'Feb 8', Organizations: 152, Vendors: 155, Total: 6690 },
  { name: 'Feb 9', Organizations: 155, Vendors: 162, Total: 6190 },
  { name: 'Feb 10', Organizations: 156, Vendors: 170, Total: 7790 },
];


const pieData2 = [
  { name: 'Apption Missiles', value: 68, c: 0 },
  { name: 'Apption Beancounters', value: 161, c: 0 },
  { name: 'Apption Hornets', value: 253, c: 1 },
  { name: 'Apption Coyotes', value: 421, c: 1 },
  { name: 'Apption Hardbodies', value: 224, c: 2 },
  { name: 'Apption Blasters', value: 234, c: 2 },
  { name: 'Apption Rebels', value: 923, c: 2 },
  { name: 'Apption Tornadoes', value: 132, c: 3 },
  { name: 'Apption Bullets', value: 166, c: 3 },
  { name: 'Apption Jets', value: 233, c: 3 },
  { name: 'Apption Bulldogs', value: 579, c: 3 },
  { name: 'Apption Marionettes', value: 1380, c: 3, },
]

const pieData1 = [
  { name: 'No Registrations', value: AddPieData(pieData2, 0), c: 0 },
  { name: 'No Vendor', value: AddPieData(pieData2, 1), c: 1 },
  { name: 'Staging Registrations', value: AddPieData(pieData2, 2), c: 2 },
  { name: 'Production Registrations', value: AddPieData(pieData2, 3), c: 3 },
]

function AddPieData(src, c) {
  let total = 0;
  for (let i = 0; i < src.length; i++) {
    if (src[i].c === c) {
      total += src[i].value;
    }
  }
  return total;
}

const red = '#f44336';
const blue = '#03a9f4';
const orange = '#ff9800';
const green = '#4caf50';
const COLORS = [red, blue, orange, green];

export default
  withStyles(styles, { withTheme: true })(
    withUserContext(DashboardPage));


const renderActiveShape = (props) => {
  const RADIAN = Math.PI / 180;
  const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle,
    fill, payload, percent, value } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 10) * sin;
  const mx = cx + (outerRadius + 30) * cos;
  const my = cy + (outerRadius + 30) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my;
  const textAnchor = cos >= 0 ? 'start' : 'end';

  return (
    <g>
      <text x={cx} y={cy} dy={8} textAnchor="middle" fill={'#333'}>{payload.name}</text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      />
      <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
      <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
      <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#333">{`Registrations: ${value}`}</text>
      <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill="#999">
        {`Overall ${(percent * 100).toFixed(2)}%`}
      </text>
    </g>
  );
};



const registrationColumns = [
  {
    Header: 'Organization',
    accessor: 'org'
  },
  {
    Header: 'Parent Organization',
    accessor: 'org'
  },
  {
    Header: 'Vendor',
    accessor: 'org'
  },
  {
    Header: 'Province',
    accessor: 'org'
  },
  {
    Header: 'Number of Registrations',
    accessor: 'org'
  },
]

function GetRegistrationData() {
  let data = [];


  return data;
}


