import { Grid, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';
import MDReactComponent from 'markdown-react-js';
import Highlight from 'react-highlight';
import './solarized-light.css';
import classNames from 'classnames';
import { ReportProblem } from '@material-ui/icons'

function BodyParagraphComponent(props) {
  return (
    <Typography variant='body2' className={props.classes.body}>
      {props.text}
    </Typography>
  )
}
BodyParagraphComponent.propTypes = {
  text: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

function MarkdownParagraphComponent(props) {
  return (
    <div className={props.classes.markdown} >
      <MDReactComponent text={props.text} />
    </div>
  )
}
MarkdownParagraphComponent.propTypes = {
  text: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

function CodeSnippetComponent(props) {
  return (
    <Highlight className={classNames(props.language || 'JSON', props.classes.highlight)}>
      {props.text}
    </Highlight>
  )
}
CodeSnippetComponent.propTypes = {
  text: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

function InfoComponent(props) {
  return (
    <Grid container spacing={8}>
      <Grid item xs={4}>
        <Typography style={{ textAlign: 'right', }} variant='body1'>{props.label}</Typography>
      </Grid>
      <Grid item>
        <Typography variant='body1'>{props.text}</Typography>
      </Grid>
    </Grid>
  )
}


function IntentComponent(props) {
  return (
    <Typography variant='body2' className={props.classes.intent}>
      <span className={props.classes.yellow}>
        <ReportProblem className={props.classes.colorYellow} />
        {props.text}
      </span>
    </Typography>
  )
}
IntentComponent.propTypes = {
  text: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

function SectionComponent(props) {
  return (
    <Grid item xs={props.xs || 12} className={props.classes.section}>
      {props.children}
    </Grid>
  )
}
SectionComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

const styles = theme => ({
  body: {
    margin: theme.spacing.unit,
  },
  section: {
    marginBottom: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
  },
  intent: {
    margin: theme.spacing.unit * 2,
    padding: theme.spacing.unit,
    fontSize: '1rem',
  },
  yellow: {
    display: 'inline-flex',
    backgroundColor: '#fff9c4',
    borderLeft: '#ffeb3b 4px solid',
    padding: theme.spacing.unit * 2,
  },
  markdown: {
    marginLeft: theme.spacing.unit,
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: '0.875rem',
    fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
    fontWeight: 400,
    lineHeight: 1.5,
    letterSpacing: `0.01071em`,

  },
  colorYellow: {
    color: '#fbc02d',
  },
  highlight: {
    margin: '8px auto',
    width: '66%',
    padding: theme.spacing.unit * 3,
    boxShadow: '0 0 0 1px rgba(16,22,26,.1), 0 2px 4px rgba(16,22,26,.2), 0 8px 24px rgba(16,22,26,.2)',
    //boxShadow: '0 0 0 1px rgba(16,22,26,.1), 0 1px 1px rgba(16,22,26,.2), 0 2px 6px rgba(16,22,26,.2)'
  }
});

export const Intent = withStyles(styles, { withTheme: true })(IntentComponent);
export const Section = withStyles(styles, { withTheme: true })(SectionComponent);
export const BodyParagraph = withStyles(styles, { withTheme: true })(BodyParagraphComponent);
export const MarkdownParagraph = withStyles(styles, { withTheme: true })(MarkdownParagraphComponent);
export const CodeSnippet = withStyles(styles, { withTheme: true })(CodeSnippetComponent);
export const Info = withStyles(styles, { withTheme: true })(InfoComponent);