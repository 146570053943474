import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router';
import { Paper, Typography, Button, Switch, FormControlLabel, Grid } from '@material-ui/core';
import { UpdateNewsItemToPortal } from '../../api/news/PostNewsToPortal';
import { withSnackbar } from 'notistack';
import parse from 'html-react-parser';
import className from 'classnames';
import { withTranslation } from 'react-i18next';

import { ConvertFromRawToContent } from '../../utils/EditorContentUtils';
import { stateToHTML } from 'draft-js-export-html';
import { withUserContext } from '../contexts/UserContext';

class AdminNewsPreview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeIndicator: props.data.activeIndicator
    }
  }

  toggleActive = () => {
    const { activeIndicator } = this.state;
    const { newsItemId } = this.props.data;
    this.setState({ activeIndicator: !activeIndicator })
    let item = {
      newsItemId: newsItemId,
      activeIndicator: !activeIndicator,
    }
    UpdateNewsItemToPortal(item)
      .then(res => res.json())
      .then(json => {
        this.props.enqueueSnackbar(`Set to ${json.activeIndicator ? 'active' : 'inactive'}`, { variant: 'success', autoHideDuration: 5500 });
      })
      .catch(e => {
        console.log(e);
        this.props.enqueueSnackbar('Error updating active status', { variant: 'error', autoHideDuration: 5500 });
      })
  }

  render() {
    const { activeIndicator } = this.state;
    const { classes, match, orgContext, noAdmin, i18n, t } = this.props;
    const { createdByUser, createdDateTime, lastUpdateUser, lastUpdateDateTime, newsItemId, contentEn, contentFr } = this.props.data;
    let createDateString = new Date(createdDateTime).toLocaleDateString();
    let lastUpdateDateString = new Date(lastUpdateDateTime).toLocaleDateString();
    let isAdmin = !noAdmin && orgContext.roleName === process.env.REACT_APP_ADMIN_ROLE

    let html = null;
    if (contentEn && i18n.language === 'en') {
      html = parse(stateToHTML(ConvertFromRawToContent(contentEn)));
    }
    else if (contentFr && i18n.language === 'fr') {
      html = parse(stateToHTML(ConvertFromRawToContent(contentFr)));
    }

    return (
      <Grid container spacing={16}>
        <Grid item xs={isAdmin ? 8 : 12}>
          <Paper className={className(classes.paper, !activeIndicator ? classes.inactive : {})}>
            {html}
            {isAdmin &&
              <React.Fragment>
                <Typography>{`Created on ${createDateString} by ${createdByUser}`}</Typography>
                <Typography color='textSecondary'>Last modified on {lastUpdateDateString} {isAdmin ? `by ${lastUpdateUser}` : ''}</Typography>
              </React.Fragment>
            }
            {!isAdmin &&
              <Typography color='textSecondary' style={{ textAlign: 'right' }}>{`${t('news.Created on')} ${createDateString}`}</Typography>
            }
          </Paper>
        </Grid>
        {
          isAdmin &&
          <Grid item xs={4}>
            <div className={classes.adminGrid}>
              <div>
                <FormControlLabel
                  control={
                    <Switch checked={activeIndicator} color='secondary' onChange={this.toggleActive} />
                  }
                  label='Active Post'
                />
              </div>
              <Button component={Link} to={`${match.path}/edit/${newsItemId}`} color='primary'>Edit Post</Button>
            </div>
          </Grid>
        }
      </Grid>
    )
  }
}

AdminNewsPreview.propTypes = {
  data: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

const styles = theme => ({
  adminGrid: {
    padding: theme.spacing.unit * 2,
    boxShadow: '-8px 0px 10px -10px rgba(0, 0, 0, 0.3)',
    height: '100%',
  },
  inactive: {
    opacity: 0.3,
  },
  paper: {
    padding: theme.spacing.unit * 2,
  },
});

export default
  withStyles(styles, { withTheme: true })(
    withRouter(
      withSnackbar(
        withUserContext(
          withTranslation()(AdminNewsPreview)))));