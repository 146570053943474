import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { ListItem, Avatar, ListItemText } from '@material-ui/core';
import classNames from 'classnames';
import { Business, DomainDisabled } from '@material-ui/icons';

class UserOrganizationItem extends Component {
  displayName = UserOrganizationItem.name;
  constructor(props) {
    super(props);
    this.state = {
      hovered: false,
    }
  }

  handleMouseEnter = () => this.setState({ hovered: true })
  handleMouseLeave = () => this.setState({ hovered: false })

  handleVisibilityClick = () => {
    const { organizationCs, onOrgTerminationToggle } = this.props;
    onOrgTerminationToggle(organizationCs);
  }

  render() {
    const { hovered } = this.state;
    const { organizationCs, until, classes } = this.props;

    let now = new Date();
    let visible = true;
    let secondaryText;

    console.log(until);
    console.log(now);

    // if null or undefined
    if (!until) {
      secondaryText = 'Indefinite';
    }
    else if (new Date(until) < now) {
      visible = false;
      //secondaryText = 'Terminating on Save';
      //}
      //else {
      let localeOptions = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
      secondaryText = `Until ${new Date(until).toLocaleDateString('en-ca', localeOptions)}`;
    }

    return (
      <ListItem className={classNames(classes.root, !visible ? classes.notVisible : {}, hovered ? classes.hovered : {})} onMouseEnter={this.handleMouseEnter} onMouseLeave={this.handleMouseLeave} onClick={this.handleVisibilityClick}>
        <Avatar className={classes.avatar}>
          {
            visible ? <Business /> : <DomainDisabled className={classes.glyph} />
          }
        </Avatar>
        <ListItemText primary={organizationCs.organizationName} secondary={secondaryText} />
      </ListItem>
    )
  }
}

UserOrganizationItem.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

const styles = theme => ({
  root: {
    cursor: 'pointer',
  },
  hovered: {
    backgroundColor: theme.palette.background.default,
  },
  avatar: {
    backgroundColor: '#969696',
  },
  notVisible: {
    opacity: 0.5,
  },
});

export default withStyles(styles, { withTheme: true })(UserOrganizationItem);