import { getToken } from '../../utils/AzureADB2C';

export const GetFSBPersonData = id => {
  const req = {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + getToken()
    },
  };

  let url = [process.env.REACT_APP_PORTAL_URL, 'duplicate', 'GetFSBPersonData', id].join('/');
  return fetch(url, req);
}



export const GetFIFADuplicates = id => {
  const req = {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + getToken()
    },
  };

  let url = [process.env.REACT_APP_PORTAL_URL, 'duplicate', 'GetFifaDuplicates', id].join('/');
  return fetch(url, req);
}
