import { Grid, Typography, IconButton, Button } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { withSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { GetAccessibleApiKeysFromPortal, GetMyOrganizationsFromPortal, GetParentOrganizationFromPortal, GetPendingOrganizationAsAdminFromPortal, GetOrganizationsAsVendorFromPortal } from '../../../api/organization/GetOrganizationsFromPortal';
import { withUserContext } from '../../../components/contexts/UserContext';
import LoadingSpinner from '../../../components/LoadingSpinner';
import { PageTitle } from '../../../components/text/Titles';
import OrganizationApiKey from '../admin/organization/OrganizationApiKey';
import APIAccessTable from './APIAccessTable';
import APIKeyManagement from './APIKeyManagement';
import OrganizationCard from './OrganizationCard';
import { withTranslation } from 'react-i18next';
import EditIcon from '@material-ui/icons/Edit';
import { Link } from 'react-router-dom';
import { CSVLink, CSVDownload } from "react-csv";
import SaveIcon from '@material-ui/icons/Save';


class InfoPage extends Component {
  displayName = InfoPage.name;

  constructor(props) {
    super(props);
    this.state = {
      organization: undefined,
      apiKey: undefined,
      apiKeyAccess: undefined,
      parentOrg: undefined,
      pendingOrg: undefined,
      exportData: [],
      exportDataLoaded: false
    }
  }

  componentDidMount() {
    const { orgContext, t } = this.props;

    if (orgContext.roleName === process.env.REACT_APP_VENDOR_ROLE) {
      this.fetchExportData();
      GetAccessibleApiKeysFromPortal(orgContext.orgId)
        .then(res => {
          if (res.ok) {
            return res.json();
          }
          throw res.text();
        })
        .then(json => {
          this.setState({ apiKeyAccess: json });
        })
        .catch(e => {
          this.props.enqueueSnackbar(t('organization.Error fetching API keys'), { variant: 'error' });
          console.log(e);
        });
    }

    GetPendingOrganizationAsAdminFromPortal(orgContext.orgId)
      .then(res => {
          if (res.status === 204) {
              this.setState({ pendingOrg: false });
              return null;
          }
          if (res.ok) {
              return res.json();
          }
          throw res.text();
      })
      .then(json => {
          if (!json) return;
          json.organizationCs = "";
          this.setState({
              parentOrg: json.parentOrganizationCs,
              organization: json,
              pendingOrg: true
          })
      })
      .catch(e => {
          this.props.enqueueSnackbar(t('organization.Error fetching organization parent'), { variant: 'error' });
          console.log(e);
      })

    GetParentOrganizationFromPortal(orgContext.orgId)
         .then(res => {
            if (res.status === 204) {
                return null;
            }
            if (res.ok) {
                 return res.json();
             }
                throw res.text();
            })
         .then(json => {
             const { pendingOrg } = this.state;
             if(!pendingOrg)
                 this.setState({
                    parentOrg: json,
                 });
              })
         .catch(e => {
             this.props.enqueueSnackbar(t('organization.Error fetching organization parent'), { variant: 'error' });
             console.log(e);
          });

    GetMyOrganizationsFromPortal()
      .then(res => {
        if (res.ok) {
          return res.json();
        }
        throw res.text();
      })
      .then(json => {
        for (let i = 0; i < json.length; i++) {
          let org = json[i].organization;
            if (org.organizationId === parseInt(orgContext.orgId)) {
            const { pendingOrg } = this.state;
            this.setState({
              apiKey: json[i].apiKey || '',
            });
            if(!pendingOrg) this.setState({ organization: org });
            return;
          }
        }
        throw json;
      })
      .catch(e => {
        this.props.enqueueSnackbar(t('organization.Error fetching organizations'), { variant: 'error' });
        console.log(e);
      });

    }

    fetchExportData = async () => {
        let exportChildOrgs = await this.exportData();
        let keys = ['organizationName', 'organizationCsId'];
        let result = exportChildOrgs.map(o => Object.assign(...keys.map(k => ({ [k]: o[k] }))));
        this.setState({
            exportData: result,
            exportDataLoaded: true
        });
    }

    exportData = () => {
        const { orgContext } = this.props;

        console.log(orgContext.orgId);
        return new Promise((resolve, reject) => {
            GetOrganizationsAsVendorFromPortal(orgContext.orgId)
                .then(res => res.json())
                .then(json => {
                    resolve(json.organizations.map(o => JSON.parse(o)));
                }
                )
                .catch(e => {
                    this.props.enqueueSnackbar(`Error fetching organizations`, { variant: 'error' });
                    console.log(e);
                });
        });
    }


  render() {
      const { organization, apiKey, apiKeyAccess, parentOrg, pendingOrg, exportDataLoaded, exportData } = this.state;
    const { orgContext, t, classes, match} = this.props;


    let isVendor = orgContext.roleName === process.env.REACT_APP_VENDOR_ROLE;
    let isOrg = orgContext.roleName === process.env.REACT_APP_ORG_ROLE;
    let baseEditRoute = `${match.path}/edit`

    if (!organization) {
      return <LoadingSpinner />
    }

    const editLink = itemProps => <Link to={`${baseEditRoute}/${organization.organizationId}` || '/'} {...itemProps} />;

    return (
      <Grid container spacing={24}>
          <Grid item xs={12}>
            <Grid container justify="space-between" direction="row" spacing={8}>
                    <Grid item xs={7}>
                        <PageTitle text={`${t('organization.Organization Information')} ${pendingOrg ? "(Pending)": ""}` } />
                    </Grid>
                    <div style={{ padding: 0 }}>
                    <Grid item xs={1}>
                        <IconButton component={editLink} className={classes.edit}>
                            <EditIcon fontSize='small' />
                        </IconButton>
                    </Grid>
                    </div>
            </Grid>
          </Grid>
        {
          !isVendor &&
          <Grid item md={3} />
        }
        <Grid item xs={12} md={6}>
          <OrganizationCard
            organization={organization}
            parentOrganizationName={(parentOrg || {}).organizationName}
            isVendor={isVendor}
            pendingOrg={pendingOrg}
          />
        </Grid>
          <React.Fragment>
            {isVendor &&
            <Grid item xs={12} md={6}>
              <OrganizationApiKey apiKey={apiKey} />
            </Grid>
            }
            {isOrg &&
            <Grid item xs={12}>
              <APIKeyManagement organizationId={organization.organizationId} />
            </Grid>
            }
          </React.Fragment>
        {apiKeyAccess && isVendor &&
          <React.Fragment>
            <Grid item xs={12}>
            <Grid container>
            <Grid item xs>
              <Typography variant='h5'>{t('organization.Associated Organizations')}</Typography>
            </Grid>
            <Grid style={{ textAlign: 'right' }} item xs>
                    <CSVLink className={`${exportDataLoaded ? "" : "btn disabled"}`} asyncOnClick={true} onClick={(event, done) => { done(exportDataLoaded) }} style={{ textDecoration: 'none' }} filename={`${orgContext.orgName.replace(" ", "_")}_Associated_Organizations.csv`} data={exportData}>
                        <Button variant="contained" color={`${exportDataLoaded ? "primary" : "secondary"}`} className={classes.button}>
                            {`${exportDataLoaded ? "" : "Loading "}`}Export Data
                        </Button>
                    </CSVLink>
            </Grid>
            </Grid>
            <Grid item xs={12} style={{ paddingTop: '0.25rem'}}>
              <APIAccessTable data={apiKeyAccess} />
            </Grid>
            </Grid>
          </React.Fragment>
        }
      </Grid>
    )
  }
}

InfoPage.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  orgContainer: {
    padding: theme.spacing.unit,
  },
  paper: {
    padding: theme.spacing.unit * 4,
  },
});
export default
  withStyles(styles, { withTheme: true })(
    withUserContext(
      withSnackbar(
        withTranslation()(InfoPage))));