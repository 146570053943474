import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { FormControlLabel, Checkbox, Grid } from '@material-ui/core';
import { withSnackbar } from 'notistack';
import AsyncSelect from 'react-select/lib/Async';
import classNames from 'classnames';
import { TextInput } from './Inputs';
import { PostFindOrganizationsWithToPortal } from '../../api/organization/PostOrganizationToPortal';

class OrganizationInput extends Component {
  displayName = OrganizationInput.name;
  constructor(props) {
    super(props);
    this.state = {
      registerNew: false,
      organizations: [],
      selectedOrg: props.defaultSelectedOrg || '',
    }
  }

  loadOptions = (inputValue, callback) => {
    PostFindOrganizationsWithToPortal(inputValue)
      .then(res => {
        if (res.ok) {
          return res.json();
        }
        throw res.text();
      })
      .then(json => {
        this.setState({ organizations: json }, () => {
          callback(this.filterOrganizations(inputValue));
        })
      })
      .catch(e => {
        console.log(e);
        this.props.enqueueSnackbar(`Error fetching organizations`, { variant: 'error', autoHideDuration: 5500 });
      })
  }

  filterOrganizations = (inputValue) => {
    const { organizations } = this.state;
    let labels = [];
    for (let i = 0; i < organizations.length; i++) {
      let o = organizations[i];
      if (o.organizationName.toLowerCase().includes(inputValue.toLowerCase())) {
        labels.push({
          label: o.organizationName,
          value: o.organizationId,
        });
      }
    }

    labels.push({
      label: "Refine Search for more results...",
      value: -1
    })

    return labels;
  }

  handleOrgSelectChange = selectedOrg => {
    const { onChange, field } = this.props;
    this.setState({ selectedOrg: selectedOrg }, () => {
      onChange(field.id)({ target: { value: selectedOrg.label, organizationId: selectedOrg.value } });
    });
  }


  handleNewOrgCheckboxChange = () => {
    this.setState({ registerNew: !this.state.registerNew });
  }

  render() {
    const { registerNew, selectedOrg } = this.state;
    const { allowNewOrg, field, onChange, classes } = this.props;



    return (
      <Grid container spacing={24} className={classNames(allowNewOrg ? classes.root : {})}>
        {
          registerNew &&
          <Grid item xs={10}>
            <TextInput fullWidth field={field} onChange={onChange} />
          </Grid>
        }
        {
          !registerNew &&
          <Grid item xs={allowNewOrg ? 10 : 12}>
            <AsyncSelect
              className={classes.select}
              value={selectedOrg}
              cacheOptions
              defaultOptions
              loadOptions={this.loadOptions}
              onChange={this.handleOrgSelectChange}
              placeholder='Select Organization'
              isOptionDisabled={(option) => option.value === -1}
            />
          </Grid>
        }
        {
          allowNewOrg &&
          <Grid item xs={2}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={registerNew}
                  onChange={this.handleNewOrgCheckboxChange}
                />
              }
              label="New"
              labelPlacement="end"
            />
          </Grid>
        }
      </Grid>
    )
  }
}

const styles = theme => ({
  root: {
    alignItems: 'center',
    minHeight: 104,
  },
  select: {
    width: '100%',
  }
});


export default
  withStyles(styles, { withTheme: true })(
    withSnackbar(OrganizationInput));