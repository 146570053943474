import { List, Divider } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { withUserContext } from '../contexts/UserContext';
import { AdminMenuItem, DocumentationMenuItem, DashboardMenuItem, HelpMenuItem, HomeMenuItem, LoginMenuItem, NewsMenuItem, OrganizationMenuItem, OrganizationsMenuItem, ProfileMenuItem, ManualRegistrationItem, ReportsMenuItem } from './items/MenuItems';


class NavMenuDrawer extends Component {

  render() {
    const { classes, user, orgContext, location } = this.props;
    return (
      <div className={classes.root}>
        <List component="nav">
          <HomeMenuItem pathname={location.pathname} />
          {!user.isAuthenticated &&
            <React.Fragment>
              {/*<Divider />*/}
              <LoginMenuItem pathname={location.pathname} />
            </React.Fragment>
          }
          {user.isAuthenticated &&
            <React.Fragment>
              {
                orgContext.roleName === process.env.REACT_APP_ADMIN_ROLE &&
                <React.Fragment>
                  {/*<Divider />*/}
                  <DashboardMenuItem pathname={location.pathname} />
                </React.Fragment>
              }
              {/*<Divider />*/}
              <ProfileMenuItem pathname={location.pathname} />
              {/*<Divider />*/}
              <OrganizationMenuItem pathname={location.pathname} />
              {/*<Divider />*/}
              <NewsMenuItem pathname={location.pathname} />
              {
                orgContext.roleName !== process.env.REACT_APP_ADMIN_ROLE &&
                orgContext.roleName !== process.env.REACT_APP_VENDOR_ROLE &&
                <React.Fragment>
                  {/*<Divider />*/}
                  <ReportsMenuItem />
                </React.Fragment>
              }
              {/*<Divider />*/}
              <ManualRegistrationItem pathname={location.pathname} />
              {/*<Divider />*/}
              <DocumentationMenuItem pathname={location.pathname} />
              {/*<Divider />*/}
              {orgContext.roleName === process.env.REACT_APP_PTSO_ROLE &&
                <React.Fragment>
                    {/*<Divider />*/}
                    <OrganizationsMenuItem pathname={location.pathname} />
                </React.Fragment>
              }
            </React.Fragment>
          }
          {orgContext.roleName === process.env.REACT_APP_ADMIN_ROLE &&
            <React.Fragment>
              {/*<Divider />*/}
              <AdminMenuItem pathname={location.pathname} />
            </React.Fragment>
          }
          {/*<Divider />*/}
          <HelpMenuItem pathname={location.pathname} />
          {/*<Divider />*/}
        </List>
      </div>
    )
  }
}

const styles = theme => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
});

export default
  withStyles(styles, { withTheme: true })(
    withRouter(
      withUserContext(NavMenuDrawer)));
