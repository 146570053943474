import { getToken } from '../../utils/AzureADB2C';

export function PostUpdateUserLoginDate(emailAddress) {
    let body = {
        emailAddress: emailAddress
    };

    const req = {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + getToken()
    },
    body: JSON.stringify(body)
  }

  let url = [process.env.REACT_APP_PORTAL_URL, 'user', 'updateuserlogindate'].join('/');
  return fetch(url, req);
}