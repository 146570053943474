import React, { useState } from 'react';
import { withStyles, Typography, Button, Grid } from '@material-ui/core';
import styled from 'styled-components';
import Select from 'react-select';
import { Phone, Email } from '@material-ui/icons';
import OrganizationCard from '../../../organization/OrganizationCard';
import { PostRequestApprovalToPortal, PostRequestDenialToPortal } from '../../../../../api/registration/PostRequestActionToPortal';

const RequestContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
`
const RequestControl = styled.div`
  margin-bottom: 4rem;
`
const ControlButtons = styled.div`
  margin-top: 2rem;
  display: flex;
  justify-content: space-between;
`


const RequestCard = styled.div`
  padding: 1rem;
  background-color: #fff;
  box-shadow: 0 1px 3px rgba(0,0,0,0.02), 0 1px 2px rgba(0,0,0,0.14);
  border-radius: 4px;
  transition: all 0.4s ease-out;
  :hover {
    box-shadow: 0 3px 6px rgba(0,0,0,0.06), 0 3px 6px rgba(0,0,0,0.13);
  }
`
const RequestPerson = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`

const Photo = styled.div`
  width: 150px;
  height: 150px;
  background-color: #ccc;
  margin: 1rem;
`

const PersonDetails = styled.div`
  margin: 1rem;
`
const IconAndText = styled.div`
  display: flex;
  align-items: center;
  margin-top: 0.2rem;
`



function ViewOrganizationUpdateRequest({ request, organizations, classes, history, enqueueSnackbar }) {
    const { requestId, createdDateTime,
        requestOrganizationDataUpdate: { organizationName, emailAddress, phoneNumber, organizationRoleText, organizationId, placeholderOrganizationName,
        } } = request;

    const [selectedOrgId, setSelectedOrgId] = useState(organizationId);
    let selectedOrgDetails = organizations.find(kvp => kvp.key === selectedOrgId);

    let createDateString = new Date(createdDateTime).toDateString();
    //let organizationName = (selectedOrgDetails || {}).value;
    let organizationDetails = `${organizationRoleText} at ${organizationName || placeholderOrganizationName}`;
    let formattedPhoneNumber = formatPhoneNumber(phoneNumber);


    function PostRequestAnswer(requestId, organizationId, approvedBool) {
        enqueueSnackbar(`Processing request...`, { variant: 'info', persist: true});
        if (Number.isInteger(requestId) && Number.isInteger(organizationId)) {
            let postRequest = approvedBool
                ? PostRequestApprovalToPortal(requestId, organizationId, true)
                : PostRequestDenialToPortal(requestId);

            postRequest
                .then(res => {
                    if (res.ok) {
                        enqueueSnackbar(`Successfully ${approvedBool ? 'approved' : 'denied'} the request`, { variant: 'success', autoHideDuration: 5500 });
                        history.goBack();
                    }
                    else {
                        throw res.text();
                    }
                })
                .catch(e => {
                    console.error(e);
                    enqueueSnackbar(`Error ${approvedBool ? 'approving' : 'denying'} the request`, { variant: 'error', autoHideDuration: 5500 });
                })
        }
    }




    return (
        <React.Fragment>
            <Button variant='contained' color='default' onClick={() => history.goBack()}>Back</Button>
            <RequestContainer>
                <RequestControl>
                    <Typography variant='h5'>Organization Data Update Request #{requestId}</Typography>
                    <Typography variant='overline' gutterBottom>Created on {createDateString}</Typography>
                    <Grid container spacing={24}>
                            <OrganizationCard
                                organization={request.requestOrganizationDataUpdate}
                                //parentOrganizationName={(parentOrg || {}).organizationName}
                            />
                    </Grid>
                    <ControlButtons>
                        <Button variant='contained' color='primary' onClick={() => PostRequestAnswer(requestId, selectedOrgId, true)}>Approve</Button>
                        <Button variant='contained' color='secondary' onClick={() => PostRequestAnswer(requestId, selectedOrgId, false)}>Deny</Button>
                    </ControlButtons>
                </RequestControl>
            </RequestContainer >
        </React.Fragment>
    )
}

const styles = theme => ({
    detailsIcon: {
        marginRight: '1rem',
        color: theme.palette.secondary.light,
    }
})

export default withStyles(styles, { withTheme: true })(ViewOrganizationUpdateRequest)


function formatPhoneNumber(phoneNumberString) {
    var cleaned = ('' + phoneNumberString).replace(/\D/g, '')
    var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)
    if (match) {
        var intlCode = (match[1] ? '+1 ' : '')
        return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('')
    }
    return null
}
