import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

function RegistrationButton(props) {
  const { classes } = props;
  const { t } = useTranslation();

  return (
    <Button
      size="medium"
      component={Link}
      to='/register'
      className={classNames(classes.margin, classes.whiteText)}
    >
      {t('buttons.register')}
    </Button>
  )
}

RegistrationButton.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};


const styles = theme => ({
  margin: {
    margin: theme.spacing.unit,
  },
  whiteText: {
    color: theme.color.light
  }
});


export default withStyles(styles, { withTheme: true })(RegistrationButton);
