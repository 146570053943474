import React from 'react';
import { Route, withRouter } from 'react-router';
import OrganizationTable from './organization/OrganizationTable';
import ViewOrganization from './organization/ViewOrganization';



function OrganizationsRouter(props) {
  const { match } = props;

  return (
    <React.Fragment>
      <Route path={`${match.path}`} exact component={OrganizationTable} />
      <Route path={`${match.path}/edit/:id`} render={(props) => <ViewOrganization {...props} redirectRoute={match.path} />} />
    </React.Fragment>
  )
}

export default withRouter(OrganizationsRouter);