import React, { Component } from 'react';

import { GetAllOrganizationTypesFromPortal } from '../../api/organization/GetOrganizationsFromPortal';

const OrganizationTypesContext = React.createContext();

export default class OrganizationTypesProvider extends Component {

  state = {
    organizationTypes: [],
    getOrganizationTypes: () => {
      return new Promise((resolve, reject) => {
        if(this.state.organizationTypes.length > 0){
            resolve(this.state.organizationTypes);
        }
        else {
          GetAllOrganizationTypesFromPortal()
            .then(res => res.json())
            .then(json => {
              json = json.sort((a, b) => {
                if(a.organizationTypeNameEn < b.organizationTypeNameEn) { return -1; }
                if(a.organizationTypeNameEn > b.organizationTypeNameEn) { return 1; }
                return 0;
              });
              this.setState({
                organizationTypes: json,
              }, () => {
                resolve(this.state.organizationTypes);
              });
            })
            .catch(e => reject(e));
        }
      })
    }
  }
  
  render() {
    return (
      <OrganizationTypesContext.Provider value={this.state}>
        {this.props.children}
      </OrganizationTypesContext.Provider>
    )
  }
}

export function withOrganizationTypes(WrappedComponent) {
  return class extends Component{
    render() {
      return (
        <OrganizationTypesContext.Consumer>
          {(context) => <WrappedComponent organizationTypes={context.organizationTypes} getOrganizationTypes={context.getOrganizationTypes} {...this.props} /> }
        </OrganizationTypesContext.Consumer>
      )
    }  
  }
}