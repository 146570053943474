import React, { Component } from 'react';
import { Typography } from '@material-ui/core';
import { withSnackbar } from 'notistack';
import styled from 'styled-components';

import LoadingSpinner from '../../../../../components/LoadingSpinner';
import { GetFSBPersonData, GetFIFADuplicates } from '../../../../../api/duplicates/GetDuplicatesFromPortal';
import { PostCreateNewRecordWithFIFA, PostAssignFIFAIdToRecord } from '../../../../../api/duplicates/PostDuplicatesResponseToPortal';
import PersonPlayerCard from './duplicateComponents/PersonPlayerCard';
import DuplicateStepper from './duplicateComponents/DuplicateStepper';
import { GetDifferenceBoolMapForFSBComparison } from './duplicateComponents/MiscComponents';


const PageContainer = styled.div`
  display: flex;
`

const Container = styled.div`
  flex-grow: 1;
`

const Card = styled.div`
  padding: 1rem;
  margin: 1rem;
  background-color: #fff;
  box-shadow: 0 1px 3px rgba(0,0,0,0.02), 0 1px 2px rgba(0,0,0,0.14);
  border-radius: 4px;
  transition: all 0.4s ease-out;
  :hover {
    box-shadow: 0 3px 6px rgba(0,0,0,0.06), 0 3px 6px rgba(0,0,0,0.13);
  }
`

const LoadingContainer = styled.div`
  min-height: 600px;
  height: 100%;
  display: flex;
  justify-container: center;
  align-items: center;
`

class ViewFIFADuplicateRequest extends Component {
  displayName = ViewFIFADuplicateRequest.name;
  constructor(props) {
    super(props);
    this.state = {
      isLoadingPerson: true,
      isLoadingDuplicates: true,
      activeStep: 0,
      duplicateBoolMap: undefined,
      personDetails: undefined,
      duplicateDetails: undefined,
    }
  }

  componentDidMount() {

    this.fetchPersonDetails()
      .then(result => {

        const { duplicateDetails, isLoadingDuplicates } = this.state;
        let duplicateBoolMap = undefined;
        if (!isLoadingDuplicates && duplicateDetails !== undefined) {
          duplicateBoolMap = GetDifferenceBoolMapForFSBComparison(result, duplicateDetails)
        }

        this.props.enqueueSnackbar(`Loaded FIFA Person details`, { variant: 'default', autoHideDuration: 5500 });
        this.setState({
          isLoadingPerson: false,
          personDetails: result,
          duplicateBoolMap: duplicateBoolMap,
        })
      });

    this.fetchDuplicateDetails()
      .then(result => {
        console.log(result);
        const { personDetails, isLoadingPerson } = this.state;
        let duplicateBoolMap = undefined;
        if (!isLoadingPerson && personDetails !== undefined) {
          duplicateBoolMap = GetDifferenceBoolMapForFSBComparison(personDetails, result)
        }

        this.props.enqueueSnackbar(`Loaded FIFA Duplicate details`, { variant: 'default', autoHideDuration: 5500 });
        this.setState({
          isLoadingDuplicates: false,
          duplicateDetails: result,
          duplicateBoolMap: duplicateBoolMap,
        })
      });
  }

  fetchPersonDetails = () => {
    const { request: { requestDuplicateResolution: { person: { personCsId } } } } = this.props;

    return new Promise((resolve, reject) => {
      GetFSBPersonData(personCsId)
        .then(res => {
          if (res.ok) {
            return res.json();
          }
          throw res.text();
        })
        .then(json => resolve(json))
        .catch(e => {
          this.props.enqueueSnackbar(`Error fetching FIFA Person details`, { variant: 'error', autoHideDuration: 5500 });
          reject(e);
        });
    })
  }

  fetchDuplicateDetails = () => {
    const { request: { requestDuplicateResolution: { person: { personCsId } } } } = this.props;

    return new Promise((resolve, reject) => {
      GetFIFADuplicates(personCsId)
        .then(res => {
          if (res.ok) {
            return res.json();
          }
          throw res.text();
        })
        .then(json => resolve(json))
        .catch(e => {
          this.props.enqueueSnackbar(`Error fetching FIFA Duplicate details`, { variant: 'error', autoHideDuration: 5500 });
          reject(e);
        });
    })
  }

  handleNext = () => {
    this.setState(prevState => ({
      activeStep: prevState.activeStep + 1,
    }))
  }
  handleBack = () => {
    this.setState(prevState => ({
      activeStep: prevState.activeStep - 1,
    }))
  }
  handleRemove = index => {
    let newDuplicates = [...this.state.duplicateDetails];
    let newBoolMap = [...this.state.duplicateBoolMap];

    newDuplicates.splice(index, 1);
    newBoolMap.splice(index, 1);

    this.setState(prevState => ({
      activeStep: Math.max(prevState.activeStep - 1, 0),
      duplicateBoolMap: newBoolMap,
      duplicateDetails: newDuplicates,
    }));
  }

  handleNewRecord = () => {
    const { request: { requestDuplicateResolution: { person: { personCsId } } } } = this.props;

    this.setState({
      isResolving: true
    });
    const savingData = this.props.enqueueSnackbar(`Processing request...`, { variant: 'info', persist: true });
    PostCreateNewRecordWithFIFA(personCsId)
      .then(res => {
        if (res.ok) {
          this.props.enqueueSnackbar(`Resolved FIFA Duplicate`, { variant: 'success', autoHideDuration: 5500 });
          this.props.history.goBack();
        }
      })
      .catch(e => {
        this.props.enqueueSnackbar(`Error resolving FIFA Duplicate`, { variant: 'error', autoHideDuration: 5500 });
      });

  }

  handleDuplicateFound = index => {
    const { request: { requestDuplicateResolution: { person: { personCsId } } } } = this.props;
    const { duplicateDetails } = this.state;

    this.setState({
      isResolving: true
    });

    var data = {
      csId: personCsId,
      fsbPersonData: duplicateDetails[index]
    }
    const savingData = this.props.enqueueSnackbar(`Processing request...`, { variant: 'info', persist: true });
    PostAssignFIFAIdToRecord(data)
      .then(res => {
        if (res.ok) {
          this.props.enqueueSnackbar(`Resolved FIFA Duplicate`, { variant: 'success', autoHideDuration: 5500 });
          this.props.history.goBack();
        }
      })
      .catch(e => {
        this.props.enqueueSnackbar(`Error resolving FIFA Duplicate`, { variant: 'error', autoHideDuration: 5500 });
      });
  }



  render() {
    const { isLoadingDuplicates, isLoadingPerson, isResolving, personDetails, duplicateDetails, duplicateBoolMap, activeStep } = this.state;
    const { request, organizations, classes, history, enqueueSnackbar } = this.props;

    let personDetailsBoolMap = undefined;
    if (duplicateBoolMap !== undefined) {
      personDetailsBoolMap = duplicateBoolMap[activeStep];
    }

    return (
      <React.Fragment>
        <Typography variant='h5'>FIFA Duplicate Resolution</Typography>
        <PageContainer>
          <Container>
            <Card>
              {
                isLoadingPerson ?
                  (<LoadingContainer>
                    <LoadingSpinner message="Loading FIFA Details" />
                  </LoadingContainer>) :
                  <PersonPlayerCard isNewRecord personDetails={personDetails} title={'New Record'} personDetailsBoolMap={personDetailsBoolMap} />
              }
            </Card>
          </Container>
          <Container>
            <Card>
              {
                isLoadingDuplicates &&
                <LoadingContainer>
                  <LoadingSpinner message="Loading duplicates from FIFA" />
                </LoadingContainer>
              }
              {
                isResolving &&
                <LoadingContainer>
                  <LoadingSpinner message="Contacting FIFA to resolve duplicate" />
                </LoadingContainer>
              }
              {
                !isLoadingDuplicates && !isResolving &&
                <DuplicateStepper
                  duplicateDetails={duplicateDetails}
                  personDetailsBoolMap={personDetailsBoolMap}
                  activeStep={activeStep}
                  onDuplicateFound={this.handleDuplicateFound}
                  onNewRecord={this.handleNewRecord}
                  onRemove={this.handleRemove}
                  onNext={this.handleNext}
                  onBack={this.handleBack} />
              }
            </Card>
          </Container>
        </PageContainer>
      </React.Fragment>
    )
  }
}



export default
  withSnackbar(ViewFIFADuplicateRequest);