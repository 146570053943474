import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import ReactTable from 'react-table';
import classNames from 'classnames';

function APIAccessTable(props) {
  const { classes, data } = props;
  return (
    <ReactTable
      data={data}
      filterable
      defaultFilterMethod={(filter, row) => String(row[filter.id]).toLowerCase().includes(String(filter.value).toLowerCase())}
      columns={columns}
      className={classNames('-striped -highlight', classes.table)}
      defaultPageSize={5}
    />
  )
}

APIAccessTable.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

const styles = theme => ({
  table: {
    fontFamily: theme.typography.fontFamily,
  }
});

const columns = [
  {
    Header: 'Organization',
    id: 'organizationName',
    accessor: a => a.organizationName,
  },
  {
    Header: 'Organization ID',
    id: 'organizationId',
    accessor: a => a.organizationCsId,
  },
]

const keyStyle = {
  color: '#BF263C',
  fontFamily: 'Menlo,Consolas,Courier New,monospace',
  backgroundColor: '#FEFEFE',
  padding: '2px 4px',
  borderRadius: 2
}

export default withStyles(styles, { withTheme: true })(APIAccessTable);