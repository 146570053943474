import styled from 'styled-components';

export const Item = styled.div`
  margin: 0.5rem 0;
  padding: 0.25rem;
  border-left: 0.25rem solid ${props => props.flagged ? '#B71C1C' : '#E2E8F0'};
  background-color: ${props => props.flagged ? '#B71C1C0F' : 'none'};
`
export const Label = styled.div`
  font-size: 0.75rem;
  font-weight: bold;
`
export const Value = styled.div`
  text-transform: capitalize;
`

export const ItemContainer = styled.div`
  margin: 1rem;
`


function DatesAreNotEqual(first, second) {
  return new Date(first).toDateString() !== new Date(second).toDateString();
}

function StringsAreNotEqual(first, second) {
  return (first || "").toLowerCase() !== (second || "").toLowerCase();
}


function GetDifferenceBoolMapBetweenFSBPersons(first, second) {
  first = first || {};
  second = second || {};
  var result = {
    fifaId: StringsAreNotEqual(first.fifaId, second.fifaId),
    firstName: StringsAreNotEqual(first.firstName, second.firstName),
    lastName: StringsAreNotEqual(first.lastName, second.lastName),
    dateOfBirth: DatesAreNotEqual(first.dateOfBirth, second.dateOfBirth),
    gender: StringsAreNotEqual(first.gender, second.gender),
    placeOfBirth: StringsAreNotEqual(first.placeOfBirth, second.placeOfBirth),
    countryOfBirth: StringsAreNotEqual(first.countryOfBirth, second.countryOfBirth),
    nationality: StringsAreNotEqual(first.nationality, second.nationality),
  }
  return result;
}

function GetDifferenceBoolMapBetweenFSBPlayerRegistration(first, second) {
  var result = {
    organizationFIFAId: StringsAreNotEqual(first.organizationFIFAId, second.organizationFIFAId),
    status: StringsAreNotEqual(first.status, second.status),
    level: StringsAreNotEqual(first.level, second.level),
    discipline: StringsAreNotEqual(first.discipline, second.discipline),
    registrationNature: StringsAreNotEqual(first.registrationNature, second.registrationNature)
  }
  return result;
}

function GetDifferenceBoolMapBetweenFSBPersonData(first, second) {
  var result = {
    fsbPerson: GetDifferenceBoolMapBetweenFSBPersons(first.fsbPerson, second.fsbPerson),
    fsbPlayerRegistrations: []
  }

  return result;

  for (let i = 0; i < first.fsbPlayerRegistrations.length; i++) {
    let firstReg = first.fsbPlayerRegistrations[i] || {};
    let secondReg = (second.fsbPlayerRegistrations || []).length < i
      ? second.fsbPlayerRegistrations[i] || {}
      : {};

    result.fsbPlayerRegistrations.push(GetDifferenceBoolMapBetweenFSBPlayerRegistration(firstReg, secondReg))
  }

  for (let i = first.fsbPlayerRegistrations.length; i < (second.fsbPlayerRegistrations || []).length; i++) {
    let firstReg = first.fsbPlayerRegistrations[i] || {};
    let secondReg = second.fsbPlayerRegistrations[i] || {};
    result.fsbPlayerRegistrations.push(GetDifferenceBoolMapBetweenFSBPlayerRegistration(firstReg, secondReg))
  }

  return result;
}


export function GetDifferenceBoolMapForFSBComparison(newRecord, duplicates) {
  var result = [];
  for (let i = 0; i < duplicates.length; i++) {
    result.push(GetDifferenceBoolMapBetweenFSBPersonData(newRecord, duplicates[i]))
  }
  return result;
}