import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import classNames from 'classnames';

function OrderedListComponent(props) {
  return (
    <ol className={props.classes.ol}>
      {props.children}
    </ol>
  )
}

OrderedListComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

function UnorderedListComponent(props) {
  return (
    <ol className={classNames(props.classes.ul, props.fixed ? props.classes.fixed : {})}>
      {props.children}
    </ol>
  )
}

UnorderedListComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};



function ListItemComponent(props) {
  return (
    <li className={classNames(props.classes.li, props.bordered ? props.classes.borderedLi : {})}>
      <Typography variant='body2'>
        {props.text}
      </Typography>
    </li>
  )
}

ListItemComponent.propTypes = {
  text: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

const styles = theme => ({
  ol: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 'lighter',
    borderLeft: '#eee solid 4px',
    marginLeft: theme.spacing.unit * 3,
  },
  li: {
    padding: theme.spacing.unit,
  },
  borderedLi: {
    borderLeft: '#eee solid 4px',
  },
  fixed: {
    position: 'fixed',
    marginTop: 150,
    marginLeft: 24,
  },
  ul: {
    fontFamily: theme.typography.fontFamily,
    listStyle: 'none',
  },
});



export const ListItem = withStyles(styles, { withTheme: true })(ListItemComponent);
export const OrderedList = withStyles(styles, { withTheme: true })(OrderedListComponent);
export const UnorderedList = withStyles(styles, { withTheme: true })(UnorderedListComponent);