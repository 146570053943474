import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withCookies } from 'react-cookie';

import { withStyles } from '@material-ui/core/styles';
import { CssBaseline, AppBar, Drawer, Toolbar, IconButton, Typography, Divider } from '@material-ui/core';
import { ChevronRight, ChevronLeft } from '@material-ui/icons';

import NavMenuDrawer from './NavMenuDrawer';
import NavMenuToolbar from './NavMenuToolbar';

class NavMenu extends Component {
  displayName = NavMenu.name
  constructor(props){
    super(props);
    this.state = {
      open: props.cookies.get('drawerState') === 'true' || true,
    }
  }

  handleDrawerOpen = () => this.setDrawerState(true);
  handleDrawerClose = () => this.setDrawerState(false);
  setDrawerState = (value) => {
    this.props.cookies.set('drawerState', value, { path: '/' });
    this.setState({ open: value });
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <CssBaseline />
        <AppBar
          color='primary'
          position='fixed'
          className={classNames(classes.appBar, {
            [classes.appBarShift]: this.state.open,
          })}
        >
          <Toolbar disableGutters={!this.state.open}>
            <IconButton
              color='inherit'
              onClick={this.handleDrawerOpen}
              className={classNames(classes.menuButton, {
                [classes.hide]: this.state.open,
              })}
            >
              <ChevronRight />
            </IconButton>
            <NavMenuToolbar />
          </Toolbar>
        </AppBar>
        <Drawer
          variant='permanent'
          className={classNames(classes.drawer, {
            [classes.drawerOpen]: this.state.open,
            [classes.drawerClose]: !this.state.open,
          })}
          classes={{
            paper: classNames({
              [classes.drawerOpen]: this.state.open,
              [classes.drawerClose]: !this.state.open,
            }),
          }}
          open={this.state.open}
        >
          <div className={classes.toolbar}>
            <Typography variant='h6' color='inherit' className={classNames(classes.grow, classes.centerText)} noWrap>
              Navigation
            </Typography>
            <IconButton onClick={this.handleDrawerClose}>
              <ChevronLeft />
            </IconButton>
          </div>
          <Divider />
          <NavMenuDrawer />
        </Drawer>
        <main className={classes.content}>
          <div className={classes.toolbar} />
          {this.props.children}
        </main>
      </div>
    );
  }
}


NavMenu.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

const drawerWidth = 240;

const styles = theme => ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 36,
  },
  grow: {
    flexGrow: 1,
  },
  centerText: {
    textAlign: 'center',
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing.unit * 7 + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing.unit * 9 + 1,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing.unit * 3,
  },
});

export default
  withStyles(styles, { withTheme: true })(
  withCookies(NavMenu));
