import { getToken } from '../../utils/AzureADB2C';

export const PostCreateNewRecordWithFIFA = id => {
  const req = {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + getToken()
    },
    body: id,
  };

  let url = [process.env.REACT_APP_PORTAL_URL, 'duplicate', 'CreateNewRecordWithFIFA'].join('/');
  return fetch(url, req);
}

export const PostAssignFIFAIdToRecord = data => {
  const req = {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + getToken()
    },
    body: JSON.stringify(data),
  };

  let url = [process.env.REACT_APP_PORTAL_URL, 'duplicate', 'ResolveDuplicate'].join('/');
  return fetch(url, req);
}