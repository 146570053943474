import React, { Component } from 'react';
import { Route } from 'react-router';
import { withCookies } from 'react-cookie';

import Home from './pages/Home';
import LoginPage from './pages/login/LoginPage';
import ContactPage from './pages/ContactPage'
import PortalApp from './PortalApp';
import NavMenu from './components/nav/NavMenu';
import { withOrganizationTypes } from './components/contexts/OrganizationTypesContext';
import RegistrationCheck from './pages/registration/RegistrationCheck';
import RegistrationForm from './pages/registration/RegistrationForm';

class App extends Component {
  componentDidMount() {
    this.props.getOrganizationTypes();
  }

  render() {
    return (
      <NavMenu>
        <Route exact path='/' component={Home} />
        <Route path='/login' component={LoginPage} />
        <Route exact path='/register' component={RegistrationForm} />
        <Route exact path='/register/check' component={RegistrationCheck} />
        <Route path='/contact' component={ContactPage} />

        <Route path='/portal' component={PortalApp} />
      </NavMenu>
    )
  }
}

export default
  withCookies(
    withOrganizationTypes(App));

