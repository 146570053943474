import { getToken } from '../../utils/AzureADB2C';

export const GetAllRegistrationRequestsFromPortal = () => {
  const req = {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + getToken()
    }
  };

  let url = [process.env.REACT_APP_PORTAL_URL, 'request', 'GetRegistrationRequests'].join('/');
  return fetch(url, req);
}

export const GetAllRequestsFromPortal = () => {
  const req = {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + getToken()
    }
  };

  let url = [process.env.REACT_APP_PORTAL_URL, 'request', 'GetRequests'].join('/');
  return fetch(url, req);
}

export const GetRequestFromPortal = (id) => {
  const req = {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + getToken()
    }
  };

  let url = [process.env.REACT_APP_PORTAL_URL, 'request', 'GetRequest', id].join('/');
  return fetch(url, req);
}


export const GetNotificationSubscriberList = () => {
  const req = {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + getToken()
    }
  };

  let url = [process.env.REACT_APP_PORTAL_URL, 'request', 'GetNotificationSubscriberList'].join('/');
  return fetch(url, req);
}

