import { getToken } from '../../utils/AzureADB2C';

export function PostRequestApprovalToPortal(requestId, organizationId, orgUpdateReq) {
  let body = {
    requestId: requestId,
    organizationId: organizationId,
    orgUpdateReq: orgUpdateReq
  }

  const req = {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + getToken()
    },
    body: JSON.stringify(body)
  }

  let url = [process.env.REACT_APP_PORTAL_URL, 'request', 'approverequest'].join('/');
  return fetch(url, req);

}

export function PostRequestDenialToPortal(requestId) {
  let body = {
    requestId: requestId,
  }

  const req = {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + getToken()
    },
    body: JSON.stringify(body)
  }

  let url = [process.env.REACT_APP_PORTAL_URL, 'request', 'rejectrequest'].join('/');
  return fetch(url, req);
}

export const ToggleAdminNotificationSubscription = email => {
  let body = {
    subscriberEmail: email,
  }

  const req = {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + getToken()
    },
    body: JSON.stringify(body)
  }

  let url = [process.env.REACT_APP_PORTAL_URL, 'request', 'ToggleAdminNotificationSubscription'].join('/');
  return fetch(url, req);
}