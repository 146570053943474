export function PostRegistrationToPortal(registrationJson){
  const req = {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(registrationJson)
  }

  let url = [process.env.REACT_APP_PORTAL_URL, 'registration', 'register'].join('/');
  return fetch(url, req);

}
