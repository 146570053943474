import { getToken } from '../../utils/AzureADB2C';

export const GetAllOrganizationsFromPortal = () => {
  const req = {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
  };

  let url = [process.env.REACT_APP_PORTAL_URL, 'organization', 'getall'].join('/');
  return fetch(url, req);
}

export const GetAllOrganizationTypesFromPortal = () => {
  const req = {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
  };

  let url = [process.env.REACT_APP_PORTAL_URL, 'organization', 'getalltypes'].join('/');
  return fetch(url, req);
}



export const GetAllOrganizationsAsAdminFromPortal = (filter, sorting, pageSize, pageNumber) => {

  const data = {
    filter: filter,
    sorting: sorting,
  }

  const req = {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + getToken()
    },
    body: JSON.stringify(data)
  };

  console.log(`filter`, filter)
  console.log('sort', sorting);
  const queryParam = `?pageSize=${pageSize || ""}&pageNumber=${pageNumber || ""}`

  let url = [process.env.REACT_APP_PORTAL_URL, 'organization', 'getallinfo'].join('/') + queryParam;
  return fetch(url, req);
}

export const GetMemberOrganizationsAsPTSOFromPortal = (id, filter, sorting, pageSize, pageNumber) => {
    const data = {
        filter: filter,
        sorting: sorting,
        id: id,
    }

    const req = {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getToken()
        },
        body: JSON.stringify(data)
    };

    console.log(`filter`, filter)
    console.log('sort', sorting);
    const queryParam = `?pageSize=${pageSize || ""}&pageNumber=${pageNumber || ""}`

    let url = [process.env.REACT_APP_PORTAL_URL, 'organization', 'getmemberorganizations'].join('/') + queryParam;
    return fetch(url, req);
}

export const GetOrganizationsAsVendorFromPortal = (id) => {
    const data = {
        id: id
    }

    const req = {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getToken()
        },
        body: JSON.stringify(data)
    };

    let url = [process.env.REACT_APP_PORTAL_URL, 'organization', 'getorganizationsasvendor'].join('/');
    return fetch(url, req);
}

export const GetOrganizationAsAdminFromPortal = (id) => {
  const req = {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + getToken()
    },
  };

  let url = [process.env.REACT_APP_PORTAL_URL, 'organization', 'getorganization', id].join('/');
  return fetch(url, req);
}

export const GetPendingOrganizationAsAdminFromPortal = (id, filter, sorting, pageSize, pageNumber) => {
    const req = {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getToken()
        },
    };

    let url = [process.env.REACT_APP_PORTAL_URL, 'organization', 'getpendingorgdata', id].join('/');
    return fetch(url, req);
}

export const GetMyOrganizationsFromPortal = () => {
  const req = {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + getToken()
    },
  };

  let url = [process.env.REACT_APP_PORTAL_URL, 'account', 'myorganizations'].join('/');
  return fetch(url, req);
}

export const GetVendorsWithAccessTo = (orgId) => {
  const req = {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + getToken()
    },
  };

  let url = [process.env.REACT_APP_PORTAL_URL, 'organization', 'GetVendorsWithAccessTo', orgId].join('/');
  return fetch(url, req);
}

export const GetOrganizationApiKeyFromPortal = (orgId) => {
  const req = {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + getToken()
    },
  };

  let url = [process.env.REACT_APP_PORTAL_URL, 'organization', 'GetApiKey', orgId].join('/');
  return fetch(url, req);
}

export const GetAccessibleApiKeysFromPortal = (orgId) => {
  const req = {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + getToken()
    },
  };

  let url = [process.env.REACT_APP_PORTAL_URL, 'organization', 'GetAccessibleApiKeys', orgId].join('/');
  return fetch(url, req);
}

export const GetParentOrganizationFromPortal = (orgId) => {
  const req = {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + getToken()
    },
  };

  let url = [process.env.REACT_APP_PORTAL_URL, 'organization', 'GetParentOrganization', orgId].join('/');
  return fetch(url, req);
}
