import React from 'react';
import { Grid, Typography, withStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

function Home({ classes }) {
  const { t } = useTranslation();

  return (
    <Grid container spacing={24}>
      <Grid item xs={12} className={classes.center}>
        <img alt='logo' src='CanadaSoccerLogo_150x150.png' />
      </Grid>
      <Grid item xs={12}>
        <Typography variant='h3' className={classes.center}>{t('home.title')}</Typography>
      </Grid>
      <div style={{ height: 150 }} />
      <Grid item xs={12}>
        <Typography variant='h6'>{t('home.header')}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant='body1'>{t('home.intro')}</Typography>
      </Grid>
      {
        process.env.REACT_APP_ENV_NAME !== 'Production' &&
        <div className={classes.version}>{`V1 - ${process.env.REACT_APP_ENV_NAME}`}</div>
      }
    </Grid>
  );
}

const styles = theme => ({
  center: {
    textAlign: 'center',
  },
  version: {
    fontFamily: 'Roboto, sans-serif',
    position: 'absolute',
    bottom: 25,
    right: 25,
    opacity: '0.3',
  },
});

export default
  withStyles(styles, { withTheme: true })(Home);
