
const tenant = process.env.REACT_APP_TENANT;
const clientId = process.env.REACT_APP_APP_CLIENT_ID;
const signInPolicy = process.env.REACT_APP_SIGN_IN_POLICY;
const redirectUri = `${process.env.REACT_APP_PORTAL_URL}${process.env.REACT_APP_REDIRECT_PATH}`;
const postLogoutRedirectUri = process.env.REACT_APP_PORTAL_URL;
const suffix = process.env.REACT_APP_APP_ID_URI_SUFFIX;
const appIdUri = `https://${tenant}.onmicrosoft.com/${suffix}`
const scopes = [
  `${appIdUri}/user_impersonation`,
  `${appIdUri}/read`
]

export const signInUrl = `https://${tenant}.b2clogin.com/${tenant}.onmicrosoft.com/oauth2/v2.0/authorize?p=${signInPolicy}&client_id=${clientId}&nonce=defaultNonce&redirect_uri=${redirectUri}&scope=openid ${scopes.join(' ')}&response_type=id_token token&prompt=login`;
export const signOutUrl = `https://${tenant}.b2clogin.com/${tenant}.onmicrosoft.com/${signInPolicy}/oauth2/v2.0/logout?post_logout_redirect_uri=${postLogoutRedirectUri}`;

const ACCESS_TOKEN = "access_token";
const EXPIRES = "expires_in";
const EXPIRATION_TIME = "expiration_time";

export const clearToken = () => {
  console.debug("clearing session storage");
  sessionStorage.clear()
}

export const getToken = () => {
  console.debug("accessing token");
  let hashArgs = window.location.hash.substr(1).split('&');

  if (hashArgs.length === 4) {
    console.debug("hash detected, parsing arguments");
    for (let i = 0; i < hashArgs.length; i++) {
      let args = hashArgs[i].split('=');
      if (args.length > 1) {
        let k = args[0];
        let v = args[1];
        sessionStorage.setItem(k, v);
        if (k === EXPIRES) {
          let expirationDate = (Date.now() / 1000 | 0) + parseInt(v);
          sessionStorage.setItem(EXPIRATION_TIME, expirationDate);
        }
      }
    }
    removeHash();
  }

  let token = sessionStorage.getItem(ACCESS_TOKEN);

  let expirationDate = sessionStorage.getItem(EXPIRATION_TIME);
  if (expirationDate > (Date.now() / 1000 | 0)) {
    console.debug("token not expired, returning token");
    return token;
  }

  console.debug("token expired, checking if in portal section");
  if (window.location.href.indexOf("portal") > 0) {
    console.debug("in portal section, redirecting to sign in url");
    window.location = signInUrl;
  }
  else {
    console.debug("not in portal section, no need for token");
  }
}

function removeHash() {
  var history = window.history;
  var scrollV, scrollH, loc = window.location;
  if ("pushState" in history)
    history.pushState("", document.title, loc.pathname + loc.search);
  else {
    // Prevent scrolling by storing the page's current scroll offset
    scrollV = document.body.scrollTop;
    scrollH = document.body.scrollLeft;

    loc.hash = "";

    // Restore the scroll offset, should be flicker free
    document.body.scrollTop = scrollV;
    document.body.scrollLeft = scrollH;
  }
}


