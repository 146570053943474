import React, { useState } from 'react';
import { Grid, Paper, withStyles, Typography, Button } from '@material-ui/core';
import styled from 'styled-components';
import Select from 'react-select';
import { Phone, Email } from '@material-ui/icons';
import { PostRequestApprovalToPortal, PostRequestDenialToPortal } from '../../../../../api/registration/PostRequestActionToPortal';

const RequestContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
`
const RequestControl = styled.div`
  margin-bottom: 4rem;
`
const ControlButtons = styled.div`
  margin-top: 2rem;
  display: flex;
  justify-content: space-between;
`


const RequestCard = styled.div`
  padding: 1rem;
  background-color: #fff;
  box-shadow: 0 1px 3px rgba(0,0,0,0.02), 0 1px 2px rgba(0,0,0,0.14);
  border-radius: 4px;
  transition: all 0.4s ease-out;
  :hover {
    box-shadow: 0 3px 6px rgba(0,0,0,0.06), 0 3px 6px rgba(0,0,0,0.13);
  }
`
const RequestPerson = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`

const Photo = styled.div`
  width: 150px;
  height: 150px;
  background-color: #ccc;
  margin: 1rem;
`

const PersonDetails = styled.div`
  margin: 1rem;
`
const IconAndText = styled.div`
  display: flex;
  align-items: center;
  margin-top: 0.2rem;
`



function ViewRegistrationRequest({ request, organizations, classes, history, enqueueSnackbar }) {
  const { requestId, createdDateTime,
    requestRegistration: {
      firstName, lastName, emailAddress, phoneNumber, organizationRoleText, organizationId, placeholderOrganizationName,
    } } = request;

  const [selectedOrgId, setSelectedOrgId] = useState(organizationId);
  let selectedOrgDetails = organizations.find(kvp => kvp.key === selectedOrgId);

  let createDateString = new Date(createdDateTime).toDateString();
  let fullName = `${firstName} ${lastName}`;
  let organizationName = (selectedOrgDetails || {}).value;
    let organizationDetails = `${organizationName || placeholderOrganizationName} (${organizationRoleText})`;
  let formattedPhoneNumber = formatPhoneNumber(phoneNumber);


  function PostRequestAnswer(requestId, organizationId, approvedBool) {
    const savingData = enqueueSnackbar(`Processing request...`, { variant: 'info', persist: true});
    if (Number.isInteger(requestId) && Number.isInteger(organizationId)) {
      let postRequest = approvedBool
        ? PostRequestApprovalToPortal(requestId, organizationId)
        : PostRequestDenialToPortal(requestId);

      postRequest
        .then(res => {
          if (res.ok) {
            enqueueSnackbar(`Successfully ${approvedBool ? 'approved' : 'denied'} the request`, { variant: 'success', autoHideDuration: 5500 });
            history.goBack();
          }
          else {
            throw res.text();
          }
        })
        .catch(e => {
          console.error(e);
          enqueueSnackbar(`Error ${approvedBool ? 'approving' : 'denying'} the request`, { variant: 'error', autoHideDuration: 5500 });
        })
    }
  }




  return (
    <React.Fragment>
      <Button variant='contained' color='default' onClick={() => history.goBack()}>Back</Button>
          <Grid container alignItems="center"
              justify="center" direction="column" spacing={24}>
        <Grid item xs={12} >
          <Typography variant='h5'>Registration Request #{requestId}</Typography>
          <Typography variant='overline' gutterBottom>Created on {createDateString}</Typography>
        </Grid>
        <Grid xs={12} md={6} item>
        <RequestCard>
          <RequestPerson>
            <PersonDetails>
              <Field label='Full Name' text={fullName}/>
              <Field label='Organization (Role)' text={organizationDetails}/>
              <Field label='Email Address' text={emailAddress}/>
              <Field label='Phone Number' text={formattedPhoneNumber}/>
            </PersonDetails>
          </RequestPerson>
        </RequestCard>
        </Grid>
        <Grid item  direction="row" container>
          <Grid xs={12} style={{ textAlign: 'center'}} item><Typography style={{ paddingTop: '0.5rem' }} variant='body2'>Select Organization</Typography></Grid>
          <Grid item xs={4}></Grid>
          <Grid xs={4} item><Select defaultValue={selectedOrgDetails} options={organizations} onChange={newOrgSelect => setSelectedOrgId(organizations.find(kvp => kvp.value === newOrgSelect.value).key)} /></Grid>
          <Grid xs={4}></Grid>
          <Grid item xs={12}>
          <ControlButtons>
            <Grid container alignItems="center" justify="center" direction="row">
            <Grid xs={5}></Grid>
            <Grid item xs={1}><Button variant='contained' color='primary' onClick={() => PostRequestAnswer(requestId, selectedOrgId, true)}>Approve</Button></Grid>
            <Grid item xs={1}><Button variant='contained' color='secondary' onClick={() => PostRequestAnswer(requestId, selectedOrgId, false)}>Deny</Button></Grid>
            <Grid xs={5}></Grid>
            </Grid>
          </ControlButtons>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  )
}
const styles = theme => ({
    detailsIcon: {
        marginRight: '1rem',
        color: theme.palette.secondary.light,
    },
    paper: {
        padding: theme.spacing.unit * 4,
    },
    center: {
        textAlign: 'center',
    },
    container: {
        display: 'flex',
        paddingLeft: theme.spacing.unit,
    },
    label: {
        width: 150,
        paddingRight: 5,
        paddingBottom: 5,
        paddingLeft: 20,
        textAlign: 'left',
        color: 'grey',
        fontWeight: 400

    },
    parentText: {
        fontSize: 16,
    }
});

const Field = withStyles(styles, { withTheme: true })(({ classes, label, text }) => {
    return (
        <div className={classes.container}>
            <Typography variant='body1' className={classes.label}>{label}</Typography>
            <Typography variant='body1' className={classes.text}>{text}</Typography>
        </div>
    )
});

export default withStyles(styles, { withTheme: true })(ViewRegistrationRequest)


function formatPhoneNumber(phoneNumberString) {
  var cleaned = ('' + phoneNumberString).replace(/\D/g, '')
  var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)
  if (match) {
    var intlCode = (match[1] ? '+1 ' : '')
    return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('')
  }
  return null
}
