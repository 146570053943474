import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';
import { withLocalization } from '../../../../components/contexts/LanguageContext';
import { BodyParagraph, CodeSnippet, MarkdownParagraph, Section } from '../../../../components/text/Paragraphs';
import { SectionTitle } from '../../../../components/text/Titles';

function APIFormat(props) {
  const { strings } = props;
  return (
    <Section>
      <SectionTitle text={strings.title} />
      <MarkdownParagraph text={strings.description} />
      <CodeSnippet text={strings.code} />
      <div style={{ textAlign: 'center', margin: 16, }}>
        <BodyParagraph text={strings.exampleIntro} />
      </div>
    </Section>
  )
}

APIFormat.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

const strings = {
  en: {
    title: 'API Format',
    description: "The API is a RESTful JSON API based on the [JSON API Specification](https://jsonapi.org/), which covers both data format and API behaviour and the API is supported by numerous open source libraries in many languages. While it is not required to use one of these libraries to consume the API we recommend exploring if any libraries may be of use before starting a custom integration.",
    exampleIntro: "Example of a JSON API compliant object",
    code: `{
  "data": {
    "type": "player-registration",
    "id": "1", 
    "attributes": {
      // ... this player-registration's attributes
    },
    "relationships": {
      // ... this player-registration's relationships
    }
  }
}`,
  },
  fr: {
    title: 'API Format',
    description: "The API is a RESTful JSON API based on the [JSON API Specification](https://jsonapi.org/), which covers both data format and API behaviour and the API is supported by numerous open source libraries in many languages. While it is not required to use one of these libraries to consume the API we recommend exploring if any libraries may be of use before starting a custom integration.",
    exampleIntro: "Example of a JSON API compliant object",
    code: `{
  "data": {
    "type": "player-registration",
    "id": "1",
    "attributes": {
      // ... this player-registration's attributes
    },
    "relationships": {
      // ... this player-registration's relationships
    }
  }
}`,
  },
}

const styles = theme => ({

});

export default
  withStyles(styles, { withTheme: true })(
    withLocalization(strings)(APIFormat));

