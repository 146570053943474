import { Grid } from '@material-ui/core';
import React from 'react';
import LoginForm from './LoginForm';


function LoginPage(props) {
  return (
    <Grid container justify='center' spacing={24}>
      <LoginForm />
    </Grid>
  )
}


export default LoginPage;