import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Section, BodyParagraph } from '../../../../components/text/Paragraphs';
import { SectionTitle } from '../../../../components/text/Titles';
import { withLocalization } from '../../../../components/contexts/LanguageContext';

function APIOverview(props) {
  const { strings } = props;
  return (
    <Section>
      <SectionTitle text={strings.title} />
      
          <BodyParagraph text={strings.description} />
          <BodyParagraph text={strings.description2} />
          {/* 
      <div style={{ textAlign: 'center', margin: 16, }}>
        <iframe title="video" width="560" height="315" src={strings.videoUrl} frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
        <BodyParagraph text={strings.videoDescription} />
      </div>
          */} 
    </Section>
  )
}

APIOverview.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

const strings = {
  en: {
    title: 'API Overview',
    description: `
        The NSR API is intended to capture registrations for all soccer players playing in Canada. The API has been 
        created as a method of integration with registration software systems. Registration software systems are operated by Vendors
        who provide online registration services to Soccer Organizations currently. The mechanism for capturing registration data is 
        to request that registration software systems, which currently capture registration information on behalf of Soccer Organizations, 
        send the data via an API call when the player registers.`,
    description2: `
        The API currently only has a single endpoint -- the endpoint to submit registration data.`,
    videoDescription: 'Example video of how to complete the steps.',
    videoUrl: 'https://www.youtube.com/embed/t5n07Ybz7yI',
  },
  fr: {
    title: "Vue d’ensemble de l’API",
    description: `
      Les systèmes logiciels d’enregistrement sont gérés par les fournisseurs qui
      soumettre les inscriptions au nom des organisations de football. Les appels
      sont destinés à être au service API lorsque des personnes s’inscrivent auprès 
      du logiciel.`,
    description2: `
      The API currently only has a single endpoint -- the endpoint to submit registration data.`,
    videoDescription: "Exemple de vidéo sur les étapes à suivre.",
    videoUrl: 'https://www.youtube.com/embed/t5n07Ybz7yI',
  }
}

const styles = theme => ({

});

export default
  withStyles(styles, { withTheme: true })(
    withLocalization(strings)(APIOverview));

