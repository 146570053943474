import { getToken } from '../../utils/AzureADB2C';

export function PostUserToPortal(userJson) {
  const req = {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + getToken()
    },
    body: JSON.stringify(userJson)
  }

  let url = [process.env.REACT_APP_PORTAL_URL, 'user', 'updateuser'].join('/');
  return fetch(url, req);
}