import { getToken } from '../../utils/AzureADB2C';

export const GetPlayerHistoryFromPortal = (id) => {
  const req = {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + getToken()
    },
  };

  let url = [process.env.REACT_APP_PORTAL_URL, 'report', 'getplayerhistory', id].join('/');
  return fetch(url, req);
}

export const SearchForPlayers = (orgId, firstName, lastName, playerId) => {

  const json = {};
  if (orgId) {
    json.organizationId = orgId;
  }
  if (firstName && firstName.length > 0) {
    json.firstName = firstName;
  }
  if (lastName && lastName.length > 0) {
    json.lastName = lastName;
  }
  if (playerId) {
    json.playerId = playerId;
  }

  const req = {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + getToken()
    },
    body: JSON.stringify(json)
  };

  let url = [process.env.REACT_APP_PORTAL_URL, 'report', 'searchplayers'].join('/');
  return fetch(url, req);
}

export const GetRegistrationReportFromPortal = (orgId, query) => {
  query = query || {};
  const body = {
    organizationId: orgId,
    firstName: query.firstName || false,
    lastName: query.lastName || false,
    age: query.age || false,
    gender: query.gender || false,
    registrationNature: query.registrationNature || false,
    registrationLevel: query.registrationLevel || false,
    registrationFromDate: query.registrationFromDate || new Date().toLocaleDateString(),
    registrationToDate: query.registrationToDate || new Date().toLocaleDateString(),
  };

  const req = {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + getToken()
    },
    body: JSON.stringify(body)
  };

  let url = [process.env.REACT_APP_PORTAL_URL, 'report', 'GetRegistrationReport'].join('/');
  return fetch(url, req);
}