import React, { Component } from 'react';
import { Route } from 'react-router';

import QuickStartPage from './documentation/QuickStartPage';
import SwaggerPage from './documentation/SwaggerPage';
import { FAQPage } from './documentation/FAQPage';
import SupportPage from './documentation/SupportPage';

export class DocumentationRouter extends Component {
    render() {
        let { match } = this.props;
        return (
            <React.Fragment>
                <Route path={`${match.path}/quickstart`} component={QuickStartPage} />
                <Route path={`${match.path}/swagger`} component={SwaggerPage} />
                <Route path={`${match.path}/support`} component={SupportPage} />
                <Route path={`${match.path}/faq`} component={FAQPage} />
            </React.Fragment>
        )
    }
}
