import React, { Component } from 'react';
import { Route } from 'react-router';
import { withUserContext } from './components/contexts/UserContext';
import LoadingSpinner from './components/LoadingSpinner';
import { AdminRouter } from './pages/authenticated/AdminRouter';
import { DocumentationRouter } from './pages/authenticated/DocumentationRouter';
import NewsPage from './pages/authenticated/NewsPage';
import { OrganizationRouter } from './pages/authenticated/OrganizationRouter';
import OrganizationsRouter from './pages/authenticated/OrganizationsRouter';
import ProfilePage from './pages/authenticated/ProfilePage';
import LoginRedirectPage from './pages/authenticated/LoginRedirectPage';
import ReportsRouter from './pages/authenticated/ReportsRouter';
import ManualRegistrationPage from './pages/authenticated/ManualRegistrationPage';


class PortalApp extends Component {
  constructor(props) {
    super(props);
    this.state = {

    }
  }

  componentDidMount() {
    const { user, getUserData } = this.props;
    if (!user.isAuthenticated) {
      getUserData();
    }
  }


  render() {
    let { match, user } = this.props;

    if (user.isAuthenticated === undefined) {
      return <LoadingSpinner message='Logging in' />
    }

    return (
      <React.Fragment>
        <Route path={`${match.path}/login`} component={LoginRedirectPage} />
        <Route path={`${match.path}/profile`} component={ProfilePage} />
        <Route path={`${match.path}/manualregistrations`} component={ManualRegistrationPage} />
        <Route path={`${match.path}/organization`} component={OrganizationRouter} />
        <Route path={`${match.path}/organizations`} component={OrganizationsRouter} />
        <Route path={`${match.path}/reports`} component={ReportsRouter} />
        <Route path={`${match.path}/news`} component={NewsPage} />
        <Route path={`${match.path}/documentation`} component={DocumentationRouter} />
        <Route path={`${match.path}/admin`} component={AdminRouter} />
      </React.Fragment>
    )
  }
}

export default withUserContext(PortalApp);