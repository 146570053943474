import React from 'react';
import { Route, withRouter } from 'react-router';
import ReportsLanding from './reports/ReportsLanding';
import PlayerHistory from './reports/PlayerHistory';



function ReportsRouter(props) {
  const { match } = props;

  return (
    <React.Fragment>
      <Route path={`${match.path}`} exact component={ReportsLanding} />
      <Route path={`${match.path}/history/:id`} render={(props) => <PlayerHistory {...props} />} />
    </React.Fragment>
  )
}

export default withRouter(ReportsRouter);