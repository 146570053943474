import React from 'react';
import { Grid, withStyles } from '@material-ui/core';
import NewsPreview from './NewsPreview';

function NewsFeed(props) {
  const { newsItems = [], noAdmin, classes } = props;
  return (
    <div className={classes.feed}>
      {newsItems.length > 0 &&
        newsItems.map((item, i) => {
          return (
            <Grid key={i} item xs={12}>
              <NewsPreview data={item} noAdmin={noAdmin} />
            </Grid>
          )
        })
      }
    </div>
  )
}

const styles = theme => ({
  feed: {
    fontFamily: theme.typography.fontFamily,
  }
});

export default withStyles(styles, { withTheme: true })(NewsFeed)