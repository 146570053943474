import React, { Component } from 'react';
import { Grid } from '@material-ui/core';
import NewsFeed from '../../components/news/NewsFeed';
import { withSnackbar } from 'notistack';
import { GetAllActiveNewsItemsFromPortal } from '../../api/news/GetNewsFromPortal';
import { PageTitle } from '../../components/text/Titles';
import { withTranslation } from 'react-i18next';

class NewsPage extends Component {
  displayName = NewsPage.name;
  state = {
    newsItems: [],
  }

  componentDidMount() {
    GetAllActiveNewsItemsFromPortal()
      .then(res => res.json())
      .then(json => {
        this.setState({ newsItems: json });
      })
      .catch(e => {
        console.log(e);
        this.props.enqueueSnackbar('Error fetching news items', { variant: 'error', autoHideDuration: 5500 });
      })
  }

  render() {
    const { newsItems } = this.state;
    const { t } = this.props;
    return (
      <Grid container spacing={24}>
        <Grid item xs={12}>
          <PageTitle text={t('news.Latest News')} />
        </Grid>
        <Grid item xs={12}>
            <NewsFeed newsItems={newsItems} noAdmin />
        </Grid>
      </Grid>
    )
  }
}

export default
  withSnackbar(
    withTranslation()(NewsPage));