import React from 'react';
import { Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { withCookies } from 'react-cookie';


function LocalizationButton({ cookies }) {

  const { i18n } = useTranslation();

  let selectedLanguage = cookies.get('selectedLanguage') || 'en';
  let newLanguage = selectedLanguage === 'en' ? 'fr' : 'en';
  if (i18n.language !== selectedLanguage) {
    i18n.changeLanguage(selectedLanguage);
  }

  function toggleChange() {
    cookies.set('selectedLanguage', newLanguage, { path: '/' });
    i18n.changeLanguage(newLanguage);
  }


  return (
    <Button color='inherit' onClick={toggleChange} >{newLanguage.toUpperCase()}</Button>
  )
}

export default withCookies(LocalizationButton);