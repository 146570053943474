import React from 'react';
import { Route, withRouter } from 'react-router';

import NewsEditor from './news/NewsEditor';
import AllAdminNews from './news/AllAdminNews';

function NewsRouter(props) {
  const { match } = props;

  return (
    <React.Fragment>
      <Route path={`${match.path}`} exact component={AllAdminNews}/>
      <Route path={`${match.path}/add`} render={(props) => <NewsEditor {...props} redirectRoute={match.path} />} />
      <Route path={`${match.path}/edit/:id`} render={(props) => <NewsEditor {...props} redirectRoute={match.path} />} />
    </React.Fragment>
  )
}

export default withRouter(NewsRouter);