import { Grid, Typography, IconButton } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { GetAllRequestsFromPortal } from '../../../../api/registration/GetRequestsFromPortal';
import ReactTable from 'react-table';
import classNames from 'classnames';
import LoadingSpinner from '../../../../components/LoadingSpinner';
import { withSnackbar } from 'notistack';
import { RequestTypes, RequestStatusTypes } from '../../../../utils/IndexDataConstants';
import { withTranslation } from 'react-i18next';
import { PageTitle } from '../../../../components/text/Titles';

import {
  ValueToColor,
  DefaultRequestFilterMethod,
  getRegistrationRequestEmail,
  getOrganizationRequestEmail,
  getDuplicateRequestEmail,
  getRegistrationRequestPersonName,
  getOrganizationRequestPersonName,
  getDuplicateRequestPersonName,
  getRequestDateTimeAsString,
  StatusFilterMethod,
  StatusFilterComponent,
  RequestTypeFilterMethod,
  RequestTypeFilterComponent,
  getOrganizationDataUpdateOrgName
} from './RequestFilterUtils';
import { Link } from 'react-router-dom';

class RequestTable extends Component {
  displayName = RequestTable.name;
  constructor(props) {
    super(props);
    this.state = {
      requests: [],
    }
  }

  componentDidMount() {
    this.initializeData();

  }

  initializeData = () => {
    this.fetchRequests()
      .then((results) => {
        this.setState({
          requests: results,
        })
      });
  }

  fetchRequests = () => {
    return new Promise((resolve, reject) => {
      GetAllRequestsFromPortal()
        .then(res => {
          if (res.ok) {
            return res.json();
          }
          throw res.text();
        })
        .then(json => resolve(json))
        .catch(e => {
          this.props.enqueueSnackbar(`Error fetching requests`, { variant: 'error', autoHideDuration: 5500 });
          reject(e);
        })
    })
  }

  getColumns = () => {
    const { classes, location: { pathname } } = this.props;
    return [
      {
        Header: 'Type',
        id: "requestType",
        filterMethod: RequestTypeFilterMethod,
        Filter: RequestTypeFilterComponent,
        Cell: cellInfo => {
          return RequestTypes[cellInfo.original.requestTypeCode]
        }
      },
      {
        Header: 'Name',
        id: "name",
        Cell: cellInfo => {
          let req = cellInfo.original;
          switch (req.requestTypeCode) {
            case 1:
              return getRegistrationRequestPersonName(req.requestRegistration);
            case 2:
              return getOrganizationRequestPersonName(req.requestUserOrganization);
            case 3:
              return getDuplicateRequestPersonName(req.requestDuplicateResolution);
            case 4:
              return getOrganizationDataUpdateOrgName(req.requestOrganizationDataUpdate);
            default:
              return `<not found>`;
          }
        }
      },
      {
        Header: 'Email',
        id: 'email',
        Cell: cellInfo => {
          let req = cellInfo.original;
          switch (req.requestTypeCode) {
            case 1:
              return getRegistrationRequestEmail(req.requestRegistration);
            case 2:
              return getOrganizationRequestEmail(req.requestUserOrganization);
            case 3:
              return getDuplicateRequestEmail(req.requestDuplicateResolution);
            case 4:
              return getRegistrationRequestEmail(req.requestOrganizationDataUpdate);
            default:
              return `<not found>`;
          }
        }
      },
      {
        Header: 'Created Date',
        id: 'createDate',
        Cell: cellInfo => {
          return getRequestDateTimeAsString(cellInfo.original);
        }
      },
      {
        Header: 'Status',
        id: 'status',
        filterMethod: StatusFilterMethod,
        Filter: StatusFilterComponent,
        Cell: cellInfo => {
          let req = cellInfo.original;
          let status = RequestStatusTypes[req.requestStatusTypeCode];
          let color = ValueToColor(status);

          return (
            <div className={classes.statusContainer}>
              <div className={classes.status}>
                <span style={{ color: color, transition: 'all .3s ease', paddingRight: 5 }}>&#x25cf;</span>
                {status}
              </div>
              {
                status.toLowerCase() === 'pending' &&
                <Link to={`${pathname}/${req.requestId}`}>
                  <IconButton className={classes.icon}>
                    <EditIcon fontSize='small' />
                  </IconButton>
                </Link>
              }
            </div>
          )
        },
      }
    ]
  }


  render() {
    const { requests } = this.state;
    const { classes, t } = this.props;

    if (requests.length === 0) {
      return <LoadingSpinner message='Fetching Requests' />
    }

    let columns = this.getColumns();

    return (
      <Grid container spacing={24}>
        <Grid item xs={12}>
          <PageTitle text={t('request.requestAdministration')} />
          <ReactTable
            data={requests}
            filterable
            defaultFilterMethod={DefaultRequestFilterMethod}
            defaultFiltered={[{ id: "status", value: "1"}]}
            columns={columns}
            className={classNames('-striped -highlight', classes.table)}
          />
        </Grid>
      </Grid>
    )
  }
}


RequestTable.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

const styles = theme => ({
  table: {
    fontFamily: theme.typography.fontFamily,
  },
  title: {
    marginBottom: theme.spacing.unit * 2,
  },
  statusContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  status: {

  },
  icon: {
    padding: 4,
  }
});

export default
  withStyles(styles, { withTheme: true })(
    withSnackbar(
      withTranslation()(RequestTable)));