import React from 'react';
import { Route, Switch, withRouter } from 'react-router';
import RequestsTable from './request/RequestTable';
import ViewRequest from './request/ViewRequest';



function RequestRouter(props) {
  const { match } = props;
  return (
    <Switch>
      <Route path={`${match.path}`} exact component={RequestsTable} />
      <Route path={`${match.path}/:id`} render={(props) => <ViewRequest {...props} redirectRoute={match.path} />} />
    </Switch>
  )
}

export default withRouter(RequestRouter);