import React, { Component } from 'react';
import { withStyles } from '@material-ui/core';

class SwaggerPage extends Component {

  componentDidMount() {
    let url = process.env.REACT_APP_PORTAL_URL + "/swagger.json";
    var page =
      `<!DOCTYPE html>
            <html xmlns="http://www.w3.org/1999/xhtml">
            <head>
                <meta charset="UTF-8">
                <title>Swagger</title>
                <link rel="stylesheet" type="text/css" href="https://unpkg.com/swagger-ui-dist@3.12.1/swagger-ui.css">


            </head>
            <body>

            <div id="swagger-ui"></div>

            <script src="https://unpkg.com/swagger-ui-dist@3.17.3/swagger-ui-standalone-preset.js"></script>
            <script src="https://unpkg.com/swagger-ui-dist@3.17.3/swagger-ui-bundle.js"></script>

            <script>
                window.onload = function() {
                    // Build a system
                    const ui = SwaggerUIBundle({
                        url: "${url}",
                        dom_id: '#swagger-ui',
                        deepLinking: true,
                        presets: [
                            SwaggerUIBundle.presets.apis,
                            SwaggerUIStandalonePreset
                        ],
                        plugins: [
                            SwaggerUIBundle.plugins.DownloadUrl
                        ],
                        layout: "BaseLayout"
                    })
                    window.ui = ui
                }                
            </script>
            </body>
            </html>`;

    let frame = this.refs.frame;
    let iFrameDoc = frame.contentWindow.document;
    iFrameDoc.open();
    iFrameDoc.write(page);
    iFrameDoc.close();
    //iFrameDoc.addEventListener('click', this.handleContainerClick);
    document.body.style.overflow = "hidden";
  }
  shouldComponentUpdate() {
    return false;
  }

  componentWillUnmount() {
    document.body.style.overflow = "visible";
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.container} >
        <iframe id='frame' ref='frame' className={classes.frame} title='swagger-frame' />
      </div>
    )
  }
}

const styles = theme => ({
  frame: {
    border: 0,
    width: '100%',
    height: '90vh',
  }
})

export default withStyles(styles, { withTheme: true })(SwaggerPage);


