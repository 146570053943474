import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { IconButton } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';


export function StatusFilterComponent(props) {
  const { filter, onChange } = props;
  return (
    <select
      onChange={e => onChange(e.target.value)}
      style={{ width: '100%' }}
      value={filter ? filter.value : 'all'}
    >
      <option value='all'>Show All</option>
      <option value='active'>Active</option>
      <option value='inactive'>Inactive</option>
    </select>
  )
}

StatusFilterComponent.propTypes = {
  onChange: PropTypes.func.isRequired,
};


export function StatusFilterMethod(filter, row) {
  if (filter.value === 'all') {
    return true;
  }
  return filter.value === 'active' ? row[filter.id] : !row[filter.id];
}

class StatusCell extends Component {
  displayName = StatusCell.name;

  editLink = itemProps => <Link to={this.props.editTo || '/'} {...itemProps} />;

  render() {
    const { value, classes } = this.props;
    return (
      <div className={classes.container}>
        <div className={classes.status}>
          <span style={{ color: value ? '#57d500' : '#ff2e00', transition: 'all .3s ease', paddingRight: 5 }}>&#x25cf;</span>
          {value ? 'Active' : 'Inactive'}
        </div>
        <IconButton component={this.editLink} className={classes.edit}>
          <EditIcon fontSize='small' />
        </IconButton>
      </div>
    )
  }
}

StatusCell.propTypes = {
  editTo: PropTypes.string.isRequired,
  value: PropTypes.bool.isRequired,
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

const styles = theme => ({
  container: {
    display: 'flex',
    alignItems: 'center',
  },
  status: {
    flexGrow: 1,
  },
  edit: {
    padding: 4,
  }
});

export default withStyles(styles, { withTheme: true })(StatusCell);

