export function PostRegistrationToAPI(registrationJson, authKey, orgId, vendorId) {
  const req = {
    method: 'POST',
    headers: new Headers({
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
      'authKey': authKey,
      'orgId': orgId,
      'vendorId': vendorId
    }),
    body: JSON.stringify(registrationJson)
  }

  let url = [process.env.REACT_APP_NSR_API_URL].join('/');
  return fetch(url, req);
}