import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

import { List, ListItem, ListItemIcon, ListItemText, Collapse } from '@material-ui/core';
import { ExpandLess, ExpandMore, Build, Assignment, HowToReg, Group, AccountBalance, Notifications } from '@material-ui/icons';

class AdminCollapse extends Component {
  displayName = AdminCollapse.name;
  constructor(props) {
    super(props);
    this.state = {
      open: (props.pathname || "").includes(props.pathRoot),
    }
  }

  handleClick = () => {
    this.setState(state => ({ open: !state.open }));
  };

  render() {
    const { classes, pathRoot, pathname, t } = this.props;

    return (
      <React.Fragment>
        <ListItem button onClick={this.handleClick}>
          <ListItemIcon>
            <Build />
          </ListItemIcon>
          <ListItemText inset primary={t('nav.admin')} />
          {this.state.open ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={this.state.open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem button component={Link} to={`${pathRoot}/news`} className={classes.nested} selected={pathname === `${pathRoot}/news`}>
              <ListItemIcon>
                <Assignment />
              </ListItemIcon>
              <ListItemText inset primary={t('nav.news')} />
            </ListItem>
            <ListItem button component={Link} to={`${pathRoot}/requests`} className={classes.nested} selected={pathname === `${pathRoot}/requests`}>
              <ListItemIcon>
                <HowToReg />
              </ListItemIcon>
              <ListItemText inset primary={t('nav.requests')} />
            </ListItem>
            <ListItem button component={Link} to={`${pathRoot}/users`} className={classes.nested} selected={pathname === `${pathRoot}/users`}>
              <ListItemIcon>
                <Group />
              </ListItemIcon>
              <ListItemText inset primary={t('nav.users')} />
            </ListItem>
            <ListItem button component={Link} to={`${pathRoot}/organizations`} className={classes.nested} selected={pathname === `${pathRoot}/organizations`}>
              <ListItemIcon>
                <AccountBalance />
              </ListItemIcon>
              <ListItemText inset primary={t('nav.organizations')} />
            </ListItem>
            <ListItem button component={Link} to={`${pathRoot}/notifications`} className={classes.nested} selected={pathname === `${pathRoot}/notifications`}>
              <ListItemIcon>
                <Notifications />
              </ListItemIcon>
              <ListItemText inset primary={t('nav.notifications')} />
            </ListItem>
          </List>
        </Collapse>
      </React.Fragment>
    )
  }
}

AdminCollapse.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

const styles = theme => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing.unit * 4,
  },
});

export default
  withStyles(styles, { withTheme: true })(
    withTranslation()(AdminCollapse));