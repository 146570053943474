export const RequestTypes = {
  "1": "Registration",
  "2": "Add Organization",
  "3": "FIFA Duplicate",
  "4": "Organization Data Update"
}

export const RequestStatusTypes = {
  "1": "Pending",
  "2": "Approved",
  "3": "Denied",
  "4": "Resolved"
}