import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { withSnackbar } from 'notistack';
import { Grid, Typography } from '@material-ui/core';
import ReactTable from 'react-table';
import classNames from 'classnames';

import LoadingSpinner from '../../../../components/LoadingSpinner';
import StatusCell, { StatusFilterMethod, StatusFilterComponent } from '../../../../components/table/StatusCell';
import { GetAllUsersAsAdminFromPortal } from '../../../../api/user/GetUsersFromPortal';
import { PhoneFormatter } from '../../../../utils/Formatters';
import { withTranslation } from 'react-i18next';
import { PageTitle } from '../../../../components/text/Titles';

class UserTable extends Component {
  displayName = UserTable.name;
  constructor(props) {
    super(props);
    this.state = {
      users: [],
    }
  }

  componentDidMount() {
    const { users } = this.state;
    if (users.length > 0) {
      return;
    }

    GetAllUsersAsAdminFromPortal()
      .then(res => {
        if (res.ok) {
          return res.json();
        }
        throw res.text();
      })
      .then(json => this.setState({ users: json }))
      .catch(e => {
        this.props.enqueueSnackbar(`Error fetching users`, { variant: 'error', autoHideDuration: 5500 });
        console.log(e);
      });
  }

  getColumns = () => {
    const { match } = this.props;
    let baseEditRoute = `${match.path}/edit`;
    return [
      {
        Header: 'First Name',
        accessor: 'firstName'
      },
      {
        Header: 'Last Name',
        accessor: 'lastName'
      },
      {
        Header: 'Email',
        accessor: 'emailAddress'
      },
      {
        Header: 'Phone',
        accessor: 'phoneNumber',
        Cell: cellInfo => PhoneFormatter(cellInfo.value)

      },
      // {
      //   Header: 'Last Login',
      //   accessor: 'lastLoginDateTime',
      // },
      // {
      //   Header: 'Last Modified By',
      //   accessor: 'lastUpdateUserId',
      // },
      // {
      //   Header: 'Last Modified Time',
      //   accessor: 'lastUpdateDateTime',
      // },
      {
        Header: 'Status',
        accessor: 'activeIndicator',
        Cell: cellInfo => <StatusCell value={cellInfo.value} editTo={`${baseEditRoute}/${cellInfo.original.userId}`} />,
        filterMethod: StatusFilterMethod,
        Filter: StatusFilterComponent,
      },
    ]
  }

  render() {
    const { users } = this.state;
    const { classes, t } = this.props;

    if (users.length === 0) {
      return <LoadingSpinner />
    }

    console.log(users);
    let columns = this.getColumns();

    return (
      <Grid container>
        <Grid item xs={12}>
          <PageTitle text={t('user.userAdministration')} />
          <ReactTable
            data={users}
            filterable
            defaultFilterMethod={(filter, row) => String(row[filter.id]).toLowerCase().includes(String(filter.value).toLowerCase())}
            columns={columns}
            className={classNames('-striped -highlight', classes.table)}
          />
        </Grid>
      </Grid>
    )
  }
}

UserTable.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

const styles = theme => ({
  table: {
    fontFamily: theme.typography.fontFamily,
  },
  title: {
    marginBottom: theme.spacing.unit * 2,
  }
});

export default
  withStyles(styles, { withTheme: true })(
    withSnackbar(
      withTranslation()(UserTable)));