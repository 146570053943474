import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from "react-router";
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { Grid, Paper, Typography, Button } from '@material-ui/core';
import { withUserContext } from '../../components/contexts/UserContext';
import { withTranslation } from 'react-i18next';

import { signInUrl } from '../../utils/AzureADB2C';

import LoadingSpinner from '../../components/LoadingSpinner';

class LoginForm extends Component {
  displayName = LoginForm.name;

  componentDidMount() {
    const { user } = this.props;
    if (user.isAuthenticated) {
      this.props.history.push('/portal/login');
    }
  }

  render() {
    const { classes, user, t } = this.props;
    if (user.isAuthenticated) {
      return <LoadingSpinner message='Logging in' />
    }

    return (
      <Grid item xs={12} sm={10} md={8} lg={6}>
        <Paper className={classes.paper}>
          <Typography variant='h6' color='inherit' >
            {t('login.Log in to Canada Soccer Portal')}
          </Typography>
          <Button variant='contained' color='secondary' href={signInUrl} className={classes.loginBtn}>
            {t('buttons.login')}
          </Button>
          <div className={classes.spacer} />
          <Typography color='inherit' >
            {t('login.Not a member?')}
          </Typography>
          <Typography color='inherit' component={Link} to='/register'>
            {t('login.Click here to register')}
          </Typography>
          <div className={classes.spacer} />
          <Typography color='inherit' >
            {t('login.Forgot your password?')}
          </Typography>
          <Typography color='inherit' component={() =>
            <a className={classes.reset} href={`${process.env.REACT_APP_PW_RESET_URL}`}>{t('login.Click here to reset your password')}</a>
          } />

        </Paper>
      </Grid >
    )
  }
}

LoginForm.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    padding: theme.spacing.unit * 2,
    margin: theme.spacing.unit * 2,
    minWidth: 400,
  },
  reset: {
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.fontSize,
    color: theme.palette.text.secondary,
    fontWeight: theme.typography.fontWeight,
  },
  paper: {
    padding: theme.spacing.unit * 2,
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  loginBtn: {
    marginTop: 40,
  },
  spacer: {
    minHeight: 75,
  }
});


export default
  withStyles(styles, { withTheme: true })(
    withRouter(
      withUserContext(
        withTranslation()(LoginForm))));