import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Typography, List } from '@material-ui/core';
import UserOrganizationItem from './UserOrganizationItem';


function UserOrganizationsControl(props) {
  const { userOrganizations, classes, onOrgTerminationToggle } = props; 
  return (
    <div>
      <Typography className={classes.title} variant='h6' color='inherit'>User's Organizations</Typography>
      <List className={classes.root}>
        {
          userOrganizations.map((o, i) =>
            <UserOrganizationItem
              key={i}
              organizationCs={o.organizationCs}
              until={o.effectiveToDateTime}
              onOrgTerminationToggle={onOrgTerminationToggle}
            />)
        }
      </List>
    </div>
  )
}

UserOrganizationsControl.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

const styles = theme => ({
  title: {
    marginTop: theme.spacing.unit * 4,
    marginBottom: theme.spacing.unit * 2,
  }
});

export default withStyles(styles, { withTheme: true })(UserOrganizationsControl);