import { Grid, Paper, withStyles } from '@material-ui/core';
import React, { Component } from 'react';
import { withLocalization } from '../../../components/contexts/LanguageContext';
import { ListItem, UnorderedList } from '../../../components/text/Lists';
import { PageTitle } from '../../../components/text/Titles';
import APIFormat from './quickstart/APIFormat';
import APIOverview from './quickstart/APIOverview';
import Authentication from './quickstart/Authentication';
import APIVersioning from './quickstart/APIVersioning';
import APISpecification from './quickstart/APISpecification';
import Environments from './quickstart/Environments';
import Prerequisites from './quickstart/Prerequisites';

class QuickStartPage extends Component {
  render() {
    const { strings, classes } = this.props;
    return (
      <Grid container>
        <Grid item xs={12} md={9}>
          <Grid container spacing={24}>
            <PageTitle text={strings.pageTitle} />
            <Paper className={classes.paper}>
              <APIOverview />
              <Prerequisites />
              <APIFormat />
              <APIVersioning />
              <Authentication />
              <APISpecification />
              <Environments />
            </Paper>
          </Grid>
        </Grid>
        <Grid item xs={false} md={3}>
          <UnorderedList fixed>
            <ListItem text='API Overview' bordered />
            <ListItem text='Prerequisites' bordered />
            <ListItem text='API Format' bordered />
            <ListItem text='API Versioning' bordered />
            <ListItem text='Authentication' bordered />
            <ListItem text='API Specification' bordered />
            <ListItem text='Environments' bordered />
          </UnorderedList>
        </Grid>
      </Grid>
    );
  }
}

const strings = {
  en: {
    pageTitle: 'Quick Start Guide',
  },
  fr: {
    pageTitle: "Guide d'Access Rapide",
  }
}

const styles = theme => ({
  paper: {
    padding: theme.spacing.unit * 4,
  },
  pageTitle: {
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 3,
  },
  title: {
  },
  section: {
    marginBottom: theme.spacing.unit * 2,
  }
});

export default
  withStyles(styles, { withTheme: true })(
    withLocalization(strings)(QuickStartPage));