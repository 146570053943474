import React, { Component } from 'react';
import LoadingSpinner from '../../components/LoadingSpinner';
import { withUserContext } from '../../components/contexts/UserContext';

class LoginRedirectPage extends Component {
  displayName = LoginRedirectPage.name;

  componentDidMount() {
    const { orgContext, history } = this.props;
    if (orgContext.roleName === process.env.REACT_APP_ADMIN_ROLE) {
      history.push('/portal/admin/dashboard');
    }
    else {
      history.push('/portal/news');
    }
  }

  render() {
    return (
      <LoadingSpinner message='Authenticating...' />
    )
  }
}


export default
  withUserContext(LoginRedirectPage);