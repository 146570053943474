import { Button, Grid, Paper } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { withSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import AsyncSelect from 'react-select/lib/Async';
import { GetVendorsWithAccessTo } from '../../../api/organization/GetOrganizationsFromPortal';
import { PostFindVendorsWithToPortal, PostGrantVendorAccessToPortal, PostRevokeVendorAccessToPortal } from '../../../api/organization/PostOrganizationToPortal';
import { SectionTitle } from '../../../components/text/Titles';
import ApprovedVendorsList from './ApprovedVendorsList';



class APIKeyManagement extends Component {
  displayName = APIKeyManagement.name;
  constructor(props) {
    super(props);
    this.state = {
      noValue: false,
      updating: true,
      inputValue: '',
      selectedVendor: '',
      selectVendorsList: [],
      grantedVendors: [],
    }
  }

  componentDidMount() {
    const { grantedVendors } = this.state;
    if (grantedVendors.length === 0) {
      this.fetchGrantedVendors();
    }
    }

  fetchGrantedVendors = (successMessage) => {
    const { organizationId } = this.props;
    GetVendorsWithAccessTo(organizationId)
      .then(res => {
        if (res.ok) {
          return res.json();
        }
        throw res.text();
      })
      .then(json => {
        this.setState({
          grantedVendors: json,
          updating: false,
          noValue: true,
        }, () => {
          if (successMessage) {
            this.props.enqueueSnackbar(successMessage, { variant: 'success', autoHideDuration: 5500 });
          }
        });
      })
      .catch(e => {
        console.log(e);
        this.props.enqueueSnackbar(`Error fetching Vendors with access`, { variant: 'error', autoHideDuration: 5500 });
      })
  }

  handleVendorGrantSubmit = () => {
    const { selectedVendor, grantedVendors } = this.state;
    const { organizationId } = this.props;

    if (grantedVendors.filter(g => g.organizationId === selectedVendor.value).length === 0) {
      this.setState({
        updating: true,
        selectedVendor: '',
      }, () => {
        PostGrantVendorAccessToPortal(selectedVendor.value, organizationId)
          .then(res => {
            if (res.ok) {
              return res.json();
            }
            throw res.text();
          })
          .then(json => {
            let successMessage = `Granted API access to ${selectedVendor.label}`;
            this.fetchGrantedVendors(successMessage);
          })
          .catch(e => {
            console.log(e);
            this.props.enqueueSnackbar(`Error granting API access to ${selectedVendor.label}`, { variant: 'error', autoHideDuration: 5500 });
          })
      });

    }
    else {
      this.setState({ selectedVendor: '' });
    }
  }

  handleRevokeVendorClick = vendorId => event => {
    const { grantedVendors } = this.state;
    const { organizationId } = this.props;

    let vendorToRevoke = grantedVendors.find(v => v.organizationId === vendorId);
    if (!vendorToRevoke) {
      this.props.enqueueSnackbar(`Error determine which vendor to revoke API access from`, { variant: 'error', autoHideDuration: 5500 });
    }
    else {
      this.setState({
        updating: true,
        selectedVendor: '',
      }, () => {
        PostRevokeVendorAccessToPortal(vendorId, organizationId)
          .then(res => {
            if (res.ok) {
              return res.json();
            }
            throw res.text();
          })
          .then(json => {
            let successMessage = `Revoked API access from ${vendorToRevoke.organizationName}`;
            this.fetchGrantedVendors(successMessage);
          })
          .catch(e => {
            console.log(e);
            this.props.enqueueSnackbar(`Error revoking API access from ${vendorToRevoke.organizationName}`, { variant: 'error', autoHideDuration: 5500 });
          })
      })
    }
  }

  handleChange = selectedVendor => {
    this.setState({ selectedVendor: selectedVendor, noValue: selectedVendor ? false : true });
  }

  loadOptions = (inputValue, callback) => {
    const { organizationId } = this.props; 
    PostFindVendorsWithToPortal(inputValue, organizationId)
      .then(res => {
        if (res.ok) {
          return res.json();
        }
        throw res.text();
      })
      .then(json => {
        this.setState({ selectVendorsList: json }, () => {
          callback(this.filterVendors(inputValue));
        })
      })
      .catch(e => {
        console.log(e);
        this.props.enqueueSnackbar(`Error fetching all Vendors`, { variant: 'error', autoHideDuration: 5500 });
      })
  }

  filterVendors = (inputValue) => {
    const { selectVendorsList, grantedVendors } = this.state;
    let labels = [];
    for (let i = 0; i < selectVendorsList.length; i++) {
      let v = selectVendorsList[i];
      if (v.organizationName.toLowerCase().includes(inputValue.toLowerCase())) {
        // Don't display vendors that are already granted
        if (!grantedVendors.find(gv => gv.organizationId === v.organizationId)) {
          labels.push({
            label: v.organizationName,
            value: v.organizationId,
          });
        }
      }
    }
    return labels;
  }

  render() {
    const { selectedVendor, grantedVendors, updating, noValue} = this.state;
    const { classes } = this.props;
    return (
      <Paper className={classes.paper}>
        <SectionTitle text='API Key Access Management' />
        <Grid container spacing={24}>
          <Grid item xs={12}>
            <AsyncSelect key={this.state.updating} className={classes.select} value={selectedVendor} defaultOptions loadOptions={this.loadOptions} onChange={this.handleChange} />
          </Grid>
          <Grid item xs={12}>
            <Button disabled={noValue} variant='contained' onClick={this.handleVendorGrantSubmit}>Grant Access</Button>
          </Grid>
          <Grid item xs={12}>
            <ApprovedVendorsList updating={updating} vendors={grantedVendors} onRevokeClick={this.handleRevokeVendorClick} />
          </Grid>
        </Grid>
      </Paper>
    )
  }
}

APIKeyManagement.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

const styles = theme => ({
  select: {
    width: '100%',
  },
  paper: {
    padding: theme.spacing.unit * 4,
  },
});

export default
  withStyles(styles, { withTheme: true })(
    withSnackbar(APIKeyManagement));