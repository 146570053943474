import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { withUserContext } from '../../components/contexts/UserContext';
import RegistrationButton from './RegistrationButton';
import { Menu, MenuItem, List, ListItem } from '@material-ui/core';
import OrgContextItem from './items/OrgContextItem';

class ContextButton extends Component {
  displayName = ContextButton.name;
  constructor(props){
    super(props);
    this.state = {
      anchorEl: null,
      selectedIndex: 0,
    }
  }

  componentDidUpdate() {
    const { selectedIndex } = this.state;
    const { orgContext, user } = this.props;
    if (orgContext.orgId) {
      for (let i = 0; i < user.contexts.length; i++){
        if (orgContext.orgId === user.contexts[i].orgId) {
          // Extremely important, otherwise it will update forever
          if (i !== selectedIndex) {
            this.setState({ selectedIndex: i });
          }
          return;
        }
      }
    }
  }

  handleContextClick = event => {
    this.setState({ anchorEl: event.currentTarget });
  }

  handleContextItemClick = (event, index) => {
    const { user, switchContext } = this.props;
    switchContext(user.contexts[index]);
    this.setState({ selectedIndex: index, anchorEl: null });
  }

  handleClose = () => {
    this.setState({ anchorEl: null });
  }

  render() {
    const { anchorEl, selectedIndex } = this.state;
    const { orgContext, user, classes } = this.props;

    if (orgContext.orgId === undefined) {
      return <RegistrationButton />
    }

    let orgName = orgContext.orgName;
    if (process.env.REACT_APP_ENV_NAME === 'Local') {
      orgName += ` (${orgContext.roleName})`;
    }

    return (
      <div className={classes.root}>
        <List className={classes.contextList} >
          <ListItem button onClick={this.handleContextClick}>
            <OrgContextItem primary={orgName} />
          </ListItem>
        </List>
        {
          user.contexts.length > 1 &&
          <Menu id='org-context-menu' anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={this.handleClose}>
            {user.contexts && user.contexts.length > 0 &&
              user.contexts.map((c, i) => (
                <MenuItem key={c.orgId} selected={i === selectedIndex} onClick={e => this.handleContextItemClick(e, i)}>
                  <OrgContextItem primary={c.orgName} />
                </MenuItem>
              ))
            }
          </Menu>
        }
      
      </div>
    )
  }
}

ContextButton.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

const styles = theme => ({
  contextList: {
    padding: 0,
  }
});

export default
  withStyles(styles, { withTheme: true })(
  withUserContext(ContextButton));