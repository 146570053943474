import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Typography, Grid, ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PlayerHistoryControls from './PlayerHistoryControls';
import RegistrationControls from './RegistrationControls';
import { withTranslation } from 'react-i18next';
import { PageTitle } from '../../../components/text/Titles';

function ReportsLanding(props) {
  const { match, classes, t } = props;
  return (
    <React.Fragment>
      <PageTitle text={t('report.reporting')} />
      <Grid container className={classes.root}>
        <Grid item xs={12}>
          <ExpansionPanel>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant='h5' className={classes.heading}>Registration</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <RegistrationControls />
            </ExpansionPanelDetails>
          </ExpansionPanel>
          <ExpansionPanel>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant='h5' className={classes.heading}>Player History</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <PlayerHistoryControls to={`${match.path}/history`} />
            </ExpansionPanelDetails>
          </ExpansionPanel>
        </Grid>
      </Grid>
    </React.Fragment>
  )
}

ReportsLanding.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

const styles = theme => ({
  root: {
  },
  title: {
    marginBottom: theme.spacing.unit * 4,
  },
});

export default 
  withStyles(styles, { withTheme: true })(
    withTranslation()(ReportsLanding));