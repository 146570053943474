import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { withSnackbar } from 'notistack';
import { Link } from 'react-router-dom';
import { Grid, Typography, Button, IconButton } from '@material-ui/core';
import classNames from 'classnames';
import ReactTable from 'react-table';
import { CSVLink, CSVDownload } from "react-csv";
import SaveIcon from '@material-ui/icons/Save';

import { GetMemberOrganizationsAsPTSOFromPortal } from '../../../api/organization/GetOrganizationsFromPortal';
import StatusCell, { StatusFilterMethod, StatusFilterComponent } from '../../../components/table/StatusCell';
import LoadingSpinner from '../../../components/LoadingSpinner';
import { withOrganizationTypes } from '../../../components/contexts/OrganizationTypesContext';
import OrganizationTypeCell, { OrganizationTypeFilterMethod, OrganizationTypeFilterComponent } from '../../../components/table/OrganizationTypeCell';
import { withTranslation } from 'react-i18next';
import { withUserContext } from '../../../components/contexts/UserContext';
import { PageTitle } from '../../../components/text/Titles';

class OrganizationTable extends Component {
  displayName = OrganizationTable.name;
  constructor(props) {
    super(props);
    this.state = {
      organizations: [],
      initialLoad: true,
      loading: true,
      totalPages: null,
      sorting: "name",
      pageSize: 10,
      currentPage: 0,
      exportData: [],
      exportDataLoaded: false
    }
  }

  componentDidMount() {
    const { organizations } = this.state;
    if (organizations.length > 0) {
      return;
    }

    this.fetchExportData();
    this.fetchData();
  }

  getColumns = () => {
    const { match, organizationTypes } = this.props;
    let baseEditRoute = `${match.path}/edit`;
    return [
      {
        Header: 'Name',
        accessor: 'organizationName',
      },
      {
        Header: 'Type',
        accessor: 'organizationTypeCode',
        Cell: cellInfo => <OrganizationTypeCell typeCode={cellInfo.value} />,
        filterMethod: OrganizationTypeFilterMethod,
        Filter: ({ filter, onChange }) => OrganizationTypeFilterComponent({ filter, onChange, organizationTypes })

      },
      {
        Header: 'Municipality',
        accessor: 'municipality'
      },
      {
        Header: 'Province',
        accessor: 'provinceCode'
      },
      {
        Header: 'Email',
        accessor: 'emailAddress'
      },
      {
        Header: 'Last Modified',
        accessor: 'lastUpdateDateTime',
      },
      {
        Header: 'Status',
        accessor: 'activeIndicator',
        Cell: cellInfo => {
          let x = cellInfo.original;
          return <StatusCell value={cellInfo.value} editTo={`${baseEditRoute}/${cellInfo.original.organizationId}`} />
        },
        filterMethod: StatusFilterMethod,
        Filter: StatusFilterComponent,
      },
    ]
  }

    fetchData = (state, instance) => {
        this.setState({ loading: true });
        const { orgContext } = this.props;

        const { filtered, page, pageSize, sorted } = state || {};
        console.log(orgContext.orgId);
         GetMemberOrganizationsAsPTSOFromPortal(orgContext.orgId, filtered, sorted, pageSize, page)
            .then(res => res.json())
            .then(json =>
            {
                console.log(json);
                this.setState({
                    organizations: json.organizations.map(o => JSON.parse(o)),
                    totalPages: json.totalPages,
                    initialLoad: false,
                    loading: false,
                });

          }
      )
      .catch(e => {
        this.props.enqueueSnackbar(`Error fetching organizations`, { variant: 'error', autoHideDuration: 5500 });
        console.log(e);
      });
    }

    fetchExportData = async () => {
        let exportChildOrgs = await this.exportData();
        this.setState({ 
            exportData: exportChildOrgs,
            exportDataLoaded: true
        });
    }

    exportData = () => {
        const { orgContext } = this.props;

         console.log(orgContext.orgId);
         return new Promise((resolve, reject) => {
            GetMemberOrganizationsAsPTSOFromPortal(orgContext.orgId,null,null,-1)
                .then(res => res.json())
                .then(json =>
                {
                    resolve(json.organizations.map(o => JSON.parse(o)));
                }
            )
            .catch(e => {
                this.props.enqueueSnackbar(`Error fetching organizations`, { variant: 'error', autoHideDuration: 5500 });
                console.log(e);
            });
        });
    }

  render() {
    const { initialLoad, organizations, totalPages, loading, pageSize, filtered, exportData, exportDataLoaded} = this.state;
    const { match, classes, t, orgContext } = this.props;
    if (initialLoad) {
      return <LoadingSpinner />
    }

    let columns = this.getColumns();

    return (
      <Grid container direction="column" spacing={12}>
        <Grid item>
          <PageTitle text={t('organization.organizationAdministration')} />
        </Grid>
        <Grid style={{ paddingBottom: 10}} item direction="row-reverse" container spacing={8}>
        <Grid style={{textAlign: 'right'}} item>
            <CSVLink className={`${exportDataLoaded ? "" : "btn disabled"}`} asyncOnClick={true} onClick={(event,done) => {done(exportDataLoaded)}} style={{textDecoration: 'none'}} filename={`${orgContext.orgName.replace(" ","_")}_Member_Organizations.csv`}  data={exportData}>
                <Button variant="contained" color={`${exportDataLoaded ? "primary" : "secondary"}`} className={classes.button}>
                    {`${exportDataLoaded ? "Export Data" : "Loading Export Data..."}`}
                </Button>
            </CSVLink> 
        </Grid>
        </Grid>
        <Grid item xs={12}>
          <ReactTable
            data={organizations}
            defaultFilterMethod={(filter, row) => {
              return String(row[filter.id]).toLowerCase().includes(String(filtered[filter.id].value).toLowerCase())
            }}
            columns={columns}
            className={classNames('-striped -highlight', classes.table)}

            manual
            pages={totalPages}
            loading={loading}
            defaultPageSize={pageSize}
            onFetchData={this.fetchData}
            filterable
          />
        </Grid>
      </Grid>
    )
  }
}




OrganizationTable.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

const styles = theme => ({
  table: {
    fontFamily: theme.typography.fontFamily,
  },
  title: {
    marginBottom: theme.spacing.unit * 2,
  }
});

export default
  withStyles(styles, { withTheme: true })(
    withUserContext(
        withSnackbar(
        withOrganizationTypes(
            withTranslation()(OrganizationTable)))));