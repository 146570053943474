import { withStyles } from '@material-ui/core/styles';
import { withSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import LoadingSpinner from '../../../../components/LoadingSpinner';
import { GetRequestFromPortal } from '../../../../api/registration/GetRequestsFromPortal';

import ViewRegistrationRequest from './viewRequestTypes/ViewRegistrationRequest'
import { ViewOrganizationRequest } from './viewRequestTypes/ViewOrganizationRequest'
import ViewFIFADuplicateRequest from './viewRequestTypes/ViewFIFADuplicateRequest.js'
import ViewOrganizationUpdateRequest from './viewRequestTypes/ViewOrganizationUpdateRequest.js'
import { GetAllOrganizationsFromPortal } from '../../../../api/organization/GetOrganizationsFromPortal';

class ViewRequest extends Component {
  displayName = ViewRequest.name;

  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
    };
  }

  componentDidMount() {
    let requestsPromise = this.getRequest();
    let organizationsPromise = this.getOrganizations();

    Promise.all([requestsPromise, organizationsPromise])
      .then((results) => {
        let request = results[0];
        let organizations = results[1];
        organizations.map(org => {
          org.label = org.value;
          return org;
        });
        this.setState({
          request: request,
          organizations: organizations,
          isLoading: false,
        })
      })
  }


  getRequest = () => {
    const { id } = this.props.match.params;
    return new Promise((resolve, reject) => {
      GetRequestFromPortal(id)
        .then(res => {
          if (res.ok) {
            return res.json();
          }
          throw res.text();
        })
        .then(json => resolve(json))
        .catch(e => {
          this.props.enqueueSnackbar(`Error fetching request`, { variant: 'error', autoHideDuration: 5500 });
          reject(e);
        });
    })
  }

  getOrganizations = () => {
    return new Promise((resolve, reject) => {
      GetAllOrganizationsFromPortal()
        .then(res => {
          if (res.ok) {
            return res.json();
          }
          throw res.text();
        })
        .then(json => resolve(json))
        .catch(e => {
          this.props.enqueueSnackbar(`Error fetching organizations`, { variant: 'error', autoHideDuration: 5500 });
          reject(e);
        });
    });
  }



  render() {
    const { isLoading, request, organizations } = this.state;
    const { history, enqueueSnackbar } = this.props;

    if (isLoading) {
      return <LoadingSpinner />
    }

    if (request === undefined || request === null) {
      console.log(request);
      return <div>Request Not Found</div>
    }

    switch (request.requestTypeCode) {
      case 1:
        return <ViewRegistrationRequest request={request} organizations={organizations} history={history} enqueueSnackbar={enqueueSnackbar} />
      case 2:
        return <ViewOrganizationRequest request={request} organizations={organizations} />
      case 3:
        return <ViewFIFADuplicateRequest request={request} organizations={organizations} history={history} enqueueSnackbar={enqueueSnackbar} />
      case 4:
        return <ViewOrganizationUpdateRequest request={request} organizations={organizations} enqueueSnackbar={enqueueSnackbar} history={history}/>
      default:
        return <div>Request Not Supported</div>

    }


  }
}

ViewRequest.propTypes = {
  redirectRoute: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

const styles = () => ({

});


export default
  withStyles(styles, { withTheme: true })(
    withSnackbar(ViewRequest));