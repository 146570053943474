import { Button, Grid, Paper, Typography, Popper } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { withSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { GetAllOrganizationTypesFromPortal, GetOrganizationApiKeyFromPortal, GetOrganizationAsAdminFromPortal } from '../../../../api/organization/GetOrganizationsFromPortal';
import { PostOrganizationToPortal, PostUpdateOrganizationToPortal } from '../../../../api/organization/PostOrganizationToPortal';
import { OrganizationInput, PhoneInput, SelectInput, SwitchInput, TextInput } from '../../../../components/inputs/Inputs';
import { isInvalid } from '../../../../components/inputs/Validation';
import LoadingSpinner from '../../../../components/LoadingSpinner';
import { ApplyBooleanInputChange, ApplyInputChange } from '../../../../utils/InputChanges';
import OrganizationApiKey from './OrganizationApiKey';
import classNames from 'classnames';


class ViewOrganization extends Component {
  displayName = ViewOrganization.name;

  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      organizationTypes: [],
      apiKey: undefined,
      formControls: {
        open: false,
        invalidSubmission: true,
      },
      formFields: CreateEmptyFormFields(),
      submitted: false
    };
  }

  componentDidMount() {
    this.initializeFields();
  }

  initializeFields = async () => {
    let orgTypes = await this.fetchOrganizationTypes();
    let fields = await this.fetchFormFields();
    let apiKey = await this.fetchApiKey();
    this.setState({
      organizationTypes: orgTypes,
      formFields: fields,
      isLoading: false,
      apiKey: apiKey
    });
  }

  fetchOrganizationTypes = () => {
    return new Promise((resolve, reject) => {
      GetAllOrganizationTypesFromPortal()
        .then(res => res.json())
        .then(json => {
          let orgTypes = [];
          json.forEach(j => {
            orgTypes.push({
              value: j.organizationTypeCode,
              text: j.organizationTypeNameEn
            })
          })
          resolve(orgTypes);
        })
        .catch(e => {
          this.props.enqueueSnackbar(`Error fetching organization types`, { variant: 'error', autoHideDuration: 5500 });
          reject(e);
        });
    })
  }

  fetchApiKey = () => {
    const { id } = this.props.match.params;

    return new Promise((resolve, reject) => {
      if (!id) {
        return resolve('');
      }
      GetOrganizationApiKeyFromPortal(id)
        .then(res => {
          if (res.status === 204) {
            return undefined;
          }
          if (res.ok) {
            return res.json();
          }
          throw res.text();
        })
        .then(json => resolve(json))
        .catch(e => {
          this.props.enqueueSnackbar(`Error fetching organization API key with id ${id}`, { variant: 'error', autoHideDuration: 5500 });
          reject(e);
        });
    })
  }

  fetchFormFields = () => {
    const { id } = this.props.match.params;
    return new Promise((resolve, reject) => {
      if (id === undefined) {
        return resolve(CreateEmptyFormFields());
      }

      GetOrganizationAsAdminFromPortal(id)
        .then(res => res.json())
        .then(json => resolve(GenerateFormFieldsFromJson(json)))
        .catch(e => {
          this.props.enqueueSnackbar(`Error fetching organization with id ${id}`, { variant: 'error', autoHideDuration: 5500 });
          reject(e);
        });
    });
  }

  handleBooleanInputChange = field => event => {
    let newFormFields = Object.assign({}, this.state.formFields);
    this.setState({ formFields: ApplyBooleanInputChange(field, event, newFormFields) });
  }

  handleInputChange = field => event => {
    let newFormFields = Object.assign({}, this.state.formFields);
    this.setState({ formFields: ApplyInputChange(field, event, newFormFields) });
  }

  anyInvalid = () => {
    const { formFields } = this.state;
    let newFormFields = {};
    let allValid = true;
    for (let field in formFields) {
      let newFieldState = Object.assign({}, formFields[field]);
      newFieldState = isInvalid(newFieldState);
      if(!newFieldState.invalid){
          newFieldState.error = "";
      }
      allValid = allValid && !newFieldState.invalid;
      newFormFields[field] = newFieldState;
      }
    if((!newFormFields['lat'].value && newFormFields['long'].value) || (newFormFields['lat'].value && !newFormFields['long'].value)){
        if (!newFormFields['lat'].value) {
            newFormFields['lat'].error = "Need to enter latitude along with longitude";
            newFormFields['lat'].invalid = true;
            allValid = false;
        }
        else if(!newFormFields['long'].value) {
            newFormFields['long'].error = "Need to enter longitude along with latitude";
            newFormFields['long'].invalid = true;
            allValid = false;
        }
    }
    this.setState({ formFields: newFormFields });
    return !allValid;
  }

  buildPostJson = () => {
    const { formFields } = this.state;
    const { id } = this.props.match.params;
    let json = {
      organizationId: id
    };
    for (let field in formFields) {
      let fieldState = formFields[field];
      if (field === 'parentOrganizationCs') {
        json['parentOrganizationId'] = fieldState.organizationId + "";
      }
      else {
        if (typeof fieldState.value === 'string') {
          json[field] = fieldState.value.trim();
        }
        else {
          json[field] = fieldState.value;
        }
      }
    }
    return json;
  }

  handleSubmit = () => {
    const { redirectRoute } = this.props;
    const { id } = this.props.match.params;

    let anyInvalid = this.anyInvalid();
    if (anyInvalid) {
      return;
    }

    const savingData = this.props.enqueueSnackbar(`Saving organization data...`, { variant: 'info', persist: true});

    let postJson = this.buildPostJson();
    let promise;
    if (id) {
      promise = PostUpdateOrganizationToPortal(postJson);
    }
    else {
      promise = PostOrganizationToPortal(postJson);
    }

    promise
      .then(res => {
        if (res.ok) {
          this.props.enqueueSnackbar(`Successfully ${id ? 'updated' : 'added new'} organization`, { variant: 'success', autoHideDuration: 5500 });
          this.props.history.push(redirectRoute);
        }
        else {
          this.props.enqueueSnackbar(`Error ${id ? 'updating' : 'creating'} organization`, { variant: 'error', autoHideDuration: 5500 });
          console.log(res);
        }
      })
      .catch(e => {
        this.props.enqueueSnackbar('Error adding organization', { variant: 'error', autoHideDuration: 5500});
        console.log(e)
      });
  }

  render() {
    const { organizationTypes, formFields, isLoading, apiKey} = this.state;
    const { organizationTypeCode, active, publicIndicator, organizationName, organizationCsId, organizationFifaId, organizationShortName, email, phoneNumber, municipality, provinceCode, postalCode, addressLine1, addressLine2, website, long, lat, parentOrganizationCs } = formFields;
    const { redirectRoute, classes } = this.props;
    const { id } = this.props.match.params;

    if (isLoading) {
      return <LoadingSpinner />
    }

    return (
      <form className={classNames(classes.container, active.value ? {} : classes.inactive)} noValidate autoComplete='off'>
        <Grid container spacing={24}>
          <Grid item md={1} xl={2}></Grid>
          <Grid item xs={12} md={10} xl={8}>
            <Paper className={classes.paper}>
              <Grid container spacing={16}>
                <Grid item xs={12}>
                  <Typography variant='h6' color='inherit'>{id ? 'Edit' : 'Create'} Organization</Typography>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <TextInput fullWidth field={organizationName} onChange={this.handleInputChange} />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Grid container spacing={16}>
                    <Grid item xs={12} md={6}>
                      <SelectInput field={organizationTypeCode} items={organizationTypes} onChange={this.handleInputChange} />
                    </Grid>
                    {id &&
                      <Grid item xs={6} md={3} className={classes.opaque}>
                        <SwitchInput field={active} onChange={this.handleBooleanInputChange} />
                      </Grid>
                       }
                     { id && <Grid item xs={6} md={2} className={classes.opaque}>
                        <SwitchInput field={publicIndicator} onChange={this.handleBooleanInputChange} />
                      </Grid>}
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <div className={classes.parentLabel}>Parent Organization</div>
                  <OrganizationInput
                    defaultSelectedOrg={{
                      label: parentOrganizationCs.organizationName,
                      value: parentOrganizationCs.organizationId,
                    }}
                    field={parentOrganizationCs}
                    onChange={this.handleInputChange}
                  />
                </Grid>
                <Grid item xs={12} md={4} lg={2}>
                  <div className={classes.parentLabel}>Canada Soccer ID</div>
                  <div className={classes.label}>{organizationCsId.value}</div>
                </Grid>
                <Grid item xs={12} md={4} lg={2}>
                  <div className={classes.parentLabel}>FIFA ID</div>
                  <div className={classes.label}>{organizationFifaId.value}</div>
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextInput field={organizationShortName} onChange={this.handleInputChange} />
                </Grid>
                <Grid item xs={12} md={8} lg={5} className={classes.grow}>
                  <TextInput fullWidth field={email} onChange={this.handleInputChange} />
                </Grid>
                <Grid item xs={12} md={4} lg={2}>
                  <PhoneInput field={phoneNumber} onChange={this.handleInputChange} />
                </Grid>
                <Grid item xs={12} lg={5}>
                  <TextInput fullWidth field={website} onChange={this.handleInputChange} />
                </Grid>
                <Grid item xs={12} lg={2}>
                  <TextInput fullWidth field={lat} onChange={this.handleInputChange} />
                </Grid>
                <Grid item xs={12} lg={2}>
                  <TextInput fullWidth field={long} onChange={this.handleInputChange} />
                </Grid>
                <Grid item xs={12} className={classes.grow}>
                  <TextInput fullWidth field={addressLine1} onChange={this.handleInputChange} />
                </Grid>
                <Grid item xs={12} className={classes.grow}>
                  <TextInput fullWidth field={addressLine2} onChange={this.handleInputChange} />
                </Grid>
                <Grid item xs={12} md={8} lg={6}>
                  <TextInput fullWidth field={municipality} onChange={this.handleInputChange} />
                </Grid>
                <Grid item xs={12} lg={4}>
                  <SelectInput field={provinceCode} items={provinceItems} onChange={this.handleInputChange} />
                </Grid>
                <Grid item xs={12} md={4} lg={2}>
                  <TextInput field={postalCode} onChange={this.handleInputChange} />
                </Grid>
              </Grid>
              <Button color='secondary' className={classes.button} onClick={this.handleSubmit}>{id ? 'Save' : 'Create'} Organization</Button>
              <Button component={Link} to={redirectRoute} className={classes.button} color='primary'>Cancel</Button>
            </Paper>
          </Grid>
          <Grid item md={1} xl={2}></Grid>
          {
            apiKey &&
            <React.Fragment>
              <Grid item md={1} xl={2}></Grid>
              <Grid item xs={12} md={10} xl={8}>
                <OrganizationApiKey isAdmin={true} organizationId={id} organizationName={organizationName.value} apiKey={apiKey.apiKey} />
              </Grid>
            </React.Fragment>
          }
        </Grid>
        </form>
    )
  }
}

ViewOrganization.propTypes = {
  redirectRoute: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

const styles = theme => ({
  inactive: {
    opacity: 0.5,
  },
  opaque: {
    opacity: '1.0 !important',
  },
  container: {
    display: 'flex',
  },
  grow: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing.unit * 2,
  },
  button: {
    marginTop: theme.spacing.unit * 3,
    margin: theme.spacing.unit,
  },
  parentLabel: {
    color: 'rgba(0, 0, 0, 0.54)',
    padding: 0,
    fontSize: '0.75rem',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    lineHeight: 2,
  },
  label: {
    color: 'rgba(0, 0, 0, 0.54)',
    padding: 0,
    lineHeight: 2,
  }
});

function GenerateFormFieldsFromJson(json) {
  let newFormFields = Object.assign({}, CreateEmptyFormFields());
  for (let field in newFormFields) {
    if (field === 'organizationType') {
      newFormFields[field].value = json.organizationTypeCode;
    }
    else if (field === 'active') {
      newFormFields[field].value = json.activeIndicator;
    }
    else if (field === 'parentOrganizationCs') {
      let parent = json.parentOrganizationCs;
      if (parent != null) {
        newFormFields[field].organizationId = parent.organizationId;
        newFormFields[field].organizationName = parent.organizationName;
        newFormFields[field].value = parent.organizationName;
      }
    }
    else if (field === 'email') {
      newFormFields[field].value = json.emailAddress;
    }
    else if (json[field]) {
      newFormFields[field].value = json[field];
    }
  }
  return newFormFields;
}

function CreateEmptyFormFields() {
  return {
    organizationName: {
      label: "Organization Name",
      id: 'organizationName',
      value: '',
      invalid: false,
      required: true,
      error : ""
    },
    organizationShortName: {
      label: "Short Name",
      id: 'organizationShortName',
      value: '',
      invalid: false,
      error : ""
    },
    parentOrganizationCs: {
      label: "Parent Organization",
      id: 'parentOrganizationCs',
      value: '',
      invalid: false,
      organizationId: '',
      error : ""
    },
    addressLine1: {
      label: "Address Line 1",
      id: 'addressLine1',
      value: '',
      invalid: false,
      error : "",
      required: true,
    },
    addressLine2: {
      label: "Address Line 2",
      id: 'addressLine2',
      value: '',
      invalid: false,
      error : ""
    },
    email: {
      label: "Email",
      id: 'email',
      value: '',
      invalid: false,
      error : ""
    },
    website: {
      label: "Website",
      id: 'website',
      value: '',
      invalid: false,
      error : ""
    },
    municipality: {
      label: "Municipality",
      id: 'municipality',
      value: '',
      invalid: false,
      error : ""
    },
    organizationTypeCode: {
      label: "Organization Type",
      id: 'organizationTypeCode',
      value: '',
      invalid: false,
      error : ""
    },
    phoneNumber: {
      label: "Phone",
      id: 'phoneNumber',
      value: '',
      invalid: false,
      error : ""
    },
    postalCode: {
      label: "Postal Code",
      id: 'postalCode',
      value: '',
      invalid: false,
      error : ""
    },
    provinceCode: {
      label: "Province",
      id: 'provinceCode',
      value: '',
      invalid: false,
      error : ""
    },
    active: {
      label: "Active",
      id: 'active',
      value: true,
      invalid: false,
      error: ""
    },
    organizationCsId: {
      label: "Canada Soccer ID",
      id: 'organizationCsId',
      value: '',
      invalid: false,
      error: ""
    },
    organizationFifaId: {
      label: "FIFA ID",
      id: 'organizationFifaId',
      value: '',
      invalid: false,
      error: ""
    },
    lat: {
      label: "Latitude",
      id: 'lat',
      value: null,
      invalid: false,
      error: ""
    },
    long: {
      label: "Longitude",
      id: 'long',
      value: null,
      invalid: false,
      error: ""
    },
    publicIndicator: {
      label:"Public",
      id: 'publicIndicator',
      value: false,
      invalid: false,
      error : ""
    }
  }
}

const provinceItems = [
  {
    value: "ON",
    text: "Ontario",
  },
  {
    value: "QC",
    text: "Quebec",
  },
  {
    value: "NS",
    text: "Nova Scotia",
  },
  {
    value: "NB",
    text: "New Brunswick",
  },
  {
    value: "MB",
    text: "Manitoba",
  },
  {
    value: "BC",
    text: "British Columbia",
  },
  {
    value: "PE",
    text: "Prince Edward Island",
  },
  {
    value: "SK",
    text: "Saskatchewan",
  },
  {
    value: "AB",
    text: "Alberta",
  },
  {
    value: "NL",
    text: "Newfoundland & Labrador",
  },
  {
    value: "NT",
    text: "Northwest Territories",
  },
  {
    value: "YT",
    text: "Yukon",
  },
  {
    value: "NU",
    text: "Nunavut",
  },
]

export default
  withStyles(styles, { withTheme: true })(
    withSnackbar(ViewOrganization));