import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import NumberFormat from 'react-number-format';
import { withStyles } from '@material-ui/core/styles';
import { TextField, FormControl, InputLabel, Select, MenuItem, Switch, FormControlLabel, FormHelperText } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import OrganizationInput from './OrganizationInput';


function SwitchInputComponent(props) {
  const { field, onChange, classes } = props;
  return (
    <FormControlLabel
      className={classes.switchField}
      control={
        <Switch
          checked={field.value}
          onChange={onChange(field.id)}
          value={field.value}
        />
      }
      label={field.label}
    />
  )
}


function SelectInputComponent(props) {
  const { field, items, onChange, classes, required, hideNone } = props;

  return (
    <FormControl required={field.required || required} error={field.error ? true : false} className={classes.selectField}>
      <InputLabel htmlFor={field.id}>{field.label}</InputLabel>
      <Select
        value={field.value}
        onChange={onChange(field.id)}
        autoWidth
        inputProps={{
          name: `${field.label}`,
          id: `${field.id}`,
        }}
      >
        {
          !hideNone &&
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
        }
        {items && items.map((item, i) => {
          return <MenuItem key={i} value={item.value}>{item.text}</MenuItem>
        })}
      </Select>
      <FormHelperText
          >{field.error}</FormHelperText>
    </FormControl>
  )
}

function TextInputComponent(props) {
  const { required, field, onChange, classes, inputProps, fullWidth } = props;

  const { t } = useTranslation();

  return (
    <TextField
      error={field.error ? true : false}
      helperText={field.error ? field.error : ""}
      required={required || field.required}
      id={field.id}
      label={t(`forms.${field.label}`)}
      className={classNames(classes.root, classes[field.id] || classes.textField)}
      value={field.value}
      onChange={onChange(field.id)}
      margin='normal'
      InputProps={inputProps}
      fullWidth={fullWidth}
    />
  )
}

TextInputComponent.propTypes = {
  required: PropTypes.bool,
  field: PropTypes.shape({
    id: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    invalid: PropTypes.bool,
  }).isRequired,
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  inputProps: PropTypes.object,
}


function PhoneMask(props) {
  const { inputRef, onChange, ...other } = props;
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            value: values.value,
          },
        });
      }}
      format='(###) ###-####'
      mask="_"
    />
  );
}
PhoneMask.propTypes = {
  inputRef: PropTypes.func.isRequired,
};

function PhoneInputComponent(props) {
  const { classes, ...other } = props;

  return (
    <TextInput
      {...other}
      inputProps={{ inputComponent: PhoneMask }}
    />
  )
}
PhoneInputComponent.propTypes = {
  field: PropTypes.shape({
    id: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    invalid: PropTypes.bool.isRequired,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
}


const styles = theme => ({
  root: {
    marginBottom: theme.spacing.unit * 2,
  },
  textField: {
    flexGrow: 1,
  },
  selectField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    marginTop: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit * 2,
    minWidth: 250,
  },
  switchField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    marginTop: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit * 2,
  },
  phone: {
  },
});


const TextInput = withStyles(styles, { withTheme: true })(TextInputComponent);
const PhoneInput = withStyles(styles, { withTheme: true })(PhoneInputComponent);
const SelectInput = withStyles(styles, { withTheme: true })(SelectInputComponent);
const SwitchInput = withStyles(styles, { withTheme: true })(SwitchInputComponent);

export {
  TextInput,
  PhoneInput,
  SelectInput,
  SwitchInput,
  OrganizationInput,
};
