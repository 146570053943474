import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { Home, Person, AssignmentInd, LiveHelp, SupervisedUserCircle, SpeakerNotes, Assessment, Dashboard, AccountBalance } from '@material-ui/icons';

import AdminCollapse from './AdminCollapse';
import DocumentationCollapse from './DocumentationCollapse';

function MenuItem(props) {
  return (
    <ListItem button component={Link} to={props.to} selected={props.pathname === props.to}>
      <ListItemIcon>
        {props.children}
      </ListItemIcon>
      <ListItemText primary={props.text} />
    </ListItem>
  )
}

MenuItem.propTypes = {
  to: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
}


export function HomeMenuItem(props) {
  const { t } = useTranslation();
  return (
    <MenuItem to='/' text={t('nav.home')} {...props}>
      <Home />
    </MenuItem>
  )
}

export function LoginMenuItem(props) {
  const { t } = useTranslation();
  return (
    <MenuItem to='/login' text={t('nav.login')} {...props}>
      <Person />
    </MenuItem>
  )
}

export function DashboardMenuItem(props) {
  const { t } = useTranslation();
  return (
    <MenuItem to='/portal/admin/dashboard' text={t('nav.dashboard')} {...props}>
      <Dashboard />
    </MenuItem>
  )
}

export function ProfileMenuItem(props) {
  const { t } = useTranslation();
  return (
    <MenuItem to='/portal/profile' text={t('nav.profile')} {...props}>
      <Person />
    </MenuItem>
  )
}

export function ManualRegistrationItem(props) {
  const { t } = useTranslation();
  return (
    <MenuItem to='/portal/manualregistrations' text={t('nav.manualRegistration')} {...props}>
      <AssignmentInd />
    </MenuItem>
  )
}

export function ReportsMenuItem(props) {
  const { t } = useTranslation();
  return (
    <MenuItem to='/portal/reports' text={t('nav.reports')} {...props}>
      <Assessment />
    </MenuItem>
  )
}

export function NewsMenuItem(props) {
  const { t } = useTranslation();
  return (
    <MenuItem to='/portal/news' text={t('nav.news')} {...props}>
      <SpeakerNotes />
    </MenuItem>
  )
}

export function DocumentationMenuItem(props) {
  return (
    <DocumentationCollapse pathRoot='/portal/documentation' {...props} />
  )
}

export function OrganizationMenuItem(props) {
  const { t } = useTranslation();
  return (
    <MenuItem to='/portal/organization' text={t('nav.organization')} {...props}>
      <SupervisedUserCircle />
    </MenuItem>
  )
}

export function OrganizationsMenuItem(props) {
  const { t } = useTranslation();
  return (
    <MenuItem to='/portal/organizations' text={t('nav.myOrganizations')} {...props}>
      <AccountBalance />
    </MenuItem>
  )
}

export function AdminMenuItem(props) {
  return (
    <AdminCollapse pathRoot='/portal/admin' {...props} />
  )
}

export function HelpMenuItem(props) {
  const { t } = useTranslation();
  return (
    <MenuItem to='/contact' text={t('nav.help')} {...props}>
      <LiveHelp />
    </MenuItem>
  )
}
