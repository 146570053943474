import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, Paper, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { withSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { PostOrganizationAPIKeyRegenToPortal } from '../../../../api/organization/PostOrganizationToPortal';
import { SectionTitle } from '../../../../components/text/Titles';

class OrganizationApiKey extends Component {
  displayName = OrganizationApiKey.name;
  constructor(props) {
    super(props);
    this.state = {
      apiKey: props.apiKey,
      open: false,
    }
  }

  handleOpen = () => this.setState({ open: true })
  handleClose = () => this.setState({ open: false })

  handleAPIRegenerate = () => {
    const { organizationId } = this.props;
    if (!organizationId) {
      return;
    }


    this.handleClose();

    PostOrganizationAPIKeyRegenToPortal(organizationId)
      .then(res => {
        if (res.ok) {
          return res.json();
        }
        throw res.text();
      })
      .then(json => {
        this.setState({
          apiKey: json,
        }, () => {
          this.props.enqueueSnackbar(`Regenerated API Key`, { variant: 'success', autoHideDuration: 5500 });
        })
      })
      .catch(e => {
        console.log(e);
        this.props.enqueueSnackbar(`Error regenerating API Key`, { variant: 'error', autoHideDuration: 5500 });
      })
  }

  render() {
    const { apiKey, open } = this.state;
    const { isAdmin, classes, organizationName } = this.props;

    return (
      <Paper className={classes.paper}>
        <Grid container spacing={24}>
          <Grid item xs={12}>
            <SectionTitle text={`API Key ${isAdmin ? 'Management' : ''}`} />
          </Grid>
          <Grid item xs={12}>
            <Typography variant='overline' className={classNames(classes.key, classes.center)} >{apiKey}</Typography>
          </Grid>
          {isAdmin &&
            <Grid item xs={12}>
              <Button variant='contained' onClick={this.handleOpen}>Regenerate API Key</Button>
            </Grid>
          }
        </Grid>
        <Dialog
          open={open}
          onClose={this.handleClose}
        >
          <DialogTitle id='api-key-dialog-title'>{`Regenerate ${organizationName} API Key?`}</DialogTitle>
          <DialogContent>
            <DialogContentText id='api-key-dialog-description'>
              {`Are you sure you want to regenerate the API key for ${organizationName}? This may interfere with their vendor's registration software.`}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleAPIRegenerate} color='primary'>Regenerate</Button>
            <Button onClick={this.handleClose} autoFocus>Cancel</Button>
          </DialogActions>
        </Dialog>
      </Paper>
    )
  }
}

OrganizationApiKey.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

const styles = theme => ({
  center: {
    textAlign: 'center',
  },
  key: {
    backgroundColor: '#eee',
    color: 'rgb(225, 0, 80)',
    margin: theme.spacing.unit,
    padding: theme.spacing.unit,
    fontSize: 16,
    marginLeft: 20,
  },
  paper: {
    padding: theme.spacing.unit * 4,
  },
});

export default
  withStyles(styles, { withTheme: true })(
    withSnackbar(OrganizationApiKey));