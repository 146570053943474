import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { Button, Grid, Typography } from '@material-ui/core';

import { GetAllNewsItemsFromPortal } from '../../../../api/news/GetNewsFromPortal';
import NewsFeed from '../../../../components/news/NewsFeed';
import { withSnackbar } from 'notistack';
import { PageTitle } from '../../../../components/text/Titles';
import { withTranslation } from 'react-i18next';

class AllAdminNews extends Component {
  displayName = AllAdminNews.name;
  constructor(props) {
    super(props);
    this.state = {
      newsItems: [],
    }
  }

  componentDidMount() {
    GetAllNewsItemsFromPortal()
      .then(res => res.json())
      .then(json => {
        console.log(json);
        this.setState({ newsItems: json });
      })
      .catch(e => {
        console.log(e);
        this.props.enqueueSnackbar('Error fetching all admin news items', { variant: 'error', autoHideDuration: 5500 });
      })
  }

  render() {
    const { newsItems } = this.state;
    const { match , t } = this.props;
    return (
      <Grid container spacing={24}>
        <Grid item xs={12}>
          <PageTitle text={t('news.newsItemAdministration')} />
        </Grid>
        <Grid item xs={12}>
          <Button variant='contained' component={Link} to={`${match.path}/add`} color='primary'>Create News Item</Button>
        </Grid>
        <Grid item xs={12}>
          <NewsFeed newsItems={newsItems} />
        </Grid>
      </Grid>
    )
  }
}

AllAdminNews.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

const styles = theme => ({
  title: {
    marginBottom: theme.spacing.unit * 2,
  }
});

export default
  withStyles(styles, { withTheme: true })(
    withSnackbar(
      withTranslation()(AllAdminNews)));