import React, { Component } from 'react';
import { Grid, TextField, Typography, Button } from '@material-ui/core';
import { PageTitle } from '../../../components/text/Titles';
import { GetNotificationSubscriberList } from '../../../api/registration/GetRequestsFromPortal';
import { ToggleAdminNotificationSubscription } from '../../../api/registration/PostRequestActionToPortal';

import { withSnackbar } from 'notistack';
import { withTranslation } from 'react-i18next';
import styled from 'styled-components';
import LoadingSpinner from '../../../components/LoadingSpinner';
import { Delete } from '@material-ui/icons';


const SubscriberItem = styled.div`
  padding: 1rem 0.5rem;
  display: flex;
  align-items: center;

  &:hover {
    background-color: #f5f5f5;
  }

  &:nth-of-type(even) {
    background-color: #f3f3f3;

    &:hover {
      background-color: #f1f1f1;
    }
  }
`
const DeleteContainer = styled.div`
  color: #b71c1c42;
  margin-right: 2rem;

  &:hover {
    color: #b71c1c;
    cursor: pointer;
  }
`
const AddContainer = styled.div`
  display: flex;
  align-items: baseline;
`

class NotificationSubscriptionPage extends Component {
  displayName = NotificationSubscriptionPage.name;
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      errorMsg: "",
      subscribers: [],
      newSubscriberEmail: "",
    }
  }

  componentDidMount() {
    this.fetchSubscriberDetails()
      .then(result => {
        console.log(result);
        this.setState({
          isLoading: false,
          subscribers: result
        });
      });
  }

  fetchSubscriberDetails = () => {
    return new Promise((resolve, reject) => {
      GetNotificationSubscriberList()
        .then(res => {
          if (res.ok) {
            return res.json();
          }
          throw res.text();
        })
        .then(json => resolve(json))
        .catch(e => {
          console.log(e);
          this.props.enqueueSnackbar('Error fetching notification subscribers', { variant: 'error', autoHideDuration: 5500 });
        })
    })
  }

  handleNewSubscriberChange = e => {
    this.setState({
      newSubscriberEmail: e.target.value
    });
  }

  handleAddClick = () => {
    const { newSubscriberEmail, subscribers } = this.state;

    if (subscribers.indexOf(newSubscriberEmail) !== -1) {
      this.setState({
        errorMsg: "Email already exists in the list"
      })
      return;
    }

    ToggleAdminNotificationSubscription(newSubscriberEmail)
      .then(res => {
        if (res.ok) {
          this.props.enqueueSnackbar(`Added "${newSubscriberEmail} to list"`, { variant: 'success', autoHideDuration: 5500 });
          this.setState(prevState => ({
            subscribers: [...prevState.subscribers, newSubscriberEmail],
            newSubscriberEmail: "",
            errorMsg: "",
          }))
        }
        else {
          throw res.text();
        }
      })
      .catch(e => {
        console.log(e);
        this.props.enqueueSnackbar('Error adding subscriber', { variant: 'error', autoHideDuration: 5500 });
      })
  }

  handleRemoveClick = email => {
    ToggleAdminNotificationSubscription(email)
      .then(res => {
        if (res.ok) {
          this.props.enqueueSnackbar(`Removed "${email} from list"`, { variant: 'success', autoHideDuration: 5500 });

          let newList = [...this.state.subscribers];
          let index = newList.indexOf(email);
          if (index !== -1) {
            newList.splice(index, 1);
            this.setState({
              subscribers: newList,
              errorMsg: "",
            })
          }
        }
        else {
          throw res.text();
        }
      })
      .catch(e => {
        console.log(e);
        this.props.enqueueSnackbar('Error removing subscriber', { variant: 'error', autoHideDuration: 5500 });
      })
  }

  render() {
    const { isLoading, subscribers, newSubscriberEmail, errorMsg } = this.state;
    const { t } = this.props;

    if (isLoading) {
      return <LoadingSpinner message={"Loading subscribers list"} />
    }

    return (
      <Grid container spacing={24}>
        <Grid item xs={12}>
          <PageTitle text={t('notification.notificationListAdministration')} />
        </Grid>
        <Grid item xs={12}>
          <AddContainer>
            <TextField label="Add new subscriber" value={newSubscriberEmail} onChange={this.handleNewSubscriberChange} />
            <Button onClick={this.handleAddClick} disabled={newSubscriberEmail.length === 0} variant="text">Add</Button>
          </AddContainer>
          {
            errorMsg.length > 0 &&
            <Typography color="primary">{errorMsg}</Typography>
          }
        </Grid>
        <Grid item xs={12}>
          <Typography variant={"body1"}>Current Subscribers:</Typography>
          {
            subscribers.length === 0 &&
            <div>-</div>
          }
          {
            subscribers.map((email, i) => {
              return (
                <SubscriberItem key={i}>
                  <DeleteContainer>
                    <Delete onClick={() => this.handleRemoveClick(email)} />
                  </DeleteContainer>
                  <Typography>
                    {email}
                  </Typography>
                </SubscriberItem>
              )
            })
          }
        </Grid>
      </Grid>
    )
  }
}



export default 
withSnackbar(
  withTranslation()(NotificationSubscriptionPage));