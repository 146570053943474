import { RequestTypes, RequestStatusTypes } from '../../../../utils/IndexDataConstants';
import React from 'react';

export function DefaultRequestFilterMethod(filter, row) {
  let { id, value } = filter;
  let { _original } = row;

  switch (id) {
    case 'requestType':
      return filterRequestByType(value, _original);
    case 'name':
      return filterRequestByName(value, _original);
    case 'email':
      return filterRequestByEmail(value, _original);
    case 'createDate':
      return filterRequestByCreateDate(value, _original);
    default:
      return true;
  }
}

function filterRequestByType(value, { requestTypeCode }) {
  return RequestTypes[requestTypeCode].toLowerCase().includes(value.toLowerCase());
}

export function getRequestDateTimeAsString({ createdDateTime }) {
  return new Date(createdDateTime).toLocaleDateString();
}

function filterRequestByCreateDate(value, _original) {
  return getRequestDateTimeAsString(_original).toLowerCase().includes(value.toLowerCase());
}

function filterRequestByEmail(value, { requestTypeCode, requestRegistration, requestUserOrganization, requestDuplicateResolution, requestOrganizationDataUpdate }) {
  switch (requestTypeCode) {
    case REQ_REG:
      return getRegistrationRequestEmail(requestRegistration).toLowerCase().includes(value.toLowerCase());
    case REQ_USER:
      return getOrganizationRequestEmail(requestUserOrganization).toLowerCase().includes(value.toLowerCase());
    case REQ_DUPE:
      return getDuplicateRequestEmail(requestDuplicateResolution).toLowerCase().includes(value.toLowerCase());
    case REQ_ORG:
      return getRegistrationRequestEmail(requestOrganizationDataUpdate).toLowerCase().includes(value.toLowerCase());
    default:
      return false;
  }
}

export function getDuplicateRequestEmail({ person: { emailAddress } }) {
  return emailAddress;
}

export function getRegistrationRequestEmail({ emailAddress }) {
  return emailAddress;
}

export function getOrganizationRequestEmail({ user: { emailAddress } }) {
  return emailAddress;
}

export function filterRequestByName(value, { requestTypeCode, requestRegistration, requestUserOrganization, requestDuplicateResolution, requestOrganizationDataUpdate }) {
  switch (requestTypeCode) {
    case REQ_REG:
      return getRegistrationRequestPersonName(requestRegistration).toLowerCase().includes(value.toLowerCase());
    case REQ_USER:
      return getOrganizationRequestPersonName(requestUserOrganization).toLowerCase().includes(value.toLowerCase());
    case REQ_DUPE:
      return getDuplicateRequestPersonName(requestDuplicateResolution).toLowerCase().includes(value.toLowerCase());
    case REQ_ORG:
      return getOrganizationDataUpdateOrgName(requestOrganizationDataUpdate).toLowerCase().includes(value.toLowerCase());
    default:
      return false;
  }
}

export function getDuplicateRequestPersonName({ person: { firstName, lastName } }) {
  return `${firstName} ${lastName}`;
}

export function getRegistrationRequestPersonName({ firstName, lastName }) {
  return `${firstName} ${lastName}`;
}

export function getOrganizationRequestPersonName({ user: { firstName, lastName } }) {
  return `${firstName} ${lastName}`;
}

export function getOrganizationDataUpdateOrgName({ organizationName }) {
    return `${organizationName}`;
}


export function ValueToColor(value) {
  switch (value.toLowerCase()) {
    case 'pending':
      return yellow;
    case 'approved':
    case 'resolved':
      return green;
    case 'denied':
    default:
      return red;
  }
}

const REQ_REG = 1;
const REQ_USER = 2;
const REQ_DUPE = 3;
const REQ_ORG = 4;


const red = '#ff2e00';
const green = '#57d500';
const yellow = '#FFCE54';


export function RequestTypeFilterComponent(props) {
  const { filter, onChange } = props;
  return (
    <select
      onChange={e => onChange(e.target.value)}
      style={{ width: '100%' }}
      value={filter ? filter.value : 'all'}
    >
      <option value='all'>Show All</option>
      <option value='1'>{RequestTypes[1]}</option>
      <option value='2'>{RequestTypes[2]}</option>
      <option value='3'>{RequestTypes[3]}</option>
      <option value='4'>{RequestTypes[4]}</option>
    </select>
  )
}

export function RequestTypeFilterMethod({ value }, { _original: { requestTypeCode } }) {
  if (value.toLowerCase() === 'all') {
    return true;
  }
  return parseInt(requestTypeCode) === parseInt(value);
}


export function StatusFilterComponent(props) {
  const { filter, onChange } = props;
  return (
    <select
      onChange={e => onChange(e.target.value)}
      style={{ width: '100%' }}
      value={filter ? filter.value : 'all'}
    >
      <option value='all'>Show All</option>
      <option value='1'>{RequestStatusTypes[1]}</option>
      <option value='2'>{RequestStatusTypes[2]}</option>
      <option value='3'>{RequestStatusTypes[3]}</option>
      <option value='4'>{RequestStatusTypes[4]}</option>
    </select>
  )
}

export function StatusFilterMethod({ value }, { _original: { requestStatusTypeCode } }) {
  if (value.toLowerCase() === 'all') {
    return true;
  }
  return parseInt(requestStatusTypeCode) === parseInt(value);
}