import { getToken } from '../../utils/AzureADB2C';


export const GetMyUserDataFromPortal = () => {
  const req = {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + getToken(),
      // 'Access-Control-Allow-Origin': 'https://canadasoccerexternal.b2clogin.com/canadasoccerexternal.onmicrosoft.com',
    }
  };

  let url = [process.env.REACT_APP_PORTAL_URL, 'account', 'getuserdata'].join('/');
  return fetch(url, req);
}

export const GetAllUsersAsAdminFromPortal = () => {
  const req = {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + getToken()
    },
  };

  let url = [process.env.REACT_APP_PORTAL_URL, 'user', 'getall'].join('/');
  return fetch(url, req);
}

export const GetUserDataAsAdminFromPortal = (id) => {
  const req = {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + getToken()
    },
  };

  let url = [process.env.REACT_APP_PORTAL_URL, 'user', 'get', id].join('/');
  return fetch(url, req);
}

export const GetMeFromPortal = () => {
  const req = {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + getToken()
    },
  };

  let url = [process.env.REACT_APP_PORTAL_URL, 'user', 'getme'].join('/');
  return fetch(url, req);
}
