import { Button, Grid, TextField, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { withSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { SearchForPlayers } from '../../../api/reports/GetReportsFromPortal';
import { withUserContext } from '../../../components/contexts/UserContext';
import LoadingSpinner from '../../../components/LoadingSpinner';
import PlayerSearchTable from './PlayerSearchTable';

class PlayerHistory extends Component {
  displayName = PlayerHistory.name;
  constructor(props) {
    super(props);
    this.state = {
      id: '',
      firstName: '',
      lastName: '',
      fetchingSearch: false,
      searchedPlayers: [],
    }
  }

  handleFirstNameChange = event => {
    this.setState({ firstName: event.target.value });
  }

  handleLastNameChange = event => {
    this.setState({ lastName: event.target.value });
  }

  handleIdChange = event => {
    let newValue = event.target.value;

    if (newValue < 1) {
      newValue = '';
    }

    this.setState({ id: newValue });
  }

  handleSearch = () => {
    const { id, firstName, lastName } = this.state;

    let playerId = id > 0 ? id : null;
    let orgId = this.props.orgContext.orgId;
    this.setState({ fetchingSearch: true });

    SearchForPlayers(orgId, firstName, lastName, playerId)
      .then(res => {
        if (res.ok) {
          return res.json();
        }
        throw res.text();
      })
      .then(json => {
        this.setState({
          fetchingSearch: false,
          searchedPlayers: json
        });
      })
      .catch(e => {
        this.props.enqueueSnackbar(`Error searching for players`, { variant: 'error', autoHideDuration: 5500 });
        console.log(e);
      });
  }

  render() {
    const { id, firstName, lastName, searchedPlayers, fetchingSearch } = this.state;
    const { to, classes } = this.props;


    return (
      <Grid container spacing={24}>
        <Grid item xs={12}>
          <Typography variant='h6' color='inherit'>Generate Player History</Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={24}>
            <Grid item>
              <TextField value={firstName} label='First Name' onChange={this.handleFirstNameChange} />
            </Grid>
            <Grid item>
              <TextField value={lastName} label='Last Name' onChange={this.handleLastNameChange} />
            </Grid>
            <Grid item>
              <TextField value={id} type='number' label='Canada Soccer Id' onChange={this.handleIdChange} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Button disabled={fetchingSearch} variant='contained' color='primary' onClick={this.handleSearch}>Search for Players</Button>
        </Grid>
        {fetchingSearch &&
          <LoadingSpinner message='Fetching Players' />
        }
        {!fetchingSearch && searchedPlayers.length > 0 &&
          <React.Fragment>
            <Typography variant='h6' color='inherit' className={classes.title}>Results Table</Typography>
            <PlayerSearchTable playerList={searchedPlayers} historyHref={to} />
          </React.Fragment>
        }
      </Grid>
    )
  }
}

// <Button disabled={disabled} variant='contained' component={Link} to={href} color='primary'>Search</Button>
PlayerHistory.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

const styles = theme => ({
  title: {
    marginTop: theme.spacing.unit * 2,
    padding: 12,
  }
});

export default
  withStyles(styles, { withTheme: true })(
    withSnackbar(
      withUserContext(PlayerHistory)));