import React from 'react';
import { MobileStepper, Button } from '@material-ui/core';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import styled from 'styled-components';

import PersonPlayerCard from './PersonPlayerCard';


function DuplicateStepper({ duplicateDetails, activeStep, onNewRecord, onDuplicateFound, onNext, onBack, onRemove, personDetailsBoolMap }) {
  const maxSteps = duplicateDetails.length + 1;

  return (
    <React.Fragment>
      <MobileStepper
        variant='dots'
        steps={maxSteps}
        position="static"
        activeStep={activeStep}
        nextButton={
          <Button size='small' onClick={onNext} disabled={activeStep === maxSteps - 1}>
            Next
              <KeyboardArrowRight />
          </Button>
        }
        backButton={
          <Button size='small' onClick={onBack} disabled={activeStep === 0}>
            <KeyboardArrowLeft />
            Back
            </Button>
        }
      />
      {
        (duplicateDetails.length > 0 && activeStep < duplicateDetails.length) &&
        <PersonPlayerCard
          onDuplicateFound={() => onDuplicateFound(activeStep)}
          onRemove={() => onRemove(activeStep)}
          personDetails={duplicateDetails[activeStep]}
          personDetailsBoolMap={personDetailsBoolMap}
          proximityScore={duplicateDetails[activeStep].proximityScore} />
      }
      {
        (activeStep >= duplicateDetails.length || duplicateDetails.length === 0) &&
        <AddNewRecordContainer>
          <Button variant='contained' color='primary' onClick={onNewRecord}>
            Create new record with FIFA
            </Button>
        </AddNewRecordContainer>
      }
    </React.Fragment>
  )
}

const AddNewRecordContainer = styled.div`
  height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export default DuplicateStepper;