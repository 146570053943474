import { Typography, withStyles, Grid, Paper, Button } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withUserContext } from '../../components/contexts/UserContext';
import { GetMeFromPortal } from '../../api/user/GetUsersFromPortal';
import { withSnackbar } from 'notistack';
import LoadingSpinner from '../../components/LoadingSpinner';
import { PhoneFormatter } from '../../utils/Formatters';
import { withTranslation } from 'react-i18next';
import { PageTitle, SectionTitle, SubTitle } from '../../components/text/Titles';

class ProfilePage extends Component {
  displayName = ProfilePage.name;
  constructor(props) {
    super(props);
    this.state = {
      profile: undefined,
    }
  }

  componentDidMount() {
    const { t } = this.props;
    GetMeFromPortal()
      .then(res => {
        if (res.ok) {
          return res.json();
        }
        throw res.text();
      })
      .then(json => this.setState({ profile: json }))
      .catch(e => {
        console.log(e);
        this.props.enqueueSnackbar(t('profile.Error retrieving profile'), { variant: 'error', autoHideDuration: 5500 });
      });
  }

  render() {
    const { profile } = this.state;
    const { user, classes, t } = this.props;

    if (!profile) {
      return <LoadingSpinner message={t('profile.Fetching profile information')} />
    }

    return (
      <Grid container direction="row">
        <Grid item xs={10}>
          <PageTitle text={t('profile.Profile Information')} />
        </Grid>
        <Grid item  xs={2}><Button variant='contained' color='primary' onClick={() => window.location = process.env.REACT_APP_PW_RESET_URL}>
                    {t('profile.Reset password')}
        </Button></Grid>

        <Grid container item xs={8}>
          <Grid item xs={4}></Grid>
          <Grid item xs={8}>
            <Paper className={classes.paper}>
            <Grid item><SectionTitle text='Profile Details'/></Grid>
            <Field label='Name' text={`${profile.firstName} ${profile.lastName}`}/>
            <Field label='Email Address' text={profile.emailAddress}/>
            <Field label='Phone Number' text={PhoneFormatter(profile.phoneNumber)}/>
            <div style={{ height: 6 }} />
            {
              user && user.contexts && user.contexts.length > 0 &&
              <Grid item>
                <SubTitle text={t('profile.Associated Organizations and Roles')}/>
                {
                  user.contexts.map(c => (
                      <Field label={c.orgName} text={GenerateOrgAndRole(c)} />
                  ))
                }
              </Grid>
            }
            </Paper>
            </Grid>
            <Grid item xs={4}></Grid>
        </Grid>
      </Grid>
    )
  }
}

ProfilePage.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

function GenerateOrgAndRole(userContext){
    var userText = "";
    if(userContext.roleName === "CSAdmin"){
        userText = '(Canada Soccer Administrator)';
    }
    else if(userContext.roleName === "SoccerOrg"){
        userText = '(Soccer Organization)';
    }
    else{
        userText = userContext.roleName;
    }

    return userText;
}

const styles = theme => ({
  reset: {
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.fontSize,
    color: theme.palette.text.secondary,
    fontWeight: theme.typography.fontWeight,
  },
  paper: {
    padding: theme.spacing.unit * 4,
  },
  center: {
    textAlign: 'center',
  },
  container: {
    display: 'flex',
    paddingLeft: theme.spacing.unit,
  },
  label: {
    width: 150,
    paddingRight: 5,
    paddingBottom: 5,
    paddingLeft: 20,
    textAlign: 'left',
    color: 'grey',
    fontWeight: 400
  }
});

const Field = withStyles(styles, { withTheme: true })(({ classes, label, text }) => {
    return (
        <div className={classes.container}>
            <Typography variant='body1' className={classes.label}>{label}</Typography>
            <Typography variant='body1' className={classes.text}>{text}</Typography>
        </div>
    )
});

export default
  withStyles(styles, { withTheme: true })(
    withSnackbar(
      withUserContext(
        withTranslation()(ProfilePage))));
