import React, { Component } from 'react';
import { Grid, Paper, Typography } from '@material-ui/core';
import { PageTitle } from '../../../components/text/Titles';
import { useTranslation } from 'react-i18next';

function SupportPage() {
    const { t } = useTranslation();
    return (
        <Grid container direction="column" spacing={22}>
            <Grid style={{ marginBottom: 5 }} item xs={12}>
                <PageTitle text='NSR API Support' />
            </Grid>
            <Grid item>
                <Typography style={{ fontWeight: 'bold', fontStyle: 'italic' }} variant='body1'>{t('support.contactUs')}</Typography>
            </Grid>
        </Grid>
    )
}

export default SupportPage;