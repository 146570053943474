import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { EditorState } from 'draft-js';
import { withStyles } from '@material-ui/core/styles';
import NewsEditorEn from '../../../../components/news/NewsEditorEn';
import NewsEditorFr from '../../../../components/news/NewsEditorFr';
import { Typography, Grid, Button } from '@material-ui/core';
import { withSnackbar } from 'notistack';

import { CreateNewsItemToPortal, UpdateNewsItemToPortal } from '../../../../api/news/PostNewsToPortal';
import { GetNewsItemById } from '../../../../api/news/GetNewsFromPortal';
import { ConvertFromRawToEditorState, ConvertFromContentToRaw } from '../../../../utils/EditorContentUtils';


class NewsEditor extends Component {
  displayName = NewsEditor.name;
  constructor(props){
    super(props);
    this.state = {      
      isLoading: true,
      en: EditorState.createEmpty(),
      fr: EditorState.createEmpty(),
    }
  }

  componentDidMount() {
    const { id } = this.props.match.params;
    if (id === undefined) {
      this.setState({ isLoading: false });
      return;
    }

    GetNewsItemById(id)
      .then(res => res.json())
      .then(json => {
        console.log(json);
        this.setState({
          en: ConvertFromRawToEditorState(json.contentEn),
          fr: ConvertFromRawToEditorState(json.contentFr),
          isLoading: false,
        })
      })
      .catch(e => {
        console.log(e);
        this.props.enqueueSnackbar('Error retrieving News Item', { variant: 'error', autoHideDuration: 5500 });
    })
  }

  handleFrenchChange = (editorState) => {
    this.setState({ fr: editorState });
  }

  handleEnglishChange = (editorState) => {
    this.setState({ en: editorState });
  }

  handleCancelClick = () => this.props.history.push(this.props.redirectRoute);

  handleSaveClick = () => {
    const { en, fr } = this.state;
    const { redirectRoute } = this.props;
    const { id } = this.props.match.params;
    let json = {
      contentEn: ConvertFromContentToRaw(en.getCurrentContent()),
      contentFr: ConvertFromContentToRaw(fr.getCurrentContent()),
      newsItemId: id,
    }    

    if (id) {
      UpdateNewsItemToPortal(json)
        .then(res => res.json())
        .then(json => {
          this.props.enqueueSnackbar('Updated News Item', { variant: 'success', autoHideDuration: 5500 });
          this.props.history.push(redirectRoute)
        })
      .catch(e => {
        console.log(e);
        this.props.enqueueSnackbar('Error updating News Item', { variant: 'error', autoHideDuration: 5500 });
      })
    }
    else {
      CreateNewsItemToPortal(json)
        .then(res => res.json())
        .then(json => {
          this.props.enqueueSnackbar('Created News Item', { variant: 'success', autoHideDuration: 5500 });
          this.props.history.push(redirectRoute)
        })
        .catch(e => {
          console.log(e);
          this.props.enqueueSnackbar('Error creating News Item', { variant: 'error', autoHideDuration: 5500 });
        })
    }
  }


  render() {
    const { isLoading } = this.state;
    const { classes } = this.props;

    if (isLoading) {
      return (
        <div>Loading...</div>
      )
    }

    return (
      <Grid container spacing={24}>
        <Grid item xs={12}>
          <Typography variant='h5'>Create a News Item</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant='h6'>English</Typography>
          <NewsEditorEn onOverrideChange={this.handleEnglishChange} overrideState={this.state.en} />
        </Grid>
        <Grid item xs={12}>
          <Typography variant='h6'>French</Typography>
          <NewsEditorFr onOverrideChange={this.handleFrenchChange} overrideState={this.state.fr} />
        </Grid>
        <Grid item xs={12}>
          <Button className={classes.button} variant='contained' color='secondary' onClick={this.handleSaveClick} >Save</Button>
          <Button className={classes.button} variant='contained' onClick={this.handleCancelClick}>Cancel</Button>
        </Grid>
      </Grid>
    )
  }
}

NewsEditor.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

const styles = theme => ({
  button: {
    margin: theme.spacing.unit,
    padding: theme.spacing.unit,
  }
});

export default
  withStyles(styles, { withTheme: true })(
    withRouter(
      withSnackbar(NewsEditor)));