import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

import { List, ListItem, ListItemIcon, ListItemText, Collapse } from '@material-ui/core';
import { ExpandLess, ExpandMore, FlightTakeoff, Description, Code, Help } from '@material-ui/icons';

class DocumentationCollapse extends Component {
  displayName = DocumentationCollapse.name;
  constructor(props) {
    super(props);
    this.state = {
      open: (props.pathname || "").includes(props.pathRoot),
    }
  }

  handleClick = () => {
    this.setState(state => ({ open: !state.open }));
  };

  render() {
    const { open } = this.state;
    const { classes, pathRoot, pathname, t } = this.props;

    return (
      <React.Fragment>
        <ListItem button onClick={this.handleClick}>
          <ListItemIcon>
            <Description />
          </ListItemIcon>
          <ListItemText inset primary={t('nav.documentation')} />
          {open ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem button component={Link} to={`${pathRoot}/quickstart`} className={classes.nested} selected={pathname === `${pathRoot}/quickstart`}>
              <ListItemIcon>
                <FlightTakeoff />
              </ListItemIcon>
              <ListItemText inset primary={t('nav.quickStart')} />
            </ListItem>
            <ListItem button component={Link} to={`${pathRoot}/swagger`} className={classes.nested} selected={pathname === `${pathRoot}/swagger`}>
              <ListItemIcon>
                <Code />
              </ListItemIcon>
              <ListItemText inset primary={t('nav.apiSpecification')} />
            </ListItem>
            <ListItem button component={Link} to={`${pathRoot}/support`} className={classes.nested} selected={pathname === `${pathRoot}/support`}>
              <ListItemIcon>
                <Help />
              </ListItemIcon>
              <ListItemText inset primary={t('nav.support')} />
            </ListItem>
          </List>
        </Collapse>
      </React.Fragment>
    )
  }
}

const styles = theme => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing.unit * 4,
  },
});

export default
  withStyles(styles, { withTheme: true })(
    withTranslation()(DocumentationCollapse));