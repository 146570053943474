export const resources = {
  en: {
    translation: {
      home: {
        title: 'National Soccer Registry',
        header: 'Welcome to the NSR Portal!',
        intro: 'A repository of documentation and administration functions of the National Soccer Registry for Soccer Organizations and Vendors'
      },
      contact: {
        contactMe: 'Contact me',
        title: 'Frequently Asked Questions',
        registerTitle: 'How to register with the NSR',
        registerBody: 'Click on the register link in the top right. Fill out the required information and submit. A Canada Soccer staff member will review your request. If approved, login credentials will be emailed to the email address supplied.',
        updateOrgTitle: 'How to update my organization information',
        updateOrgBody: 'Once registered and logged in, navigate to your Organization page using the left navigation menu. Click the Edit icon in the top right of the screen. Update any fields which require updating and save the changes. Your changes will be reviewed by Canada Soccer staff before being applied. Once approved your changes should be visible within the Club Directory tool on canadasoccer.com.',
        vendorTitle: 'How to select my registration vendor with the NSR',
        vendorBody: 'Navigate to your Organization page using the left navigation menu. Scroll down to the API Key Access Management panel. Select your Vendor from the list provided and click Grant Access. If your vendor is not listed or you manage your own website contact Canada Soccer at the email address provided below.',
        contactUs: 'If you any questions or feedback on the NSR Portal or program please contact info@canadasoccer.com'
      },
      support: {
        contactUs: 'If you have API integration questions or require further assistance please contact steve@apption.com'
      },
      login: {
        "Log in to Canada Soccer Portal": "Log in to Canada Soccer Portal",
        'Not a member?': "Not a member?",
        'Click here to register': 'Click here to register',
        "Forgot your password?": "Forgot your password?",
        "Click here to reset your password": "Click here to reset your password",
      },
      news: {
        newsItemAdministration: "News Item Administration",
        "Latest News": "Latest News",
        "Created on": "Created on"
      },
      forms: {
        "First Name": "First Name",
        "Last Name": "Last Name",
        "Email": "Email",
        "Phone": "Phone",
        "Organization": "Organization",
        "Role in Organization": "Role in Organization",
        "Organization Name": "Organization Name",
        "Short Name": "Short Name",
        "Organization Type": "Organization Type",
        "Website": "Website",
        "Address Line 1": "Address Line 1",
        "Address Line 2": "Address Line 2",
        "Municipality": "Municipality",
        "Postal Code": "Postal Code",
        "Province": "Province",
        "Longitude": "Longitude",
        "Latitude": "Latitude"
      },
      organization: {
        organizationAdministration: "Organization Administration",
        "Error fetching API keys": "Error fetching API keys",
        "Error fetching organization parent": "Error fetching organization parent",
        "Error fetching organizations": "Error fetching organizations",
        "Organization Information": "Organization Information",
        "Organization Details": "Organization Details",
        "Granted API Keys": "Granted API Keys",
        "Associated Organizations": "Associated Organizations"
      },
      profile: {
        "Error retrieving profile": "Error retrieving profile",
        "Fetching profile information": "Fetching profile information",
        "Profile Details": "Profile Details",
        "Profile Information": "Profile Information",
        "Reset password": "Reset password",
        "Associated Organizations and Roles": "Associated Organizations and Roles",
      },
      registration: {
        "Registration": "Registration",
        "Register": "Register",
        "Already registered? Click here to check your registration status": "Already registered? Click here to check your registration status",
        "Please enter valid information": "SVP entrer une réponse valide",
        "Submitted registration to portal": "Submitted registration to portal",
        "Failed registration with portal": "Failed registration with portal",
        "Error submitting registration to portal": "Error submitting registration to portal",
        "Registration request is": "Registration request is",
        "Already registered?": "Already registered?",
        "Enter your email to check the status of your registration": "Enter your email to check the status of your registration",
        "Not registered? Click here to register": "Not registered? Click here to register",
      },
      nav: {
        topbar: 'Canada Soccer - National Soccer Registry Portal',
        login: 'Log In',
        logout: 'Log Out',
        home: 'Home',
        dashboard: 'Dashboard',
        profile: 'Profile',
        manualRegistration: 'Manual Registration',
        reports: 'Reports',
        news: 'News',
        organization: 'Organization',
        myOrganizations: 'My Organizations',
        help: 'Help',
        admin: 'Admin',
        requests: 'Requests',
        users: 'Users',
        organizations: 'Organizations',
        documentation: 'Documentation',
        quickStart: 'Quick Start',
        apiSpecification: 'API Specification',
        support: 'Support',
        notifications: 'Notifications',
      },
      request: {
        requestAdministration: 'Request Administration'
      },
      report: {
        reporting: 'Reporting'
      },
      notification: {
        notificationListAdministration: 'Notification List Administration'
      },
      user: {
        userAdministration: 'User Administration'
      },
      buttons: {
        register: 'Register',
        login: 'Log In',
        logout: 'Log Out',
      },
      manualRegistration: {
        manualRegistration: "Manual Registration",
        httpData: "HTTP Header Data",
        registerFrom: "Register From",
        registerTo: "Register To",
        authenticationKey: "Authentication Key",
        vendorId: "Vendor ID",
        organizationId: "Organization ID",
        processingSubmission: "Processing Submission",
        submit: "Submit Registration",
        registrationData: "Registration Data",
        firstName: "First Name",
        lastName: "Last Name",
        gender: "Gender",
        male: "Male",
        female: "Female",
        dob: "Date of Birth",
        status: "Status",
        active: "Active",
        inactive: "Inactive",
        playerLevel: "Player Level",
        pro: "Pro",
        amateur: "Amateur",
        recreationalRegistration: "Recreational Registration",
        yes: "Yes",
        no: "No",
        dateBeforeEnd: "Date must be before end of registration",
        dateAfterStart: "Date must be after start of registration",
        homeAddress1: "Home Address Line 1",
        homeAddress2: "Home Address Line 2",
        municipality: "Municipality",
        province: "Province",
        ab: "Alberta",
        bc: "British Columnbia",
        mb: "Manitoba",
        nb: "New Brunswick",
        nl: "Newfoundland",
        ns: "Nova Scotia",
        nt: "Northwest Territory",
        nu: "Nunavut",
        on: "Ontario",
        pe: "Prince Edward Island",
        qc: "Quebec",
        sk: "Saskatchewan",
        yk: "Yukon Territory",
        postalCode: "Postal Code",
        homeCountryCode: "Home Country Code",
        emailAddress: "Email Address",
        parentEmailAddress: "Parent Email Address",
        homePhone: "Home Phone Number",
        mobilePhone: "Mobile Phone Number",
        citizenship: "Citizenship",
        birthPlace: "Birth Place",
        birthRegion: "Birth Region",
        birthCountryCode: "Birth Country Code",
        languagePref: "Language Preference",
        english: "English",
        french: "French",
      }
    }
  },
  fr: {
    translation: {
      home: {
        title: "Enregistrement National du Football",
        header: 'Bienvenue sur le portail ENF!',
        intro: "Un référentiel de fonctions de documentation et d'administration du registre national du football pour les organisations de football et leurs vendeurs"
      },
      contact: {
        contactMe: 'Contactez moi'
      },
      forms: {
        "First Name": "Prénom",
        "Last Name": "Nom",
        "Email": "Email",
        "Phone": "Téléphone",
        "Organization": "Organisation",
        "Role in Organization": "Rôle dans l'Organisation",
        "Organization Name": "Nom de l'Organisation",
        "Short Name": "Nom Court",
        "Organization Type": "Type d'Organisation",
        "Website": "Site Web",
        "Address Line 1": "Adress Ligne 1",
        "Address Line 2": "Adress Ligne 2",
        "Municipality": "Municipalité",
        "Postal Code": "Code Postal",
        "Province": "Province",
      },
      news: {
        newsItemAdministration: "**News Item Administration",
        "Latest News": "**Latest News",
        "Created on": "Créé le"
      },
      organization: {
        organizationAdministration: "**Organization Administration",
        "Error fetching API keys": "Erreur lors de la récupération des clés API",
        "Error fetching organization parent": "Erreur lors de la récupération du parent de l'organisation",
        "Error fetching organizations": "Erreur lors de la récupération des organisations",
        "Organization Information": "Information sur l'Organisation",
        "Organization Details": "Information sur l'Organisation",
        "Organization Information": "Information sur l'Organisation",
        "Granted API Keys": "Clés API accordées",
        "Associated Organizations": "Organisations associées"
      },
      profile: {
        "Error retrieving profile": "Erreur lors de la récupération du profil",
        "Fetching profile information": "Récupération des informations de profil",
        "Profile Details": "Informations sur le profil",
        "Profile Information": "Informations sur le profil",
        "Reset password": "Cliquez ici pour réinitialiser le mot de passe",
        "Associated Organizations and Roles": "Organisations et rôles associés",
      },
      registration: {
        "Registration": "Enregistrement",
        "Register": "Enregistrer",
        "Please enter valid information": "SVP entrer une réponse valide",
        "Already registered? Click here to check your registration status": "Déjà enregistré? Cliquez ici pour vérifier votre statut d'enregistrement",
        "Submitted registration to portal": "Inscription soumise au portail",
        "Failed registration with portal": "Échec avec l'inscription au portail",
        "Error submitting registration to portal": "Erreur lors de la soumission au portail",
        "Registration request is": "La demande d'enregistrement est",
        "Already registered?": "Déjà enregistrer?",
        "Enter your email to check the status of your registration": "Entrez votre email pour vérifier l'état de votre enregistrement",
        "Not registered? Click here to register": "Pas enregistré? Cliquez ici pour vous inscrire",
      },
      login: {
        "Log in to Canada Soccer Portal": "Connectez-vous au Portail Canada Soccer",
        "Not a member?": "Pas un membre?",
        "Click here to register": "Cliquez ice pour enregistrer",
        "Forgot your password?": "Oublié votre mot de passe?",
        "Click here to reset your password": "Cliquez ici pour réinitialiser votre mot de passe",
      },
      nav: {
        topbar: "Canada Soccer - Portail d'Enregistrement National du Football",
        login: 'Log In',
        logout: 'Log Out',
        home: 'Accueil',
        dashboard: 'Tableau De Bord',
        profile: 'Profil',
        manualRegistration: 'Enregistrement',
        reports: 'Rapports',
        news: 'Nouvelles',
        organization: 'Organisation',
        help: 'Aide',
        admin: 'Admin',
        requests: 'Requêtes',
        users: 'Utilisateur',
        organizations: 'Organisations',
        documentation: 'Documentation',
        quickStart: 'Départ Rapide',
        apiSpecification: 'Spécification du API',
        support: 'Support',
        notifications: 'Notifications',
      },
      request: {
        requestAdministration: '**Request Administration'
      },
      report: {
        reporting: '**Reporting'
      },
      notification: {
        notificationListAdministration: '**Notification List Administration'
      },
      user: {
        userAdministration: '**User Administration'
      },
      buttons: {
        register: 'Enregistrer',
        login: 'Log In',
        logout: 'Log Out',
      },
      manualRegistration: {
        httpData: "Données d'en-tête HTTP",
        registerFrom: "S'inscrire depuis",
        registerTo: "S'inscrire à",
        authenticationKey: "Clé d'Authentification",
        vendorId: "ID du Fournisseur",
        organizationId: "ID de l'Organisation",
        processingSubmission: "Traitement de la Soumission",
        submit: "Soumettre Enregistrement",
        registrationData: "Données d'Enregistrement",
        firstName: "Prénom",
        lastName: "Noms",
        gender: "Sexe",
        male: "Mâle",
        female: "Femelle",
        dob: "Date de Naissance",
        status: "Statut",
        active: "Actif",
        inactive: "Inactif",
        playerLevel: "Niveau du Joueur",
        pro: "Pro",
        amateur: "Amateur",
        recreationalRegistration: "Inscription Récréative",
        yes: "Oui",
        no: "Non",
        dateBeforeEnd: "La date doit être antérieure à la fin de l'inscription",
        dateAfterStart: "La date doit être postérieure au début de l'inscription",
        homeAddress1: "Adresse domiciliaire ligne 1",
        homeAddress2: "Adresse domiciliaire ligne 2",
        municipality: "Municipalité",
        province: "Province",
        ab: "Alberta",
        bc: "Colombie Britannique",
        mb: "Manitoba",
        nb: "Nouveau-Brunswick",
        nl: "Terre-Neuve",
        ns: "Nouvelle-Écosse",
        nt: "Territoire du Nord-Ouest",
        nu: "Nunavut",
        on: "Ontario",
        pe: "Île-du-Prince-Édouard",
        qc: "Québec",
        sk: "Saskatchewan",
        yk: "Territoire du Yukon",
        postalCode: "Code Postal",
        homeCountryCode: "Code du pays d'origine",
        emailAddress: "Adresse e-mail",
        parentEmailAddress: "Adresse e-mail des parents",
        homePhone: "Numéro de téléphone résidentiel",
        mobilePhone: "Numéro de téléphone portable",
        citizenship: "Citoyenneté",
        birthPlace: "Lieu de naissance",
        birthRegion: "Région de naissance",
        birthCountryCode: "Code du pays de naissance",
        languagePref: "Préférence de langue",
        english: "Anglais",
        french: "Français",
      }
    }
  }
};