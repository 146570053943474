import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { withSnackbar } from 'notistack';
import { Link } from 'react-router-dom';
import { Paper, Grid, Typography, Button } from '@material-ui/core';

import LoadingSpinner from '../../../../components/LoadingSpinner';
import { TextInput, PhoneInput, SwitchInput } from '../../../../components/inputs/Inputs';
import { ApplyInputChange, ApplyBooleanInputChange } from '../../../../utils/InputChanges';
import { GetUserDataAsAdminFromPortal } from '../../../../api/user/GetUsersFromPortal';
import { AnyInvalid } from '../../../../components/inputs/Validation';
import { PostUserToPortal } from '../../../../api/user/PostUserToPortal';
import UserOrganizationsControl from './UserOrganizationsControl';

class ViewUser extends Component {
  displayName = ViewUser.name;
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      userOrganizations: [],
      formFields: {},
    }
  }

  componentDidMount() {
    this.initializeFields();
  }

  initializeFields = async () => {
    let userData = await this.fetchUserData();
    this.setState({
      userOrganizations: userData.userOrganizations,
      formFields: userData.fields,
      isLoading: false,
    })
  }

  fetchUserData = () => {
    const { id } = this.props.match.params;
    return new Promise((resolve, reject) => {
      if (id === undefined) {
        return resolve({
          userOrganizations: [],
          fields: CreateEmptyFormFields(),
        });
      }

      GetUserDataAsAdminFromPortal(id)
        .then(res => res.ok ? res.json() : reject(res))
        .then(json => resolve({
          userOrganizations: json.userOrganization,
          fields: GenerateUserFormFieldsFromJson(json),
        }))
        .catch(e => {
          this.props.enqueueSnackbar(`Error fetching user with id ${id}`, { variant: 'error', autoHideDuration: 5500 });
          reject(e);
        });
    });
  }


  handleBooleanInputChange = field => event => {
    let newFormFields = Object.assign({}, this.state.formFields);
    this.setState({ formFields: ApplyBooleanInputChange(field, event, newFormFields) });
  }
  handleInputChange = field => event => {
    let newFormFields = Object.assign({}, this.state.formFields);
    this.setState({ formFields: ApplyInputChange(field, event, newFormFields) });
  }


  handleOrgTerminationToggle = org => {
    let orgs = Object.assign([], this.state.userOrganizations);
    for (let i = 0; i < orgs.length; i++) {
      if (orgs[i].organizationId === org.organizationId) {
        let userOrganization = orgs[i];

        if (userOrganization.effectiveToDateTime === null) {
          let date = new Date();
          date.setDate(date.getDate() - 1);
          userOrganization.effectiveToDateTime = date.toUTCString();
        }
        else {
          userOrganization.effectiveToDateTime = null;
        }

        this.setState({ userOrganizations: orgs });
        return;
      }
    }
  }


  buildPostJson = () => {
    const { formFields, userOrganizations } = this.state;
    const { id } = this.props.match.params;

    let json = {
      userId: parseInt(id),
      userOrganization: userOrganizations,
    };

    for (let field in formFields) {
      json[field] = formFields[field].value;
    }

    return json;
  }

  handleSubmit = () => {
    const { redirectRoute } = this.props;
    const { id } = this.props.match.params;

    let validationCheck = AnyInvalid(this.state.formFields);
    this.setState({ formFields: validationCheck.formFields });
    if (validationCheck.anyInvalid) {
      return;
    }

    let postJson = this.buildPostJson();
    PostUserToPortal(postJson)
      .then(res => {
        if (res.ok) {
          this.props.enqueueSnackbar(`Successfully ${id ? 'updated' : 'added new'} user`, { variant: 'success', autoHideDuration: 5500 });
          this.props.history.push(redirectRoute);
        }
        else {
          this.props.enqueueSnackbar(`Error ${id ? 'updating' : 'creating'} user`, { variant: 'error', autoHideDuration: 5500 });
          console.log(res);
        }
      })
      .catch(e => {
        this.props.enqueueSnackbar('Error adding user', { variant: 'error', autoHideDuration: 5500 });
        console.log(e)
      });
  }



  render() {
    const { formFields, isLoading, userOrganizations } = this.state;
    const { firstName, lastName, emailAddress, phoneNumber, activeIndicator } = formFields;
    const { redirectRoute, classes } = this.props;
    const { id } = this.props.match.params;

    if (isLoading) {
      return <LoadingSpinner />
    }

    return (
      <form className={classes.container} noValidate autoComplete='off'>
        <Grid container>
          <Grid item md={1} xl={2}></Grid>
          <Grid item xs={12} md={10} xl={8}>
            <Paper className={classes.paper}>
              <Grid container spacing={16}>
                <Grid item xs={12}>
                  <Typography variant='h6' color='inherit'>Edit User</Typography>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <TextInput fullWidth required field={firstName} onChange={this.handleInputChange} />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <TextInput fullWidth required field={lastName} onChange={this.handleInputChange} />
                </Grid>
                <Grid item xs={12} md={7} lg={8} className={classes.grow}>
                  <TextInput fullWidth required field={emailAddress} onChange={this.handleInputChange} />
                </Grid>
                <Grid item xs={12} sm={8} md={3} lg={2}>
                  <PhoneInput field={phoneNumber} onChange={this.handleInputChange} />
                </Grid>
                <Grid item xs={12} sm={4} md={2} lg={2}>
                  {id &&
                    <SwitchInput field={activeIndicator} onChange={this.handleBooleanInputChange} />
                  }
                </Grid>
                {
                  userOrganizations.length > 0 &&
                  <Grid item xs={12}>
                    <UserOrganizationsControl userOrganizations={userOrganizations} onOrgTerminationToggle={this.handleOrgTerminationToggle} />
                  </Grid>
                }
                <Grid item xs={12}>
                  <Button color='secondary' className={classes.button} onClick={this.handleSubmit}>{id ? 'Save' : 'Create'} User</Button>
                  <Button component={Link} to={redirectRoute} className={classes.button} color='primary'>Cancel</Button>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </form>
    )
  }
}

ViewUser.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

const styles = theme => ({
  container: {
    display: 'flex',
  },
  grow: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing.unit * 2,
  },
  button: {
    marginTop: theme.spacing.unit * 3,
    margin: theme.spacing.unit,
  },
});

function GenerateUserFormFieldsFromJson(json) {
  let newFormFields = Object.assign({}, CreateEmptyFormFields());
  console.log(json);
  for (let field in newFormFields) {
    if (json[field] !== undefined) {
      newFormFields[field].value = json[field];
    }
  }

  return newFormFields;
}

function CreateEmptyFormFields() {
  return {
    firstName: {
      label: 'First Name',
      id: 'firstName',
      value: '',
      invalid: false,
      required: true,
    },
    lastName: {
      label: 'Last Name',
      id: 'lastName',
      value: '',
      invalid: false,
      required: true,
    },
    emailAddress: {
      label: 'Email',
      id: 'emailAddress',
      value: '',
      invalid: false,
      required: true,
    },
    phoneNumber: {
      label: 'Phone',
      id: 'phoneNumber',
      value: '',
      invalid: false,
    },
    activeIndicator: {
      label: 'Active',
      id: 'activeIndicator',
      value: true,
      invalid: false,
    }
  }
}


export default
  withStyles(styles, { withTheme: true })(
    withSnackbar(ViewUser));