import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';
import { withLocalization } from '../../../../components/contexts/LanguageContext';
import { BodyParagraph, Section } from '../../../../components/text/Paragraphs';
import { SectionTitle } from '../../../../components/text/Titles';

function APIVersioning(props) {
  const { strings } = props;
  return (
    <Section>
      <SectionTitle text={strings.title} />
      <BodyParagraph text={strings.description} />
    </Section>
  )
}

APIVersioning.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

const strings = {
  en: {
    title: 'API Versioning',
    description: 'The API will be versioned using a custom HTTP header. This approach has the advantage of not requiring URL updates for versions. Not specifying a version in the HTTP request will result in a default to the latest version available. The API will only be versioned on breaking changes to reduce version churn as much as possible.',
  },
  fr: {
    title: 'API Versioning',
    description: 'Aliquam volutpat dui dignissim justo interdum ultrices non eget justo. Vivamus massa lorem, elementum ac sem a, hendrerit congue velit. Fusce ut nisi quis elit sollicitudin feugiat id sed ex. Aenean consequat, enim in pharetra hendrerit, neque justo viverra ligula, aliquet pellentesque lectus metus vitae leo. Donec a nisi at lacus viverra tincidunt. Suspendisse lacus tellus, lacinia eget finibus ac, accumsan non nibh. Sed ac euismod turpis, id facilisis ipsum. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. In dignissim ligula eget vulputate elementum. Curabitur ligula libero, facilisis ut nunc non, convallis cursus metus. Fusce eu efficitur felis, ac fermentum turpis. Pellentesque vestibulum volutpat tellus, id efficitur nunc malesuada sodales. Mauris ultricies dolor vehicula velit tincidunt ornare. Cras aliquam convallis lectus eget ullamcorper.',
  },
}

const styles = theme => ({

});

export default
  withStyles(styles, { withTheme: true })(
    withLocalization(strings)(APIVersioning));

