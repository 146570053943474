import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { GetMyUserDataFromPortal } from '../../api/user/GetUsersFromPortal';
import { PostUpdateUserLoginDate } from '../../api/user/PostUpdateUserLoginDate';
import { withCookies } from 'react-cookie';
import { withSnackbar } from 'notistack';

const UserContext = React.createContext();

class UserProvider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orgContext: {},
      user: {},
      switchContext: (newContext) => {
        props.cookies.set('orgContext', newContext, { path: '/' });
        this.setState({ orgContext: newContext }, () => {
          if (newContext.roleName === process.env.REACT_APP_ADMIN_ROLE) {
            this.props.history.push('/portal/admin/dashboard');
          }
          else {
            this.props.history.push('/portal/news');
          }
        });
      },
      getUserData: () => {
        return new Promise((resolve, reject) => {
          if (this.state.user.isAuthenticated) {
            resolve(this.state.user);
          }
          else {
            GetMyUserDataFromPortal()
              .then(res => {
                if (res.ok) {
                  return res.json();
                }
                throw res.text();
              })
              .then(json => {
                json.contexts = json.contexts.sort((a, b) => {
                  if (a.orgName < b.orgName) { return -1; }
                  if (a.orgName > b.orgName) { return 1; }
                  return 0;
                });
                let orgContext = this.props.cookies.get('orgContext') || undefined;
                if (orgContext === undefined) {
                  orgContext = json.contexts.length > 0 ? json.contexts[0] : {}
                }
                this.setState({
                  user: json,
                  orgContext: orgContext,
                }, () => {
                  PostUpdateUserLoginDate(this.state.user.email);
                  resolve(this.state.user);
                });
              })
              .catch(e => {
                Promise.resolve(e)
                  .then(msg => {
                    console.log(msg);
                    this.props.enqueueSnackbar(msg, { variant: 'error', autoHideDuration: 5500 });
                    this.props.history.push('/');
                  })
              });
          }
        })
      }
    }
  }

  render() {
    return (
      <UserContext.Provider value={this.state}>
        {this.props.children}
      </UserContext.Provider>
    )
  }
}

export default
  withRouter(
    withCookies(
      withSnackbar(UserProvider)));

export function withUserContext(WrappedComponent) {
  return class extends Component {
    render() {
      return (
        <UserContext.Consumer>
          {(context) => <WrappedComponent orgContext={context.orgContext} switchContext={context.switchContext} user={context.user} getUserData={context.getUserData} {...this.props} />}
        </UserContext.Consumer>
      )
    }
  }
}