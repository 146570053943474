import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';
import { withLocalization } from '../../../../components/contexts/LanguageContext';
import { BodyParagraph, CodeSnippet, Section } from '../../../../components/text/Paragraphs';
import { SectionTitle } from '../../../../components/text/Titles';
import { UnorderedList, ListItem } from '../../../../components/text/Lists';

function Authentication(props) {
  const { strings } = props;
  return (
    <Section>
      <SectionTitle text={strings.title} />
      <BodyParagraph text={strings.description} />
      <CodeSnippet text={strings.headerCode} />
      <BodyParagraph text={strings.headerIntro} />
      <UnorderedList>
        <ListItem text={strings.header1} bordered />
        <ListItem text={strings.header2} bordered />
        <ListItem text={strings.header3} bordered />
        <ListItem text={strings.header4} bordered />
        <ListItem text={strings.header5} bordered />
      </UnorderedList>
    </Section>
  )
}

Authentication.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

const strings = {
  en: {
    title: 'Authentication',
    description: 'The API will be authenticated using three custom HTTP headers. Each Vendor will be assigned a unique ID in the NSR Portal and will have an API key generated for them. Both the unique ID and API key need to be included as headers in the request.The Soccer Organization which authorized the Vendor will have their unique ID included in the request.',
    headerCode: `  "Content-type": "application/vnd.api+json"
  "AuthKey": "<GUID>"
  "OrgId": "<integer>"
  "VendorId": "<integer>"
  "ApiVersion": "<integer>"`,
    headerIntro: 'To be considered a valid the first four HTTP headers must be set correctly. The fifth value (version) is optional.',
    header1: 'Content-type: This must be set to the JSON API Specification content type, as shown above.',
    header2: 'AuthKey: This value is the API key assigned to the Vendor which, once granted by the Soccer Organization, will fulfill the request for the Soccer Organization. It will be found on the Organization page when logged in as a Vendor.',
    header3: 'OrgId: This value is the Organization ID of the Soccer Organization the request is being submitted on behalf of. It may be found on the Granted Organization table on the Organization page when logged in as a Vendor.',
    header4: 'VendorId: This value is Vendor ID of the Vendor submitting the request. It can be also be found Organization page.',
    header5: 'ApiVersion: The version of the API the request is using.',
  },
  fr: {
    title: 'Authentication',
    description: 'The API will be authenticated using three custom HTTP headers. Each Vendor will be assigned a unique ID in the NSR Portal and this is to be included as a header in the request.The Soccer Organization which authorized the Vendor to use their API key will also have their unique ID included in the request, as well as the API key associated with the Soccer Organization.',
    headerCode: `  "Content-Type": "application/vnd.api+json"
  "Authkey": "<GUID>"
  "OrgId": "<integer>"
  "VendorId": "<integer>"
  "ApiVersion": "<integer>"`,
    headerIntro: 'To be considered a valid the first four HTTP headers must be set correctly. The fifth value (version) is optional.',
    header1: 'TEST',
    header2: 'TEST',
    header3: 'TEST',
    header4: 'TEST',
    header5: 'TEST',
  },
}

const styles = theme => ({

});

export default
  withStyles(styles, { withTheme: true })(
    withLocalization(strings)(Authentication));

