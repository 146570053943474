import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';
import { withLocalization } from '../../../../components/contexts/LanguageContext';
import { Section, Intent, MarkdownParagraph } from '../../../../components/text/Paragraphs';
import { SectionTitle } from '../../../../components/text/Titles';

function Environments(props) {
  const { strings } = props;
  return (
    <Section>
      <SectionTitle text={strings.title} />
      <MarkdownParagraph text={strings.descriptions} />
      <Intent text={strings.intent} />
    </Section>
  )
}

Environments.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

const strings = {
  en: {
      title: 'Environments',
        descriptions: "A staging environment is available for integration and testing purposes. All testing should be completed on staging before pointing your software to the production environment.\n\nThe url for the Staging API is `https://api.nsr.canadasoccer.com/nsr/stg/player-registration`\n\n",
      intent: 'Please ensure that your environment is pointed to the staging until you are confident it is working correctly.',
    },
  fr: {
      title: 'Environments',
      description: 'Aliquam volutpat dui dignissim justo interdum ultrices non eget justo. Vivamus massa lorem, elementum ac sem a, hendrerit congue velit. Fusce ut nisi quis elit sollicitudin feugiat id sed ex. Aenean consequat, enim in pharetra hendrerit, neque justo viverra ligula, aliquet pellentesque lectus metus vitae leo. Donec a nisi at lacus viverra tincidunt. Suspendisse lacus tellus, lacinia eget finibus ac, accumsan non nibh. Sed ac euismod turpis, id facilisis ipsum. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. In dignissim ligula eget vulputate elementum. Curabitur ligula libero, facilisis ut nunc non, convallis cursus metus. Fusce eu efficitur felis, ac fermentum turpis. Pellentesque vestibulum volutpat tellus, id efficitur nunc malesuada sodales. Mauris ultricies dolor vehicula velit tincidunt ornare. Cras aliquam convallis lectus eget ullamcorper.',
      intent: "L'API NSR est destin� aux syst�mes logiciels d'enregistrement.",
    },
}

const styles = theme => ({

});

export default
  withStyles(styles, { withTheme: true })(
        withLocalization(strings)(Environments));

