import { isInvalid } from "../components/inputs/Validation";

export function ApplyBooleanInputChange(field, event, newFormFields) {
  let newFieldState = newFormFields[field];
  let boolValue = event.target.value === 'true';
  newFieldState.value = !boolValue;
  newFieldState = isInvalid(newFieldState);
  newFormFields[field] = newFieldState;
  return newFormFields;
}

export function ApplyInputChange(field, event, newFormFields) {
  let newFieldState = newFormFields[field];
  newFieldState.value = event.target.value;

  if (event.target.organizationId) {
    newFieldState.organizationId = event.target.organizationId;
  }

  newFieldState = isInvalid(newFieldState);
  if(!newFieldState.invalid){
   newFieldState.error = "";
  }
  newFormFields[field] = newFieldState;
  return newFormFields;
}
