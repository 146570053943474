import React, { Component } from 'react';
import { TextField, withStyles, Typography, MenuItem, Button } from '@material-ui/core';
import { withSnackbar } from 'notistack';
import styled from 'styled-components';
import { DatePicker } from 'material-ui-pickers';
import { PostRegistrationToAPI } from '../../api/registration/PostRegistrationToAPI';
import { withUserContext } from '../../components/contexts/UserContext';
import { withTranslation } from 'react-i18next';
import { PageTitle } from '../../components/text/Titles';

const WhiteTextTypography = withStyles({
  root: {
    color: "#FFFFFF"
  }
})(Typography);

const Card = styled.div`
  padding: 1rem;
  background-color: #fff;
  box-shadow: 0 1px 3px rgba(0,0,0,0.02), 0 1px 2px rgba(0,0,0,0.14);
  border-radius: 4px;
  transition: all 0.4s ease-out;
  :hover {
    box-shadow: 0 3px 6px rgba(0,0,0,0.06), 0 3px 6px rgba(0,0,0,0.13);
  }
`

const FieldsContainer = styled.div`
  margin-top: 2rem;
`

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-gap: 2rem;
  margin-top: 4rem;
`

const HeaderContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`
const TwoRowWrapper = styled.div`
  grid-row-end: span 2;
`
const StickyWrapper = styled.div`
  position: sticky;
  top: 5.5rem;
`

const MessageContainer = styled.div`
  flex-basis: 100%;

`

class ManualRegistrationPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isSubmitting: false,
      successMsg: '',
      errorMsg: [],
      orgId: "",
      vendorId: "",
      authKey: "",
      firstName: '',
      lastName: '',
      gender: '',
      birthDate: new Date(),
      status: 'active',
      playerDiscipline: "Football",
      playerRegistrationNature: "Registration",
      playerRegistrationLevel: "amateur",
      recreationalRegistration: "true",
      registrationFromDate: new Date(),
      registrationToDate: new Date(),
      homeAddressLine1: "",
      homeAddressLine2: "",
      homeAddressMunicipality: "",
      homeProvinceCode: "",
      homePostalCode: "",
      homeCountryCode: "CA",
      emailAddress: "",
      parentEmailAddress: "",
      homePhoneNumber: "",
      mobilePhoneNumber: "",
      citizenship: "CA",
      birthPlace: "",
      birthRegion: "",
      birthCountryCode: "CA",
      sourceSystemName: "NSR Portal Manual Registration",
      languagePreference: "en"
    }
  }

  handleDateChange = name => date => {
    this.setState({ [name]: date });
  }

  handleChange = name => event => {
    this.setState({ [name]: event.target.value });
  }

  formatDate = date => {
    let month = date.getMonth() + 1;
    if (month < 10) {
      month = '0' + month;
    }
    let day = date.getDate();
    if (day < 9) {
      day = '0' + day;
    }
    let year = date.getFullYear();

    return `${year}/${month}/${day}`;
  }

  submitRegistration = () => {
    let data = {
      "data": {
        "type": "player-registration",
        "attributes": {
          "firstName": this.state.firstName,
          "lastName": this.state.lastName,
          "gender": this.state.gender,
          "birthDate": this.formatDate(this.state.birthDate),
          "status": this.state.status,
          "playerDiscipline": this.state.playerDiscipline,
          "playerRegistrationNature": this.state.playerRegistrationNature,
          "playerRegistrationLevel": this.state.playerRegistrationLevel,
          "recreationalRegistration": this.state.recreationalRegistration,
          "registrationFromDate": this.formatDate(this.state.registrationFromDate),
          "registrationToDate": this.formatDate(this.state.registrationToDate),
          "homeAddressLine1": this.state.homeAddressLine1,
          "homeAddressLine2": this.state.homeAddressLine2,
          "homeAddressMunicipality": this.state.homeAddressMunicipality,
          "homeProvinceCode": this.state.homeProvinceCode,
          "homePostalCode": this.state.homePostalCode,
          "homeCountryCode": this.state.homeCountryCode,
          "emailAddress": this.state.emailAddress,
          "parentEmailAddress": this.state.parentEmailAddress,
          "homePhoneNumber": this.state.homePhoneNumber,
          "mobilePhoneNumber": this.state.mobilePhoneNumber,
          "citizenship": this.state.citizenship,
          "birthPlace": this.state.birthPlace,
          "birthRegion": this.state.birthRegion,
          "birthCountryCode": this.state.birthCountryCode,
          "sourceSystemName": this.state.sourceSystemName,
          "languagePreference": this.state.languagePreference,
        }
      }
    }

    this.setState({
      isSubmitting: true,
    });

    const { authKey, orgId, vendorId } = this.state;
    PostRegistrationToAPI(data, authKey, orgId, vendorId)
      .then(res => {
        return res.json();
      })
      .then(res => {
        if (res.data && res.data.id) {
          const { firstName, lastName, canadaSoccerId } = res.data.attributes;
          this.props.enqueueSnackbar(`"Successfully registered ${firstName} ${lastName}. Player data can be searched from the Reports Tab"`, { variant: 'success', autoHideDuration: 7000 });
          this.setState({
            isSubmitting: false,
            successMsg: `${firstName} ${lastName}'s Canada Soccer Id is ${canadaSoccerId}`,
            errorMsg: [],
            firstName: '',
            lastName: '',
            gender: '',
            birthDate: new Date(),
            status: 'active',
            playerDiscipline: "Football",
            playerRegistrationNature: "Registration",
            playerRegistrationLevel: "amateur",
            recreationalRegistration: "true",
            registrationFromDate: new Date(),
            registrationToDate: new Date(),
            homeAddressLine1: "",
            homeAddressLine2: "",
            homeAddressMunicipality: "",
            homeProvinceCode: "",
            homePostalCode: "",
            homeCountryCode: "CA",
            emailAddress: "",
            parentEmailAddress: "",
            homePhoneNumber: "",
            mobilePhoneNumber: "",
            citizenship: "CA",
            birthPlace: "",
            birthRegion: "",
            birthCountryCode: "CA",
            sourceSystemName: "NSR Portal Manual Registration",
            languagePreference: "en"
          });

        }
        else {
          throw res;
        }
      })
      .catch(e => {
        console.error(e);
        this.setState({
          errorMsg: e.errors,
          isSubmitting: false,
        });
        this.props.enqueueSnackbar("There was an issue with the submission, please see error messages", { variant: 'error', autoHideDuration: 5500 });
      })

  }

  render() {
    const { errorMsg, successMsg, isSubmitting } = this.state;
    const { classes, t } = this.props;

    return (
      <React.Fragment>

        <PageTitle text={t('manualRegistration.manualRegistration')} />
        <Container>
          <TwoRowWrapper>
            <StickyWrapper>


              <Card>
                <Typography variant='h5'>{t('manualRegistration.httpData')}</Typography>
                <HeaderContainer>
                  <TextField style={{ flexBasis: '100%' }} id="authKey" label={t('manualRegistration.authenticationKey')} className={classes.textField} value={this.state.authKey} onChange={this.handleChange('authKey')} />
                  <TextField style={{ flexGrow: 1 }} id="vendorId" label={t('manualRegistration.vendorId')} className={classes.textField} value={this.state.vendorId} onChange={this.handleChange('vendorId')} />
                  <TextField style={{ flexGrow: 1 }} id="orgId" label={t('manualRegistration.organizationId')} className={classes.textField} value={this.state.orgId} onChange={this.handleChange('orgId')} />
                  <div style={{ flexBasis: '100%', display: 'flex', justifyContent: 'center' }}>
                    {
                      isSubmitting &&
                      <Button disabled className={classes.submitButton} variant='contained' color='primary'>{t('manualRegistration.processingSubmission')}</Button>
                    }
                    {
                      !isSubmitting &&
                      <Button onClick={this.submitRegistration} className={classes.submitButton} variant='contained' color='primary'>{t('manualRegistration.submit')}</Button>
                    }
                  </div>

                  {
                    errorMsg && errorMsg.length > 0 &&
                    <MessageContainer>
                      {
                        errorMsg.map((msg, index) => <Typography key={index} color='primary'>{`- Error response [${msg.status}] ${msg.title}: ${msg.detail}`}</Typography>)
                      }
                    </MessageContainer>
                  }
                  {
                    successMsg && successMsg.length > 0 &&
                    <MessageContainer>
                      {
                        <Typography>{`- ${successMsg}`}</Typography>
                      }
                    </MessageContainer>
                  }
                </HeaderContainer>
              </Card>
            </StickyWrapper>
          </TwoRowWrapper>
          <TwoRowWrapper>

            <Card>
              <Typography variant='h5'>{t('manualRegistration.registrationData')}</Typography>
              <FieldsContainer>
                <form className={classes.container} noValidate autoComplete='off'>
                  <TextField id="firstName" label={t('manualRegistration.firstName')} className={classes.textField} value={this.state.firstName} onChange={this.handleChange('firstName')} />
                  <TextField id="lastName" label={t('manualRegistration.lastName')} className={classes.textField} value={this.state.lastName} onChange={this.handleChange('lastName')} />
                  <TextField id="gender" label={t('manualRegistration.gender')} className={classes.textField} value={this.state.gender} onChange={this.handleChange('gender')} select
                    SelectProps={{
                      MenuProps: {
                        className: classes.menu
                      }
                    }}
                  >
                    <MenuItem key='M' value='M'>{t('manualRegistration.male')}</MenuItem>
                    <MenuItem key='F' value='F'>{t('manualRegistration.female')}</MenuItem>
                  </TextField>
                  <DatePicker autoOk disableFuture format="MMM dd yyyy" className={classes.textField} label={t('manualRegistration.dob')} value={this.state.birthDate} onChange={this.handleDateChange('birthDate')} />
                  <TextField id="status" label={t('manualRegistration.status')} className={classes.textField} value={this.state.status} onChange={this.handleChange('status')} select
                    SelectProps={{
                      MenuProps: {
                        className: classes.menu
                      }
                    }}
                  >
                    <MenuItem key='active' value='active'>{t('manualRegistration.active')}</MenuItem>
                    <MenuItem key='inactive' value='inactive'>{t('manualRegistration.inactive')}</MenuItem>
                  </TextField>
                  <TextField id="playerRegistrationLevel" label={t('manualRegistration.playerLevel')} className={classes.textField} value={this.state.playerRegistrationLevel} onChange={this.handleChange('playerRegistrationLevel')} select
                    SelectProps={{
                      MenuProps: {
                        className: classes.menu
                      }
                    }}
                  >
                    <MenuItem key='pro' value='pro'>{t('manualRegistration.pro')}</MenuItem>
                    <MenuItem key='amateur' value='amateur'>{t('manualRegistration.amateur')}</MenuItem>
                  </TextField>
                  <TextField id="recreationalRegistration" label={t('manualRegistration.recreationalRegistration')} className={classes.textField} value={this.state.recreationalRegistration} onChange={this.handleChange('recreationalRegistration')} select
                    SelectProps={{
                      MenuProps: {
                        className: classes.menu
                      }
                    }}
                  >
                    <MenuItem key='yes' value='true'>{t('manualRegistration.yes')}</MenuItem>
                    <MenuItem key='no' value='false'>{t('manualRegistration.no')}</MenuItem>
                  </TextField>
                  <DatePicker maxDate={this.state.registrationToDate} maxDateMessage={t('manualRegistration.dateBeforeEnd')} autoOk format="MMM dd yyyy" className={classes.textField} label={t('manualRegistration.registerFrom')} value={this.state.registrationFromDate} onChange={this.handleDateChange('registrationFromDate')} />
                  <DatePicker minDate={this.state.registrationFromDate} minDateMessage={t('manualRegistration.dateAfterStart')} autoOk format="MMM dd yyyy" className={classes.textField} label={t('manualRegistration.registerTo')} value={this.state.registrationToDate} onChange={this.handleDateChange('registrationToDate')} />

                  <TextField id="homeAddressLine1" label={t('manualRegistration.homeAddress1')} className={classes.textField} value={this.state.homeAddressLine1} onChange={this.handleChange('homeAddressLine1')} />
                  <TextField id="homeAddressLine2" label={t('manualRegistration.homeAddress2')} className={classes.textField} value={this.state.homeAddressLine2} onChange={this.handleChange('homeAddressLine2')} />
                  <TextField id="homeAddressMunicipality" label={t('manualRegistration.municipality')} className={classes.textField} value={this.state.homeAddressMunicipality} onChange={this.handleChange('homeAddressMunicipality')} />
                  <TextField id="homeProvinceCode" label={t('manualRegistration.province')} className={classes.textField} value={this.state.homeProvinceCode} onChange={this.handleChange('homeProvinceCode')} select
                    SelectProps={{
                      MenuProps: {
                        className: classes.menu
                      }
                    }}
                  >
                    <MenuItem key='AB' value='AB'>{t('manualRegistration.ab')}</MenuItem>
                    <MenuItem key='BC' value='BC'>{t('manualRegistration.bc')}</MenuItem>
                    <MenuItem key='MB' value='MB'>{t('manualRegistration.mb')}</MenuItem>
                    <MenuItem key='NB' value='NB'>{t('manualRegistration.nb')}</MenuItem>
                    <MenuItem key='NL' value='NL'>{t('manualRegistration.nl')}</MenuItem>
                    <MenuItem key='NS' value='NS'>{t('manualRegistration.ns')}</MenuItem>
                    <MenuItem key='NT' value='NT'>{t('manualRegistration.nt')}</MenuItem>
                    <MenuItem key='NT' value='NT'>{t('manualRegistration.nu')}</MenuItem>
                    <MenuItem key='ON' value='ON'>{t('manualRegistration.on')}</MenuItem>
                    <MenuItem key='PE' value='PE'>{t('manualRegistration.pe')}</MenuItem>
                    <MenuItem key='QC' value='QC'>{t('manualRegistration.qc')}</MenuItem>
                    <MenuItem key='SK' value='SK'>{t('manualRegistration.sk')}</MenuItem>
                    <MenuItem key='YK' value='YK'>{t('manualRegistration.yk')}</MenuItem>
                  </TextField>

                  <TextField id="homePostalCode" label={t('manualRegistration.postalCode')} className={classes.textField} value={this.state.homePostalCode} onChange={this.handleChange('homePostalCode')} />
                  <TextField id="homeCountryCode" label={t('manualRegistration.homeCountryCode')} className={classes.textField} value={this.state.homeCountryCode} onChange={this.handleChange('homeCountryCode')} />
                  <TextField id="emailAddress" label={t('manualRegistration.emailAddress')} className={classes.textField} value={this.state.emailAddress} onChange={this.handleChange('emailAddress')} />
                  <TextField id="parentEmailAddress" label={t('manualRegistration.parentEmailAddress')} className={classes.textField} value={this.state.parentEmailAddress} onChange={this.handleChange('parentEmailAddress')} />
                  <TextField id="homePhoneNumber" label={t('manualRegistration.homePhone')} className={classes.textField} value={this.state.homePhoneNumber} onChange={this.handleChange('homePhoneNumber')} />
                  <TextField id="mobilePhoneNumber" label={t('manualRegistration.mobilePhone')} className={classes.textField} value={this.state.mobilePhoneNumber} onChange={this.handleChange('mobilePhoneNumber')} />
                  <TextField id="citizenship" label={t('manualRegistration.citizenship')} className={classes.textField} value={this.state.citizenship} onChange={this.handleChange('citizenship')} />
                  <TextField id="birthPlace" label={t('manualRegistration.birthPlace')} className={classes.textField} value={this.state.birthPlace} onChange={this.handleChange('birthPlace')} />
                  <TextField id="birthRegion" label={t('manualRegistration.postalCode')} className={classes.textField} value={this.state.birthRegion} onChange={this.handleChange('birthRegion')} />
                  <TextField id="birthCountryCode" label={t('manualRegistration.postalCode')} className={classes.textField} value={this.state.birthCountryCode} onChange={this.handleChange('birthCountryCode')} />

                  <TextField id="languagePreference" label={t('manualRegistration.languagePref')} className={classes.textField} value={this.state.languagePreference} onChange={this.handleChange('languagePreference')} select
                    SelectProps={{
                      MenuProps: {
                        className: classes.menu
                      }
                    }}
                  >
                    <MenuItem key='en' value='en'>{t('manualRegistration.english')}</MenuItem>
                    <MenuItem key='fr' value='fr'>{t('manualRegistration.french')}</MenuItem>
                  </TextField>
                </form>
              </FieldsContainer>
            </Card>
          </TwoRowWrapper>
        </Container>
      </React.Fragment>
    )
  }
}

const styles = theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: 600,
  },
  flexGrow: {
    flexGrow: 1,
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    paddingRight: theme.spacing.unit * 2,
    marginTop: theme.spacing.unit * 2,
  },
  selectField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    paddingRight: theme.spacing.unit * 2,
    marginTop: theme.spacing.unit * 3,
  },
  submitButton: {
    margin: "4rem auto",
  },
  menu: {
    width: 600,
  }
});


export default
  withStyles(styles)(
    withSnackbar(
      withUserContext(
        withTranslation()(ManualRegistrationPage))));