import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Grid, Paper, Typography, Button } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

import { GetRegistrationFromPortal } from '../../api/registration/GetRegistrationFromPortal';
import { TextInput } from '../../components/inputs/Inputs';
import { isInvalid } from '../../components/inputs/Validation';

class RegistrationCheck extends Component {
  displayName = RegistrationCheck.name;
  constructor(props) {
    super(props);
    this.state = {
      email: {
        label: "Email",
        id: 'email',
        value: '',
        invalid: false,
      },
      status: {},
      checked: false,
    };
  }

  handleInputChange = field => event => {
    let newFieldState = Object.assign({}, this.state[field]);
    newFieldState.value = event.target.value;
    newFieldState = isInvalid(newFieldState);
    this.setState({ [field]: newFieldState });
  }

  handleCheckClick = () => {
    const { email } = this.state;
    if (email) {
      GetRegistrationFromPortal(email.value)
        .then(res => res.json())
        .then(json => {
          this.setState({
            status: json.status,
            checked: true,
          })
        })
        .catch(e => console.log(e))
    }
  }

  render() {
    const { email, status, checked } = this.state;
    const { classes, match, t } = this.props;
    let registrationHref = match.path.substring(0, match.path.lastIndexOf('/'));

    let disabled = email.value.length === 0 || email.invalid;
    let checkMessage = `${t("Registration request is")} ${(status.en || '').toLowerCase()}`;

    return (
      <Grid container justify='center' spacing={24}>
        <Grid item md={1} lg={2} />
        <Grid item xs={12} md={10} lg={8}>
          <Paper className={classes.paper}>
            <Grid container justify='center'>
              <Grid item xs={12}>
                <Typography align='center' variant='h6'>{t('registration.Already registered?')}</Typography>
                <Typography align='center' variant='body1'>{t("registration.Enter your email to check the status of your registration")}</Typography>
              </Grid>
              <Grid item xs={12} sm={10} md={8} lg={6}>
                <TextInput fullWidth field={email} onChange={this.handleInputChange} />
              </Grid>
              <Grid item xs={12} className={classes.check}>
                <Button variant='contained' color='secondary' disabled={disabled} onClick={this.handleCheckClick}>
                  Check
                </Button>
              </Grid>
              <Grid item xs={12} className={classes.message}>
                <Typography color='inherit' >{checked ? checkMessage : ''}</Typography>
              </Grid>
              <Grid item xs={12} className={classes.registerLink}>
                <Typography component={Link} to={registrationHref}>{t('registration.Not registered? Click here to register')}</Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item md={1} lg={2} />
      </Grid>
    )
  }
}

RegistrationCheck.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};


const styles = theme => ({
  paper: {
    padding: theme.spacing.unit * 2,
  },
  check: {
    textAlign: 'center',
    marginTop: theme.spacing.unit * 2,
  },
  message: {
    textAlign: 'center',
    minHeight: 50,
    marginTop: theme.spacing.unit * 2,
  },
  registerLink: {
    textAlign: 'center',
  }
});

export default
  withStyles(styles, { withTheme: true })(
    withTranslation()(RegistrationCheck));