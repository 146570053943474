import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

function OrgContextItem(props) {
  const { classes, padded } = props;
  return (
    <div className={classNames(classes.root, padded ? classes.padded : '')}>
      <div className={classes.primary}>
        {props.primary}
      </div>
      <div className={classes.secondary}>
        {props.secondary}
      </div>
    </div>
  )
}

OrgContextItem.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

const styles = theme => ({
  root: {
    fontFamily: theme.typography.fontFamily, 
  },
  padded: {
    paddingTop: 15,
    paddingBottom: 15,
  },
  primary: {
    
  },
  secondary: {
  }
});

export default withStyles(styles, { withTheme: true })(OrgContextItem);